import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {
  ConfirmDialogService,
  ToastService,
  useDataTable,
  useDataTableBasic,
  useDialogCrup,
  useToolbar
} from '@iamsoftware/react-hooks';
import {OutgoingShipmentsService as Service} from "../xuat-kho/OutgoingShipmentsService";
import {usePG} from "./CrupPG";

export const CrupPGItems = ({display, setDisplay, customerId, setShipmentChild, shipmentChild}) => {

  const [params, setParams] = useState([])
  const dataKey = 'workEffortId';

  useEffect(() => {
    if (display) {
      reloadLazyData();
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setParams([{
      customerPartyId: customerId,
      filterShipIds: shipmentChild.map(item => {
        return item.workEffortId
      })
    }])
  }, [customerId, shipmentChild])

  const {render: renderItemsTable, selectedItems, reloadLazyData} = useDataTable({
    tableHeader: 'Phiếu xuất ',
    dataKey,
    columns: [
      {field: 'workEffortName', header: 'Mã phiếu xuất', width: 200, matchMode: 'contains'},
      {field: 'facilityPseudoId', header: 'Mã kho giao', width: 150, matchMode: 'contains'},
      {field: 'facilityName', header: 'Tên kho giao', minWidth: 150, matchMode: 'contains'},
      {field: 'amountTotal', header: 'Thành tiền', minWidth: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'estimatedStartDate', header: 'Ngày dự kiến', width: 140, dataType: 'date'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'},
      {field: 'description', header: 'Ghi chú', minWidth: 150, matchMode: 'contains', dataType: 'html'}
    ],
    getList: lazyLoadEvent => {
      if (display) {
        return Service.getNotIn(JSON.stringify(params));
      } else {
        return Promise.resolve({});
      }
    },
    selectionMode: 'checkbox',

  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;
  const onSubmit = event => {
    if (selectedItems) {
      setShipmentChild(prevItems => {
        selectedItems.map(item => {
          prevItems.push({
            workEffortId: item.workEffortId,
            workEffortName: item.workEffortName,
            facilityPseudoId: item.facilityPseudoId,
            facilityName: item.facilityName,
            customerPseudoId: item.customerPseudoId,
            customerName: item.customerName,
            estimatedStartDate: item.estimatedStartDate,
            status: item.status,
            description: item.description,
            amountTotal: item.amountTotal
          })
        });
        return prevItems
      })
    }
    setParams([{
      customerPartyId: customerId,
      filterShipIds: shipmentChild.map(item => {
        return item.workEffortId
      })
    }])
    setDisplay(null)
    event.preventDefault();
  }


  const footer = (
    <div className="flex justify-content-center">
      <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small"
              onClick={() => setDisplay(null)}/>
    </div>
  );
  return (
    <Dialog header="Thêm phiếu xuất" footer={footer} visible={!!display} style={{width: '80rem'}} position="top"
            onHide={() => setDisplay(false)}>
      <div className="grid pt-2">
        {renderItemsTable()}
      </div>
    </Dialog>
  )
}