import {Fragment, useEffect, useState} from 'react';
import moment from 'moment';
import {Button} from 'primereact/button';
import {useDataTable, useForm, useToolbar, FormatDisplay} from '@iamsoftware/react-hooks';

import {ReportPlanService as Service} from './reportPlanService';
import {formatFieldValue} from '@iamsoftware/react-hooks/lib/hooks/Form';

export default function BaoCaoSl() {
    const [display, setDisplay] = useState(null);
    const header = 'Báo cáo số lượng cần giao tiếp của từng PO';

    useEffect(() => {
        if(display){
            form.setValue({
                fromDate: FormatDisplay.date(moment().subtract(1, 'month').startOf('day').toDate(), 'YYYY-MM-DD'),
                thruDate: FormatDisplay.date(moment().endOf('day').toDate(), 'YYYY-MM-DD')
            })
        }
    }, [display]);

    const {render: renderDataTable,reloadLazyData} = useDataTable({

        columns: [
            {field: 'projectId', header: 'Mã dự án',  minWidth: 75, matchMode: 'contains'},
            {field: 'trackingNumber', header: 'Số PO',   minWidth: 100, matchMode: 'contains'},
            {field: 'productId', header: 'Mã vật liệu',  minWidth: 100, matchMode: 'contains'},
            {field: 'productName', header: 'Tên sản phẩm', minWidth: 200  , matchMode: 'contains'},
            {field: 'quantityUom', header: 'Đơn vị' ,  minWidth: 75, matchMode: 'contains'},
            {field: 'quantity', header: 'Số lượng đặt hàng',  minWidth: 80, matchMode: 'contains'},
            {field: 'operatorName', header: 'Người mua',  minWidth: 100, matchMode: 'contains'},
            {field: 'estimatedShipDate', header: 'Ngày dự kiến giao hàng',dataType: 'date', minWidth: 100, matchMode: 'contains'},
            {field: 'estimatedDeliveryDate', header: 'Ngày giao hàng thực tế', dataType: 'date', minWidth: 100, matchMode: 'contains'},
            {field: 'estimatedShipDate', header: 'Ngày giao hàng tiếp theo', dataType: 'date', minWidth: 100, matchMode: 'contains'},
            {field: 'unitAmount', header: 'Giá mua', dataType:'number', minWidth: 100, matchMode: 'contains'},
            {field: 'partTotal', header: 'Tổng giá tiền mua',dataType:'number', minWidth: 100, matchMode: 'contains'},
            {field: 'invoiceId', header: 'Mã hóa đơn', minWidth: 75, matchMode: 'contains'},
            {field: 'invoiceNumber', header: 'Số hóa đơn',  minWidth: 75, matchMode: 'contains'},
            {field: 'productName', header: 'Tên hàng hoá ghi trên hoá đơn đầu vào', minWidth: 250, matchMode: 'contains'},
            {field: 'facilityName', header: 'Nơi mua',  minWidth: 125, matchMode: 'contains'},
            {field: 'shippingInstructions', header: 'Ghi chú',  minWidth: 100, matchMode: 'contains'}
        ],
        indexColumnWidth: 45,
        getList: lazyLoadEvent => {
            return Service.getList(lazyLoadEvent,form.getValue());
        },
    });

    const reloadSearch =()=>{
        reloadLazyData();
        setDisplay(null);
    }

    const downloadExcel = () => {
        window.open(`${Service.baseURL}/iam-report/reports/po-plan`);
    }

    const {renderToolbar} = useToolbar({
        leftButtons:
            <Fragment>
                <Button label="Xuất excel" icon="pi pi-file-excel" className="p-button-help" onClick={downloadExcel}/>
            </Fragment>
    });
    const form = useForm({
        fields: [
            {field: 'fromDate', header: 'Từ ngày',  InputTextProps: {type: 'date'}, className: 'md:col-3', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
            {field: 'thruDate', header: 'Đến ngày',  InputTextProps: {type: 'date'}, className: 'md:col-3', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
            {
                type: 'custom', body: <Button icon="pi pi-search" className="p-button-rounded p-button-success" onClick={reloadSearch}/>, className: 'md:col-1'
            }
        ],
        layout: 'Horizontal',
    });

    return (
        <div className="grid">
            <div className="col-10">
                {form.render()}
            </div>
            <div className="col-2">
                {renderToolbar()}
            </div>
            <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 18rem)'}}>
                {renderDataTable()}
            </div>

        </div>
    )
}