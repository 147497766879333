import {EntityService} from '../../../service/EntityService';

export class PaymentPurchaseServices extends EntityService{
  static entity = 'iam-purchase/payments';

  static getOrderAddable(orderId: any,orderPartSeqId: any): Promise<any> {
    return this.axios.get(`${this.entity}/order-part-addable?orderId=${orderId}&orderPartSeqId=${orderPartSeqId}`).then(res => res.data);
  }

  static getBankAccounts(partyId: any): Promise<any> {
    return this.axios.get(`iam-party/info/bank-accounts?partyId=${partyId}`).then(res => res.data);
  }

  static doCancel(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/cancel`).then(res => res.data);
  }

  static doDelivery(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/delivery`).then(res => res.data);
  }
}