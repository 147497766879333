import {EntityService} from '../../../service/EntityService';

export class AccountsService extends EntityService {

  static entity = 'iam-party/employees/account';

  static getRoles(): Promise<any> {
    return this.axios.get(`${this.entity}/roles`).then(res => res.data);
  }

}
