import {Fragment, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {SuppliersService as Service} from './SuppliersService';
import {ProductsService} from '../../ban-hang/san-pham/ProductsService';
import {QuantityUomsService} from '../../quan-ly/danh-muc/don-vi-san-pham/QuantityUomsService';

export const ProductPrices = ({display, setDisplay}) => {

  const header = `Sản phẩm nhà cung cấp #${display?.pseudoId} - ${display?.partyName}`;

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(false)}>
      <Screen partyId={display?.partyId}/>
    </Dialog>
  );
}

const Screen = ({partyId}) => {

  const header = 'Sản phẩm';
  const dataKey = 'productPriceId';

  const [products, setProducts] = useState([]);
  const [quantityUoms, setQuantityUoms] = useState([]);

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'pseudoId', header: 'Mã sản phẩm', width: 200, matchMode: 'contains'},
      {field: 'productName', header: 'Tên sản phẩm', minWidth: 250, matchMode: 'contains'},
      {field: 'price', header: 'Giá mua', width: 150, matchMode: 'numeric', dataType: 'number'},
      {field: 'quantityUom', header: 'Đơn vị tính', width: 150, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      if (partyId) {
        return Service.getProductPrices(partyId, lazyLoadEvent);
      } else {
        return Promise.resolve({});
      }
    }
  });

  const searchProducts = event => {
    const _event: any = {
      filters: {
        statusId: {value: 'ProdActive', matchMode: 'equals'},
        productName: {value: event.query, matchMode: 'contains'}
      }
    };
    ProductsService.getList(JSON.stringify(_event)).then(data => {
      setProducts(data.listData.map(item => {
        let label = `[${item.pseudoId}] - ${item.productName}`;
        if (item.localizedName) {
          label += ` | ${item.localizedName}`;
        }
        return {value: item.productId, label};
      }));
    });
  }

  const searchQuantityUoms = event => {
    const _event: any = {
      filters: {
        disabled: {value: 'N', matchMode: 'equals'},
        description: {value: event.query, matchMode: 'contains'}
      }
    };
    QuantityUomsService.getList(JSON.stringify(_event)).then(data => {
      setQuantityUoms(data.listData.map(item => {
        return {value: item.uomId, label: item.description};
      }));
    });
  }

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'productPriceId', type: 'hidden'},
      {
        field: 'product', header: 'Sản phẩm', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: products, completeMethod: searchProducts, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-12'
      },
      {field: 'price', header: 'Giá mua', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-12'},
      {
        field: 'qtyUom', header: 'Đơn vị tính', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: quantityUoms, completeMethod: searchQuantityUoms, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-12'
      }
    ],
    createItem: item => {
      return Service.createProductPrice(partyId, inject(item));
    },
    updateItem: (id, item) => {
      return Service.updateProductPrice(partyId, inject(item));
    },
    reloadLazyData
  });

  const inject = item => {
    item.productId = item.product.value;
    delete item.product;
    item.quantityUomId = item.qtyUom.value;
    delete item.qtyUom;
    return item;
  }

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    create();
  }

  const doUpdate = () => {
    if (selectedItem && selectedItem[dataKey]) {
      selectedItem.product = {value: selectedItem.productId, label: `[${selectedItem.pseudoId}] - ${selectedItem.productName}`};
      selectedItem.qtyUom = {value: selectedItem.quantityUomId, label: selectedItem.quantityUom};
      update(selectedItem);
    }
  }

  const doDisable = () => {
    if (selectedItem && selectedItem[dataKey]) {
      ConfirmDialogService.confirm('Xóa khách hàng', `Xác nhận xóa ${header} [${selectedItem.pseudoId}] - ${selectedItem.productName}?`, () => {
        Service.disableProductPrice(partyId, selectedItem[dataKey]).then(() => {
          ToastService.success();
          reloadLazyData();
        });
      });
    }
  }

  const {renderToolbar} = useToolbar({
    doCreate,
    hasSelectedItem: selectedItem,
    doUpdate,
    leftButtons: <Fragment>
      <Button label="xoá" icon="pi pi-trash" severity="danger" size="small" onClick={doDisable} disabled={!selectedItem}/>
    </Fragment>
  });

  return (
    <div className="grid mt-2">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );

}