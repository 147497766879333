import {Fragment, useEffect, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {classNames} from 'primereact/utils';

import {AuthService} from '../service/AuthService';
import {AppStorage, UserStorage} from '../service/UserStorage';

import {AppTopbar} from './AppTopbar';
import {AppMenu} from './AppMenu';

import Sales from './ban-hang';
import Purchases from './mua-hang';
import Logistics from './kho-van';
import Reports from './bao-cao';
import System from './quan-ly';
import PaymentFile from "./ho-so-thanh-toan";
import Dashboard from './dashboard';

export default function App() {

  const [me, setMe] = useState(null);

  const [layoutMode] = useState('static');
  const [layoutColorMode] = useState('light');
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    AuthService.getMe('N').then(data => {
      setMe(data);
      AppStorage.set('me', JSON.stringify(data));
      UserStorage.id = data.partyId;
    });
  }, []);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden');
    } else {
      removeClass(document.body, 'body-overflow-hidden');
    }
  }, [mobileMenuActive]);

  const onWrapperClick = () => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  }
  const onToggleMenuClick = event => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive(prevState => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === 'static') {
        setStaticMenuInactive(prevState => !prevState);
      }
    } else {
      setMobileMenuActive(prevState => !prevState);
    }

    event.preventDefault();
  }

  const onSidebarClick = () => {
    menuClick = true;
  }

  const onMenuItemClick = event => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  }

  const onMobileTopbarMenuClick = event => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive(prevState => !prevState);
    event.preventDefault();
  }

  const onMobileSubTopbarMenuClick = event => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  }


  const isDesktop = () => {
    return window.innerWidth >= 992;
  }

  const addClass = (element, className) => {
    if (element.classList)
      element.classList.add(className);
    else
      element.className += ' ' + className;
  }

  const removeClass = (element, className) => {
    if (element.classList)
      element.classList.remove(className);
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }


  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'layout-theme-light': layoutColorMode === 'light'
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      {me &&
				<Fragment>
					<AppTopbar me={me}
										 mobileTopbarMenuActive={mobileTopbarMenuActive} onToggleMenuClick={onToggleMenuClick}
										 onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}/>

					<div className="layout-sidebar" onClick={onSidebarClick}>
						<AppMenu me={me} onMenuItemClick={onMenuItemClick}/>
					</div>

					<div className="layout-main-container">
						<div className="layout-main">
							<div className="grid">
								<div className="col-12">
									<div className="card">
                    {['Administrator', 'Manager', 'Employee'].includes(me.roleTypeId) &&
											<Routes>
												<Route path="quan-ly/*" element={<System/>}/>
												<Route path="ban-hang/*" element={<Sales/>}/>Ư
												<Route path="mua-hang/*" element={<Purchases/>}/>
												<Route path="kho-van/*" element={<Logistics/>}/>
												<Route path="bao-cao/*" element={<Reports/>}/>
												<Route path="ho-so-thanh-toan/*" element={<PaymentFile/>}/>
												<Route path="dashboard/*" element={<Dashboard/>}/>
												<Route path="*" element={<Navigate to="dashboard" replace/>}/>
                      </Routes>
                    }
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
      }
    </div>
  );
}
