import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ConfirmDialogService, ToastService, useDataTable, useToolbar} from '@iamsoftware/react-hooks';

import {SalesOrdersService as Service} from './SalesOrdersService';

import {OrderShipments} from '../../../shared/components/order/OrderShipments';
import {ImportExcel} from '../../../shared/components/ImportExcel';
import {useCrupSO} from './CrupSO';

export const SalesOrders = () => {
  const header = 'Đơn hàng bán';
  const dataKey = 'orderId';
  const [displayShipments, setDisplayShipments] = useState(null);
  const [headerResult, setHeaderResult] = useState(null);
  useEffect(() => {
    setHeaderResult(tabHeader)
  }, []);
  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    tableHeader: headerResult,
    dataKey,
    columns: [
      {
        field: 'displayId', header: 'Mã đơn hàng', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {

          return <a onClick={() => Service.get(rowData.orderId).then(data => {
            doUpdateSO(rowData.orderId, data, true)
          })
          }>{rowData.displayId}</a>
        }
      },
      {field: 'placedDate', header: 'Ngày lập', width: 120, matchMode: 'contains', dataType: 'date'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'},
      {field: 'customerPseudoId', header: 'Mã khách hàng', width: 150, matchMode: 'contains',},
      {field: 'customerName', header: 'Tên khách hàng', minWidth: 200, matchMode: 'contains',},
      {field: 'grandTotal', header: 'Tổng tiền', width: 140, matchMode: 'contains', dataType: 'number'},
      {field: 'vendorName', header: 'Nhà cung cấp', minWidth: 180, matchMode: 'contains'},
      {field: 'destFacilityPseudoId', header: 'Mã xưởng', minWidth: 140, matchMode: 'contains'},
      {field: 'orderName', header: 'Ghi chú', minWidth: 200, matchMode: 'contains'}

    ],
    indexColumnWidth: 45,
    actionColumnWidth: 120,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Xem', className: 'p-button-info', command: () => doUpdate(true, item)},
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(false, item)},
            {icon: 'pi pi-times', title: 'Hủy đơn hàng', className: 'p-button-danger', command: () => doCancel(item)},
          ]
        })

      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
  });

  const {renderCrupSO, doCreateSO, doUpdateSO} = useCrupSO({reloadLazyData});
  const doUpdate = (doView: boolean, rowData: any) => {
    Service.get(rowData.orderId).then(data => {
      doUpdateSO(rowData.orderId, data, doView);
    });

  }

  const doCancel = (rowData) => {
    ConfirmDialogService.confirmCancel(() => {
      Service.cancel(rowData.orderId).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    });
  }
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{header}</span>
          <div>
            <Button label={'Tạo mới'} icon="pi pi-plus" size={'small'} severity={'success'} style={{height:'25px'}} className={'mr-2'} onClick={()=>doCreateSO()}></Button>
            <ImportExcel column={{field: 'displayId', header: 'Số PO'}}
                         downloadTemplateUrl={`${Service.baseURL}/${Service.entity}/download-excel-template`} uploadExcel={contentFile => Service.uploadExcel(contentFile)}/>
          </div>
        </Fragment>
      </div>
    </div>
  )
  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 10rem)'}}>
        {renderDataTable()}
      </div>
      {renderCrupSO()}
      <OrderShipments Service={Service} type="outgoing" display={displayShipments} setDisplay={setDisplayShipments} reloadLazyData={reloadLazyData}/>
    </div>
  )
}