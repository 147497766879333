import {Fragment, useEffect, useLayoutEffect, useState} from 'react';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {ProductsToBuyService as Service} from './ProductsToBuyService';
import { Dialog } from 'primereact/dialog';
import {Button} from 'primereact/button';

export default function ProductsToBuy() {
  const header = "Danh sách cần mua";
  const dataKey = 'productId';

  const [productOrdering, setProductOrdering] = useState(null);
  const [displayProdOrder, setDisplayProdOrder] = useState(false);
  const [displayTrans, setDisplayTrans] = useState(false);
  const [transData, setTransData] = useState(null);
  const [headerDia, setHeaderDia] = useState('');
  const [syncTime, setSyncTime] = useState(null);

  useEffect(() => {
    Service.getSynTime().then(data => {
      setSyncTime(FormatDisplay.dateTime(data.lastSyncTime));
    })
  }, []);

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: header,
    stateKey:'mainTable',
    dataKey,
    columns:[
      {field:'pseudoId', header:'Mã sản phẩm', matchMode:'contains', minWidth:100},
      {field:'productName', header:'Tên sản phẩm', matchMode:'contains', minWidth:150},
      {field:'estimatedIssueQty', header:'Cần giao', matchMode:'contains', minWidth:100, dataType:'number'},
      {field:'quantityOnHandTotal', header:'Tồn hiện tại', matchMode:'contains', minWidth:100, dataType:'number'},
      {field:'estimatedReceiveQty', header:'Đang đặt mua', matchMode:'contains', minWidth:100, dataType:'number'},
      {field:'estimatedUnavailableQty', header:'Còn thiếu', matchMode:'contains', minWidth:100, dataType:'number'},
    ],
    indexColumnWidth:45,
    getList(lazyLoadEvent: any): Promise<any> {
      return Service.getList(lazyLoadEvent)
    },
    actionColumnWidth:90,
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions=[
            {icon: 'pi pi-box', title: 'Đang đặt mua', className: 'p-button-info', command: () => showOrdering(item)},
            {icon: 'pi pi-truck', title: 'Cần giao', className: 'p-button-secondary', command: () => showTransaction(item)},
          ]
        })
      }
    }
  })

  const {render: renderDataTableProductOrder} = useDataTableBasic({
    tableHeader:'',
    dataKey:'id',
    columns:[
      {field:'displayId', header:'Mã đơn hàng', minWidth:100},
      {field:'placedDate', header:'Ngày đặt hàng', minWidth:100, dataType:'date-time'},
      {field:'unitAmount', header:'Đơn giá', minWidth:100, dataType:'number'},
      {field:'quantity', header:'Số lượng', minWidth:100, dataType:'number'},
      {field:'quantityCompleted', header:'Đã nhập', minWidth:100, dataType:'number'},
      {field:'quantityNotComplete', header:'Còn lại', minWidth:100, dataType:'number'},
    ],
    indexColumnWidth:45,
    items: productOrdering
  })

  const {render: renderDataTableTrans} = useDataTableBasic({
    tableHeader:'',
    dataKey:'id',
    columns:[
      {field:'displayId', header:'Mã đơn hàng', minWidth:100},
      {field:'placedDate', header:'Ngày đặt hàng', minWidth:100, dataType:'date-time'},
      {field:'unitAmount', header:'Đơn giá', minWidth:100, dataType:'number'},
      {field:'quantity', header:'Số lượng', minWidth:100, dataType:'number'},
      {field:'quantityCompleted', header:'Đã xuất', minWidth:100, dataType:'number'},
      {field:'quantityNotComplete', header:'Còn lại', minWidth:100, dataType:'number'},
    ],
    indexColumnWidth:45,
    items: transData
  })

  const showOrdering = (item)=>{
    if (item.productId){
      Service.getOrdering(item.productId).then(({listData})=>{
        listData.forEach(item =>{
          item.id = `${item.orderId}_${item.orderItemSeqId}`
          return item
        })
        setProductOrdering(listData)
        setDisplayProdOrder(true);
        setHeaderDia(item.pseudoId)
      })
    }
  }

  const showTransaction = (item) =>{
    if (item.productId){
      Service.getTrans(item.productId).then(({listData})=>{
        listData.forEach(item =>{
          item.id = `${item.orderId}_${item.orderItemSeqId}`
          return item
        })
        setTransData(listData)
        setDisplayTrans(true)
        setHeaderDia(item.pseudoId)
      })
    }
  }


  const doSyncTime =()=>{
    ConfirmDialogService.confirm('Xác nhận đồng bộ','Bạn có muốn đồng bộ không?', ()=>{
      Service.doSynTime().then(()=>{
        ToastService.success('Đang đồng bộ vui lòng quay lại sau ít phút')
      })
    })
  }

  const headerProdOrder = (
    <h4>Đơn hàng chứa sản phẩm {headerDia} đang đặt mua</h4>
  )

  const headerTrans = (
    <h4>Đơn hàng chứa sản phẩm {headerDia} cần giao</h4>
  )
  return (
    <div className="grid flex" style={{justifyContent:'flex-end'}}>
      <div className="flex" style={{marginRight: '2vw'}}>
        <span className="mr-2 mt-3">Thời điểm đồng bộ: <strong>{syncTime}</strong></span>
        <Button icon="pi pi-sync" title="Đồng bộ" severity="secondary" rounded onClick={() => doSyncTime()}></Button>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17rem)'}}>
        {renderDataTable()}
      </div>
      <Dialog header={headerProdOrder} position="top" visible={displayProdOrder} style={{width: '80vw'}} onHide={() => setDisplayProdOrder(false)}>
        <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17rem)'}}>
          {renderDataTableProductOrder()}
        </div>
      </Dialog>
      <Dialog header={headerTrans} position="top" visible={displayTrans} style={{width: '80vw'}} onHide={() => setDisplayTrans(false)}>
        <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17rem)'}}>
          {renderDataTableTrans()}
        </div>
      </Dialog>
    </div>
  )
}