import {EntityService} from '../../../service/EntityService';

export class ProductsService extends EntityService {

  static entity = 'iam-product/products';

  static update(productId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${productId}/update`, data).then(res => res.data);
  }

  static createContent(productId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${productId}/contents`, data).then(res => res.data);
  }

  static deleteContent(productId: string, productContentId: string): Promise<any> {
    return this.axios.delete(`${this.entity}/${productId}/contents/${productContentId}`).then(res => res.data);
  }

  static getOriginGeos(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`iam-product/product-geo/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static getSuppliers(productId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${productId}/suppliers`).then(res => res.data);
  }
  static getHistory(productId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${productId}/purchase-histories`).then(res => res.data);
  }

}