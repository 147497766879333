import {useEffect} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {useDataTableBasic} from '@iamsoftware/react-hooks';

export const OrderShipmentItems = ({type, label, display, setDisplay}) => {

  const header = `Sản phẩm trong Phiếu ${label.header} #${display?.workEffortName}`;

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '80rem'}} position="top" onHide={() => setDisplay(false)}>
      <Screen type={type} label={label} items={display?.items}/>
    </Dialog>
  );
}

const Screen = ({type, label, items}) => {

  useEffect(() => {
    if (items?.length) {
      items.forEach(item => {
        item.vendorNameWithPseudoId = `[${item.fromPseudoId}] - ${item.fromPartyName}`;
        item.productNameWithPseudoId = `[${item.pseudoId}] - ${item.itemDescription}`;
      });
    }
  }, [items]);

  const {render: renderDataTable} = useDataTableBasic({
    tableHeader: `${items?.length} Sản phẩm`,
    dataKey: 'orderItemSeqId',
    columns: [
      {field: 'vendorNameWithPseudoId', header: 'Nhà cung cấp', minWidth: 200, matchMode: 'contains', hidden: type === 'outgoing'},
      {field: 'productNameWithPseudoId', header: 'Sản phẩm', minWidth: 200, matchMode: 'contains'},
      {field: 'quantity', header: 'SL', width: 130, matchMode: 'equals', dataType: 'number'},
      {field: 'quantityNotHandled', header: `SL chưa ${label.facility}`, width: 140, matchMode: 'equals', dataType: 'number'}
    ],
    indexColumnWidth: 45,
    items
  });

  return (
    <div className="grid">
      <div className="col-12">
        {renderDataTable()}
      </div>
    </div>
  );
}
