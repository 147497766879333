import { useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {ToastService, useDataTable, useDialogCrup, useToolbar, validateTaxCode} from '@iamsoftware/react-hooks';
import {HouseholdsService } from './HouseholdsService';
import {InternalService as Service} from './../../../app/quan-ly/to-chuc/InternalService';
import {PartyService} from "../../../service/PartyService";
import {AppStorage, UserStorage} from "../../../service/UserStorage";

export const DialogHouseholds = ({setSelectedItem}) => {
    const dataKey = 'partyId';
    const header='Tổ chức';
    const [crupMethod, setCrupMethod] = useState(null);
    const [data, setData] = useState(null);
    const me = JSON.parse(AppStorage.get('me'));

    useEffect(() => {
        if (UserStorage.get('household')) {
            HouseholdsService.get(UserStorage.get('household')).then(data => {
                if (data?.partyId) {
                    setData(data);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (data) {
            setSelectedItems(data);
        }
    }, [data]);

    const {render: renderDataTable, selectedItems, setSelectedItems, reloadLazyData,} = useDataTable({
        dataKey,
        columns: [
            {field: 'pseudoId', header: 'Mã', width: 110, matchMode: 'contains'},
            {field: 'partyName', header: 'Tên tổ chức', minWidth: 150, matchMode: 'contains'},
            {field: 'address1', header: 'Địa chỉ', minWidth: 170, matchMode: 'contains'},
            {field: 'comments', header: 'Ghi chú', width: 150, matchMode: 'contains'}
        ],
        getList: lazyLoadEvent => {
            return Service.getList(lazyLoadEvent);
        },
    });
    const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

    const doCreate = () => {
        create();
    }
    const doUpdate = (doView?: boolean) => {
        if (selectedItem && selectedItem[dataKey]) {
            Service.get(selectedItem[dataKey]).then(data => {
                if (doView === true) {
                    view(data);
                } else {
                    update(data);
                }
            });
        }
    }

    const getByTaxCode = () => {
        const _value = form.getValue();
        if (_value.pseudoId && validateTaxCode(_value.pseudoId)) {
            PartyService.getByTaxCode(_value.pseudoId).then(({data}) => {
                if (data?.partyName) {
                    _value.partyName = data.partyName;
                    _value.address1 = data.address1;
                    form.setValue(_value);
                }
            });
        } else {
            ToastService.error('Mã số thuế không hợp lệ');
        }
    }

    const {render: renderDialogCrup, create, update, view, form, formMethod} = useDialogCrup({
        header,
        dataKey,
        width: '60rem',
        fields: [
            {field: 'pseudoId', header: 'Mã tổ chức', required: true, className: 'md:col-3'},
            {
                field: 'getByTaxCode', type: 'custom', body: <Button icon="pi pi-search" rounded severity="success" onClick={getByTaxCode} disabled={crupMethod !== 'Create'}/>, className: 'md:col-1 pl-0 pt-4'
            },
            {field: 'partyName', header: 'Tên tổ chức', required: true, className: 'md:col-8'},
            {field: 'comments', header: 'Tên nước ngoài', className: 'md:col-12'},
            {field: 'emailAddress', header: 'Email', className: 'md:col-4'},
            {field: 'contactNumber', header: 'Số điện thoại', className: 'md:col-8'},
            {field: 'address1', header: 'Địa chỉ', type: 'InputTextarea', className: 'md:col-12'}
        ],
        createItem: async item => {
            return Service.create(item);
        },
        updateItem: async (id, item) => {
            return Service.update(id, item);
        },
        reloadLazyData
    });
    useEffect(() => {
        setCrupMethod(formMethod);
    }, [formMethod]);


    useEffect(() => {
        setSelectedItem(selectedItem);
    }, [selectedItem]); // eslint-disable-line react-hooks/exhaustive-deps

    const {renderToolbar} = useToolbar({
        doCreate:['Administrator'].includes(me.roleTypeId) && doCreate,
        hasSelectedItem: selectedItem,
        doView: () => doUpdate(true),
        // doUpdate,
    });
    return (
        <div className="grid">
            <div className="col-12">
                {renderToolbar()}
            </div>
            <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 29rem)'}}>
                {renderDataTable()}
            </div>
            {renderDialogCrup()}
        </div>
    );
}