import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {Fieldset} from 'primereact/fieldset';
import {InputText} from "primereact/inputtext";

import {ToastService, useDataTable, useDialogCrup, useForm, useToolbar, validateTaxCode} from '@iamsoftware/react-hooks';

import {SuppliersService as Service} from './SuppliersService';
import {PartyService} from '../../../service/PartyService';

import {ImportExcel} from '../../../shared/components/ImportExcel';
import {ProductPrices} from './ProductPrices';
import {PurchaseOrders} from './PurchaseOrders';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];

export const Suppliers = () => {

  const header = 'Nhà cung cấp';
  const dataKey = 'partyId';

  const [statusId, setStatusId] = useState(statuses[0].value);
  const [requiredParams, setRequiredParams] = useState(null);

  const [crupMethod, setCrupMethod] = useState(null);
  const [readOnly, setReadOnly] = useState(false)

  const [displayProductPrices, setDisplayProductPrices] = useState(null);
  const [displayPurchaseOrders, setDisplayPurchaseOrders] = useState(null);
  const [vendorPseudoId, setVendorPseudoId] = useState('');
  const [headerResult, setHeaderResult] = useState(null);
  useEffect(() => {
    setHeaderResult(tabHeader)
  }, []);
  useEffect(() => {
    if (statusId) {
      setRequiredParams({disabled: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    tableHeader: headerResult,
    dataKey,
    columns: [
      {
        field: 'pseudoId', header: 'Mã nhà cung cấp', width: 200, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => Service.get(rowData.partyId).then(data => {
            commonInfoForm.setValue(data);
            contactInfoForm.setValue(data);
            bankInfoForm.setValue(data);
            setReadOnly(false)
            update(data);
          })}>{rowData.pseudoId}</a>
        }
      },
      {field: 'partyName', header: 'Tên nhà cung cấp', minWidth: 150, matchMode: 'contains'},
      {field: 'comments', header: 'Tên nước ngoài', minWidth: 150, matchMode: 'contains'},
      {field: 'accountNumber', header: 'STK', minWidth: 150, matchMode: 'contains'},
      {field: 'bankName', header: ' Ngân hàng', minWidth: 150, matchMode: 'contains'},
      {field: 'accountName', header: 'Chủ tài khoản', minWidth: 150, matchMode: 'contains'},
      {field: 'updateTime', header: 'Ngày cập nhật', dataType: 'date', width: 150, matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 150,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Xem', className: 'p-button-info', command: () => doUpdate(true, item)},
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(false, item)},
            {icon: 'pi pi-box', title: 'Đơn hàng', className: 'p-button-help', command: () => setDisplayPurchaseOrders(item)},
          ]
          if (statusId === 'N') {
            item.actions.push({icon: 'pi pi-lock', title: 'Khóa', className: 'p-button-danger', command: () => doDisable(item)})
          } else if (statusId === 'Y') {
            item.actions.push({icon: 'pi pi-lock-open', title: 'Mở khóa', className: 'p-button-success', command: () => doEnable(item)})
          }
        })

      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const getByTaxCode = () => {
    if (crupMethod !== 'View') {
      const _value = commonInfoForm.getValue();
      if (vendorPseudoId && validateTaxCode(vendorPseudoId)) {
        PartyService.getByTaxCode(vendorPseudoId).then(({data}) => {
          if (data?.partyName) {
            _value.partyName = data.partyName;
            _value.address1 = data.address1;
            commonInfoForm.setValue(_value);
          }
        });
      } else {
        ToastService.error('Mã số thuế không hợp lệ');
      }
    }
  }
  const commonInfoForm = useForm({
    fields: [
      {
        field: 'pseudoId', type: 'custom', className: 'md:col-4', body: <div>
          <div>
            <div>
              Mã NCC<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={vendorPseudoId} onChange={e => setVendorPseudoId(e.target.value)}/>
              <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getByTaxCode}>
            <i className="pi pi-search"/>
          </span>
            </div>
          </div>
        </div>
      },
      {field: 'partyName', header: 'Tên nhà cung cấp', required: true, className: 'md:col-4'},
      {field: 'comments', header: 'Tên nước ngoài', className: 'md:col-4'},
      {field: 'contactNumber', header: 'Số điện thoại', className: 'md:col-4'},
      {field: 'address1', header: 'Địa chỉ', className: 'md:col-8'},
    ],
    readOnly: readOnly
  });
  const contactInfoForm = useForm({
    fields: [
      {field: 'contactPartyName', header: 'Tên người liên hệ', className: 'md:col-3'},
      {field: 'contactEmailAddress', header: 'Email', className: 'md:col-3'},
      {field: 'contactPhoneNumber', header: 'Số điện thoại người liên hệ', className: 'md:col-3'},
      {field: 'contactJobTitle', header: 'Chức vụ', className: 'md:col-3'},
    ],
    readOnly: readOnly
  })
  const bankInfoForm = useForm({
    fields: [
      {field: 'accountNumber', header: 'STK', className: 'md:col-4'},
      {field: 'bankName', header: ' Ngân hàng', className: 'md:col-4'},
      {field: 'accountName', header: 'Chủ tài khoản', className: 'md:col-4'},
    ],
    readOnly: readOnly
  });

  const {render: renderDialogCrup, create, view, update, formMethod} = useDialogCrup({
    header,
    dataKey,
    width: '90rem',
    fields: [
      {
        type: 'custom', className: 'md:col-12 mb-3',
        body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-12 mb-3',
        body: <Fieldset legend="Thông tin liên hệ">
          {contactInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-12 mb-3',
        body: <Fieldset legend="Tài khoản ngân hàng">
          {bankInfoForm.render()}
        </Fieldset>
      },
    ],
    createItem: async item => {
      return Service.create(await inject(item));
    },
    updateItem: async (id, item) => {
      return Service.update(id, await inject(item));
    },
    reloadLazyData
  });

  const inject = item => {
    const commonInfo = commonInfoForm.getValue();
    const contactInfo = contactInfoForm.getValue();
    const bankInfo = bankInfoForm.getValue();
    return Object.assign(item, commonInfo, contactInfo, bankInfo);
  }


  useEffect(() => {
    setCrupMethod(formMethod);
  }, [formMethod]);

  useEffect(() => {

  }, [dataKey]);

  const doCreate = () => {
    commonInfoForm.reset();
    contactInfoForm.reset();
    bankInfoForm.reset();
    setReadOnly(false)
    setVendorPseudoId('')
    create();
  }

  const doUpdate = (doView: boolean, rowData) => {
    Service.get(rowData[dataKey]).then(data => {
      setVendorPseudoId(data.pseudoId)
      commonInfoForm.setValue(data);
      contactInfoForm.setValue(data);
      bankInfoForm.setValue(data);
      if (doView === true) {
        setReadOnly(true)
        view(data);
      } else {
        setReadOnly(false)
        update(data);
      }
    });

  }

  const doDisable = (rowData) => {
    Service.disable(rowData[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    });

  }
  const doEnable = (rowData) => {
    Service.enable(rowData[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    });
  }

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{header}</span>
          <div>
            <Button label={'Tạo mới'} icon="pi pi-plus" size={'small'} severity={'success'} style={{height: '25px'}} className={'mr-2'} onClick={() => doCreate()}></Button>
            <ImportExcel column={{field: 'pseudoId', header: 'Mã NCC'}}
                         downloadTemplateUrl={`${Service.baseURL}/iam-party/suppliers/download-excel-template`} uploadExcel={contentFile => Service.uploadExcel(contentFile)}/>
          </div>
        </Fragment>
      </div>
    </div>
  )


  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 14rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}

      <ProductPrices display={displayProductPrices} setDisplay={setDisplayProductPrices}/>
      <PurchaseOrders display={displayPurchaseOrders} setDisplay={setDisplayPurchaseOrders}/>
    </div>
  )
}