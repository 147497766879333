import axios from 'axios';

import {ToastService} from '@iamsoftware/react-hooks';

import {LoadingService} from '../AppLoading';

import {baseURL} from '../config';

import {AuthService} from './AuthService';

import {AppStorage, UserStorage} from './UserStorage';

const _axios = axios.create({
  baseURL
});

_axios.interceptors.request.use(config => {
  LoadingService.start();

  config.headers['X-CSRF-Token'] = AppStorage.get('moquiSessionToken');
  try {
    const me = JSON.parse(AppStorage.get('me'));
    const ownerPartyId = UserStorage.get('household');
    if (['Administrator', 'Manager', 'Employee'].includes(me.roleTypeId) && ownerPartyId) {
      config.params = {...(config.params || {}), ownerPartyId};
    }
  } catch (e) {
  }

  return config;
}, error => {
  return Promise.reject(error);
});
_axios.interceptors.response.use(response => {
  LoadingService.stop();
  return response;
}, error => {

  LoadingService.stop();

  if (error && error.response && error.response.status === 401) {
    AuthService.getToken().then(() => {
      window.location.hash = '/login';
    });
  } else if (error.response && error.response.data && error.response.data.errors) {
    ToastService.error(error.response.data.errors);
  }
  return Promise.reject(error);
});

export default _axios;
