import {Fragment, useEffect, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {
  FormMethod,
  ToastService, useDataTable,
  useDataTableBasic,
  useDialogCrup
} from '@iamsoftware/react-hooks';

import {ProductsService as Service} from "./ProductsService";

export const CrupPurchaseHistory = ({display, setDisplay}) => {

  const dataKey = 'productId';
  const [header, setHeader] = useState('');
  const [formMethod, setFormMethod] = useState<FormMethod>('View');
  const [items, setItems] = useState([]);


  useEffect(() => {
    setFormMethod('View');
    loadItems();
  }, [display?.productId]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadItems = () => {
    if (display?.productId) {
      Service.getHistory(display.productId).then(data => {
        setItems(data.listData);
      });
    }
  }
  const {render: renderDataTable} = useDataTableBasic({
    tableHeader:'Lịch sử mua hàng',
    dataKey,
    columns: [
      {field: 'displayId', header: 'Mã đơn hàng', minWidth: 100},
      {field: 'vendorPseudoId', header: 'Mã nhà cung cấp', minWidth: 100},
      {field: 'vendorName', header: 'Tên nhà cung cấp', minWidth: 400},
      {field: 'unitAmount', header: 'Đơn giá', minWidth: 100, dataType:'number'},
      {field: 'quantityAccepted', header: 'Số lượng', minWidth: 75, dataType:'number'},
      {field: 'placedDate', header: 'Ngày lập', minWidth: 100, dataType:'date-time'},
    ],
    indexColumnWidth: 45,
    items
  });


  return (
    <Dialog header={header} visible={!!display} style={{width: '80rem'}} position="top" onHide={() => setDisplay(false)}>
      <br/>
      {renderDataTable()}
    </Dialog>
  );
}
