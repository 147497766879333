import {Fragment, useEffect, useLayoutEffect, useState} from 'react';
import {ConfirmDialogService, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {InventoriesService as Service} from './InventoriesService';
import {FacilitiesService} from '../kho-hang/FacilitiesService';
import {InvenHistory} from './invenHistory';
import {ProductsService} from '../../ban-hang/san-pham/ProductsService';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Button } from 'primereact/button';

export default function KiemKho (){
  const header = 'Tồn kho';
  const dataKey = 'productId';
  const [fieldItems, setFieldItems] = useState<Array<any>>([])
  const [display, setDisplay] = useState(null);
  const [historyType, setHistoryType] = useState(null);
  const [products,setProducts]= useState(null)
  const [selectedProducts, setSelectedProducts] = useState('');

  useEffect(() => {
    FacilitiesService.getList('').then( data =>{
      const _field = [
        {field: 'product', header: 'Tên sản phẩm', minWidth: 250,dataType:'custom',customCell(rowData: any): any {
            return <a>{rowData.pseudoId} - {rowData.productName}</a>
          }},
        {field: 'productPrice', header: 'Giá bán', minWidth: 100,  dataType: 'number', hidden: true},
      ]
      if (data.listData?.length){
        data.listData.forEach(item =>{
          if (item.statusId === 'FacActive'){
            _field.push({field: `_${item.pseudoId}`, header: item.pseudoId,  minWidth: 150,dataType: 'number', hidden: false})
          }
        })
      }
      setFieldItems(_field)
    })
    ProductsService.getList('').then(({listData})=>{
      setProducts(listData.map(item =>{
        return {value: item.productId, label:`${item.pseudoId}`}
      }))
    })
  }, []);

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader:header,
    stateKey:'mainTable',
    dataKey,
    columns:fieldItems,
    indexColumnWidth: 45,
    actionColumnWidth:90,
    getList:  lazyLoadEvent => {
      return Service.getList(lazyLoadEvent,selectedProducts).then()
    },
    initActions(items: Array<any>) {
      if (items){
        items.forEach(item =>{
          item.actions = [
            {icon: 'pi pi-angle-double-down', title: 'Lịch sử nhập kho', className: 'p-button-secondary', command: () => doHistory(item, 'Receipt')},
            {icon: 'pi pi-angle-double-up', title: 'Lịch sử xuất kho', className: 'p-button-warning', command: () => doHistory(item, 'Issuance')},
          ]
        })
      }
    }
  })

  const doHistory = (item, type) => {
    setHistoryType(type)
    setDisplay(item)
  }


  return (
    <div className="grid">
      <div className="col-12 pb-0 flex" style={{flexDirection:'column'}}>
        <label className="mb-2">Tìm kiếm</label>
        <div className="flex">
          <MultiSelect value={selectedProducts} onChange={(e) => setSelectedProducts(e.value)} options={products} optionLabel="label"
                       filter placeholder="Chọn mã sản phẩm" maxSelectedLabels={3} className="md:w-20rem" />
          <Button className="ml-2" icon="pi pi-search" rounded severity="success" aria-label="Search" onClick={()=> reloadLazyData()}/>
        </div>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17rem)'}}>
        {renderDataTable()}
      </div>
      <InvenHistory display={display} setDisplay={setDisplay} historyType={historyType}/>
    </div>
  );
}
