import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {AccountsService as Service} from './AccountsService';
import {InternalService} from "../to-chuc/InternalService";

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];

export const Accounts = () => {

  const header = 'Người dùng';
  const dataKey = 'userId';

  const [statusId, setStatusId] = useState(statuses[0].value);
  const [requiredParams, setRequiredParams] = useState(null);

  const [roleTypes, setRoleTypes] = useState([]);
  const [organizationPartyIds, setOrganizationPartyIds] = useState([]);
  const [headerResult, setHeaderResult] = useState(null);

  useEffect(() => {
    InternalService.getList('').then(({listData}) => {
      setOrganizationPartyIds(listData.map(item => {
        return {value: item.partyId, label: item.partyName};
      }));
    });
    setHeaderResult(tabHeader)

  }, []);

  useEffect(() => {
    Service.getRoles().then(({listData}) => {
      setRoleTypes(listData.map(item => {
        return {value: item.roleTypeId, label: item.description};
      }));
    });
  }, []);


  useEffect(() => {
    if (statusId) {
      setRequiredParams({disabled: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    tableHeader: header,
    dataKey,
    columns: [
      {
        field: 'pseudoId', header: 'Tài khoản', width: 200, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => Service.get(rowData.userId).then(data => {
            update(data);
          })}>{rowData.username}</a>
        }
      },
      {field: 'partyName', header: 'Tên người dùng', minWidth: 250, matchMode: 'contains'},
      {field: 'roleTypeId', header: 'Vai trò', width: 200, matchMode: 'equals', filterType: 'dropdown', filterOptions: roleTypes, dataType: 'fromFilterOptions'},
      {field: 'organizationPseudoId', header: 'Mã tổ chức', minWidth: 250, matchMode: 'contains'},
      {field: 'organizationName', header: 'Tên tổ chức', minWidth: 250, matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }, actionColumnWidth: 120,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(item)},
          ]
          if (statusId === 'N') {
            item.actions.push({icon: 'pi pi-lock', title: 'Khóa', className: 'p-button-danger', command: () => doDisable(item)})
          } else if (statusId === 'Y') {
            item.actions.push({icon: 'pi pi-lock-open', title: 'Mở khóa', className: 'p-button-success', command: () => doEnable(item)})
          }
        })

      }
    },
    requiredParams
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'partyName', header: 'Tên người dùng', required: true, className: 'md:col-6'},
      {field: 'username', header: 'Tài khoản', required: true, InputTextProps: {maxLength: 50, keyfilter: /\S/}, className: 'md:col-6'},
      {field: 'emailAddress', header: 'Email', required: true, InputTextProps: {maxLength: 50}, className: 'md:col-6'},
      {
        field: 'roleTypeId', header: 'Vai trò', required: true, type: 'Dropdown', DropdownProps: {options: roleTypes}, displayDependency(item: any): boolean {
          return !item.userId;
        }, className: 'md:col-6'
      },
      {
        field: 'organizationPartyIds', header: 'Tổ chức', type: 'MultiSelect',
        MultiSelectProps: {options: organizationPartyIds, display: 'chip', filter: true},
        className: 'md:col-12'
      },
    ],
    createItem: item => {
      item.pseudoId = item.username;
      item.userFullName = item.partyName;
      item.password = '12345678';
      return Service.create(item);
    },
    updateItem: (id, item) => {
      item.pseudoId = item.username;
      item.userFullName = item.partyName;
      return Service.update(id, item);
    },
    reloadLazyData
  });

  const doUpdate = (rowData) => {
    Service.get(rowData[dataKey]).then(data => {
      update(data);
    });
  }

  const doDisable = (rowData) => {
    Service.disable(rowData[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    });
  }

  const doEnable = (rowData) => {
    Service.enable(rowData[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    });
  }


  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{header}</span>
          <div>
            <Button label={'Tạo mới'} icon="pi pi-plus" style={{height: '25px'}} size={'small'} severity={'success'} className={'mr-2'} onClick={() => create()}></Button>
          </div>
        </Fragment>
      </div>
    </div>
  )
  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 14rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}
