import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {TabView, TabPanel} from 'primereact/tabview';
import {AutoComplete} from 'primereact/autocomplete';

import {ConfirmDialogService, useDialogCrup} from '@iamsoftware/react-hooks';

import {ProductsService} from '../../ban-hang/san-pham/ProductsService';
import {QuantityUomsService} from '../../quan-ly/danh-muc/don-vi-san-pham/QuantityUomsService';
import {AccountsService} from '../../quan-ly/nguoi-dung/AccountsService';

import {CrupPOPartForm} from './CrupPOPartForm';
import {CrupPOPartItems} from './CrupPOPartItems';

export const useCrupPOParts = ({disabled, onDeleteOrderItem, setDisplayProductContents}) => {

  const dataKey = 'dataKey';

  const [parts, setParts] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const [selectedItem, setSelectedItem] = useState(null);
  const [productName, setProductName] = useState(null);

  const [products, setProducts] = useState([]);
  const [quantityUoms, setQuantityUoms] = useState([]);
  const [operatorParties, setOperatorParties] = useState([]);
  useEffect(() => {
    if (parts?.length) {
      parts.forEach(part => {
        if (!part.items) {
          part.items = []
        }
        part?.items.forEach((item, index) => {
          if (!item[dataKey]) {
            item[dataKey] = `${item.productId}_${item.orderId}_${item.orderItemSeqId}_${index}_${Math.random()}`;
          }
        });
      });
    }
  }, [parts]); // eslint-disable-line react-hooks/exhaustive-deps
  const searchProducts = event => {
    const _event: any = {
      filters: {
        statusId: {value: 'ProdActive', matchMode: 'equals'},
        productName: {value: event.query, matchMode: 'contains'}
      }
    };
    ProductsService.getList(JSON.stringify(_event)).then(data => {
      setProducts(data.listData.map(item => {
        delete item.disabled;
        let label = `[${item.pseudoId}] - ${item.productName}`;
        if (item.localizedName) {
          label += ` | ${item.localizedName}`;
        }
        return {...item, value: item.productId, label};
      }));
    });
  }
  const searchOperatorParties = event => {
    const _event: any = {
      filters: {
        disabled: {value: 'N', matchMode: 'equals'},
        partyName: {value: event.query, matchMode: 'contains'}
      }
    };
    AccountsService.getList(JSON.stringify(_event)).then(data => {
      setOperatorParties(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`};
      }));
    });
  }


  const searchQuantityUoms = event => {
    const _event: any = {
      filters: {
        disabled: {value: 'N', matchMode: 'equals'},
        description: {value: event.query, matchMode: 'contains'}
      }
    };
    QuantityUomsService.getList(JSON.stringify(_event)).then(data => {
      setQuantityUoms(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.uomId, label: item.description};
      }));
    });
  }


  const {render: renderDialogCrup, create, update, form} = useDialogCrup({
    header: '!Sản phẩm',
    dataKey,
    width: '50rem',
    fields: [
      {field: 'partyId', type: 'hidden'},
      {
        field: 'product', header: 'Tên sản phẩm', type: 'custom', className: 'md:col-12 mt-4 ',
        body:
          <div className={'p-inputgroup'}>
            <AutoComplete value={productName} suggestions={products} completeMethod={searchProducts} onChange={e => setProductName(e.value)} field="label" dropdown={true} placeholder="Tên sản phẩm"/>
            {!!productName && <span className="p-inputgroup-addon" style={{background: '#22C55E', color: 'white', cursor: 'pointer'}} onClick={() => setDisplayProductContents(productName)}>
            <i className="pi pi-image"/>
          </span>}
          </div>
      },
      {field: 'unitListPrice', header: 'Đơn giá', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'quantity', header: 'Số lượng', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {
        field: 'qtyUom', header: 'Đơn vị tính', required: true, type: 'AutoComplete',
        AutoCompleteProps: {field: 'label', suggestions: quantityUoms, completeMethod: searchQuantityUoms, dropdown: true, forceSelection: true},
        className: 'md:col-4'
      },
      {field: 'discountPercentage', header: 'Tỷ lệ CK', type: 'InputNumber', InputNumberProps: {min: 0, maxFractionDigits: 2, suffix: '%'}, className: 'md:col-4'},
      {field: 'discountAmount', header: 'Số tiền CK', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'sourcePercentage', header: 'Thuế suất', type: 'InputNumber', InputNumberProps: {min: 0, max: 100, maxFractionDigits: 2, suffix: '%'}, className: 'md:col-4'},
      {field: 'amountAlreadyIncluded', header: 'Tiền thuế', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'amountExcludeTax', header: 'Thành tiền trước thuế', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'amountTotal', header: 'Thành tiền sau thuế', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {
        field: 'operatorParty', header: 'Nhân viên phụ trách', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: operatorParties, completeMethod: searchOperatorParties, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-12'
      },
      {field: 'comments', header: 'Ghi chú', type: 'Editor', className: 'md:col-12'}
    ],
    createItem: item => {
      const _item: any = {
        dataKey: `${Date.now()}_${Math.random()}`,
        productId: productName.value,
        pseudoId: productName.pseudoId,
        productName: productName.productName,
        itemDescription: productName.productName,
        unitListPrice: item.unitListPrice,
        quantity: item.quantity,
        quantityUomId: item.qtyUom?.value,
        quantityUom: item.qtyUom?.label,
        discountPercentage: item.discountPercentage,
        discountAmount: item.discountAmount,
        sourcePercentage: item.sourcePercentage,
        amountAlreadyIncluded: item.amountAlreadyIncluded,
        amountExcludeTax: item.amountExcludeTax,
        amountTotal: item.amountTotal,
        operatorPartyId: item.operatorParty?.value,
        operatorPseudoId: item.operatorParty?.pseudoId,
        operatorName: item.operatorParty?.partyName,
        customerContactName: item.customerContactName,
        comments: item.comments
      };
      _item.dataKey = `${_item.productId}_${Date.now()}_${Math.random()}`;
      ;
      setParts(prevState => {
        for (const part of prevState) {
          if (part.partyId === item.partyId) {
            part.items.push(_item);
            break;
          }
        }
        return [...prevState];
      });
      return Promise.resolve(item);
    },
    updateItem: (id, item) => {
      setParts(prevState => {
        for (const part of prevState) {
          if (part.vendorPartyId === item.partyId) {
            for (const part_item of part.items) {
              if (part_item[dataKey] === id) {
                part_item.productId = productName.value;
                part_item.pseudoId = productName.pseudoId;
                part_item.productName = productName.productName;
                part_item.itemDescription = productName.productName;
                part_item.unitListPrice = item.unitListPrice;
                part_item.quantity = item.quantity;
                part_item.quantityUomId = item.qtyUom?.value;
                part_item.quantityUom = item.qtyUom?.label;
                part_item.discountPercentage = item.discountPercentage;
                part_item.discountAmount = item.discountAmount;
                part_item.sourcePercentage = item.sourcePercentage;
                part_item.amountAlreadyIncluded = item.amountAlreadyIncluded;
                part_item.amountExcludeTax = item.amountExcludeTax;
                part_item.amountTotal = item.amountTotal;
                part_item.operatorPartyId = item.operatorParty?.value;
                part_item.operatorPseudoId = item.operatorParty?.pseudoId;
                part_item.operatorName = item.operatorParty?.partyName;
                part_item.customerContactName = item.customerContactName;
                part_item.comments = item.comments;
                break;
              }
            }
            break;
          }
        }
        return [...prevState];
      });
      return Promise.resolve(item);
    },
    hideToastMessage: true
  });
  const product = form.getRawValue();
  useEffect(() => {
    if (productName) {
      const _value = form.getRawValue();
      _value.unitListPrice = productName.salePrice;
      if (productName.amountUomId) {
        _value.qtyUom = {value: productName.amountUomId, label: productName.uomName};
      } else {
        delete _value.qtyUom
      }
      form.setValue(_value);
    }
  }, [productName]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const _value = form.getRawValue();
    if (product?.quantity && product?.unitListPrice) {
      if (product?.discountPercentage) {
        _value.discountAmount = product?.quantity * product.unitListPrice * product.discountPercentage / 100
      }
      _value.amountExcludeTax = product?.quantity * product.unitListPrice - _value?.discountAmount
      if (product?.sourcePercentage) {
        _value.amountAlreadyIncluded = _value.amountExcludeTax * product.sourcePercentage / 100

      }
      _value.amountTotal = _value?.amountExcludeTax + _value?.amountAlreadyIncluded
    }
    form.setValue(_value)
  }, [product?.quantity, product?.unitListPrice, product?.discountPercentage, product?.sourcePercentage]); // eslint-disable-line react-hooks/exhaustive-deps

  const setPartProperty = (part, property, value) => {
    setParts(prevState => {
      for (const prevPart of prevState) {
        if (prevPart.partyId === part.partyId) {
          part[property] = value;
          break;
        }
      }
      return [...prevState];
    });
  }


  const onItemSelect = (part, item) => {
    if (item) {
      item.vendorParty = {value: part.vendorPartyId, label: `[${part.vendorPseudoId}] - ${part.vendorPartyName}`};
    }
    setSelectedItem(item);
  }

  const doDeleteProduct = (selectedItem) => {
    ConfirmDialogService.confirm('Xóa sản phẩm', `Xác nhận xóa sản phẩm [${selectedItem.pseudoId}] - ${selectedItem.productName} ra khỏi danh sách?`, () => {
      setParts(prevState => {
        for (const part of prevState) {

          if (part.partyId === selectedItem.partyId) {
            part.items = part.items.filter(item => item[dataKey] !== selectedItem[dataKey]);
            break;
          }
        }
        return [...prevState];
      });
      if (selectedItem.orderItemSeqId) {
        onDeleteOrderItem(selectedItem.orderItemSeqId);
      }
    });
  }
  const doUpdate = (data) => {
    let label = `[${data.pseudoId}] - ${data.productName}`
    setProductName({value: data.productId, label: label, productName: data.productName, pseudoId: data.pseudoId})

    if (data?.quantityUomId && data?.quantityUom) {
      data.qtyUom = {value: data?.quantityUomId, label: data?.quantityUom};
    }
    data.operatorParty = {
      pseudoId: data.operatorPseudoId,
      partyName: data.operatorName,
      value: data.operatorPartyId,
      label: `[${data.operatorPseudoId}] - ${data.operatorName}`
    };
    update(data)
  }
  const doCreate = () => {
    setProductName(null)
    create()
  }

  const renderParts = () => {
    return (
      <div className="grid">
        <div className="col-12 py-0">
          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            {parts?.map((part, index) => <TabPanel key={`${index}_${activeIndex}`} header={part.partyName || part.vendorPartyName}>
              <CrupPOPartForm disabled={disabled} part={part} setPartProperty={setPartProperty}/>
              <CrupPOPartItems part={part} onItemSelect={onItemSelect} doCreate={doCreate} doUpdate={doUpdate} doDelete={doDeleteProduct}/>
            </TabPanel>)}
          </TabView>
        </div>

        {renderDialogCrup()}
      </div>
    );
  }

  return {renderParts, parts, setParts};
}