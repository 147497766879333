import {Fragment, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';

import {Badge} from 'primereact/badge';
import {classNames} from 'primereact/utils';
import {Ripple} from 'primereact/ripple';

export const AppMenu = ({me, onMenuItemClick}) => {

  const appMenus: Array<any> = [
    {
      label:'',
      items: [{label: 'Tổng quan',icon:'pi pi-desktop', to: '/dashboard'}]
    },
    {
      label: 'Bán hàng',
      items: [
        {label: 'Đơn hàng bán', icon: 'pi pi-fw pi-shopping-cart', to: '/ban-hang/don-hang-ban'},
        {label: 'Lịch giao SO', icon: 'pi pi-fw pi-calendar', to: '/ban-hang/lich-SO'},
        {label: 'Sản phẩm', icon: 'pi pi-fw pi-box', to: '/ban-hang/san-pham'},
        {label: 'Khách hàng', icon: 'pi pi-fw pi-users', to: '/ban-hang/khach-hang'},
        // {
        //   label: 'Báo cáo', icon: 'pi pi-fw pi-chart-line', items: [
        //     {label: 'Báo cáo doanh thu', icon: 'pi pi-fw pi-circle', to: '/ban-hang/bao-cao/doanh-thu'},
        //     {label: 'Doanh thu theo SP', icon: 'pi pi-fw pi-circle', to: '/ban-hang/bao-cao/doanh-thu-theo-san-pham'},
        //     {label: 'Doanh thu theo KH', icon: 'pi pi-fw pi-circle', to: '/ban-hang/bao-cao/doanh-thu-theo-khach-hang'}
        //   ]
        // }
      ], visible: ['Administrator', 'Manager', 'Employee'].includes(me.roleTypeId)
    },
    {
      label: 'Mua hàng',
      items: [
        {label: 'Đơn hàng mua', icon: 'pi pi-fw pi-cart-plus', to: '/mua-hang/don-hang-mua'},
        {label: 'Thanh toán', icon: 'pi pi-fw pi-paypal', to: '/mua-hang/thanh-toan'},
        {label: 'Sản phẩm cần mua', icon: 'pi pi-fw pi-plus-circle', to: '/mua-hang/san-pham-can-mua'},
        {label: 'Lịch giao PO', icon: 'pi pi-fw pi-calendar', to: '/mua-hang/lich-PO'},
        {label: 'Nhà cung cấp', icon: 'pi pi-fw pi-id-card', to: '/mua-hang/nha-cung-cap'},
        // {
        //   label: 'Báo cáo', icon: 'pi pi-fw pi-chart-line', items: [
        //     {label: 'Báo cáo lượng mua', icon: 'pi pi-fw pi-circle', to: '/mua-hang/bao-cao/luong-mua'},
        //     {label: 'Lượng mua theo NCC', icon: 'pi pi-fw pi-circle', to: '/mua-hang/bao-cao/luong-mua-theo-nha-cung-cap'}
        //   ]
        // }
      ], visible: ['Administrator', 'Manager', 'Employee'].includes(me.roleTypeId)
    },
    {
      label: 'Kho',
      items: [
        {label: 'Kho hàng', icon: 'pi pi-fw pi-inbox', to: '/kho-van/kho-hang'},
        {label: 'Nhập kho', icon: 'pi pi-fw pi-download', to: '/kho-van/nhap-kho'},
        {label: 'Xuất kho', icon: 'pi pi-fw pi-upload', to: '/kho-van/xuat-kho'},
        {label: 'Tồn kho', icon: 'pi pi-fw pi-database', to: '/kho-van/ton-kho'},
        {label: 'Phiếu gộp', icon: 'pi pi-fw pi-file-edit', to: '/kho-van/gop-phieu-xuat'},
        // {
        //   label: 'Báo cáo', icon: 'pi pi-fw pi-chart-line', items: [
        //     {label: 'Tổng hợp tồn kho', icon: 'pi pi-fw pi-circle', to: '/kho-van/bao-cao/tong-hop-ton'},
        //     {label: 'Chi tiết tồn kho', icon: 'pi pi-fw pi-circle', to: '/kho-van/bao-cao/chi-tiet-ton'},
        //     {label: 'Xuất - Nhập - Tồn', icon: 'pi pi-fw pi-circle', to: '/kho-van/bao-cao/xuat-nhap-ton'}
        //   ]
        // }
      ], visible: ['Administrator', 'Manager', 'Employee'].includes(me.roleTypeId)
    },
    {
      label: 'Hồ sơ ',
      items: [
        {label: 'Thanh toán', icon: 'pi pi-fw pi-credit-card', to: '/ho-so-thanh-toan/thanh-toan'},
        {label: 'Biểu mẫu', icon: 'pi pi-fw pi-align-left', to: '/ho-so-thanh-toan/bieu-mau'},
      ], visible: ['Administrator', 'Manager', 'Employee'].includes(me.roleTypeId)
    },
    {
      label: 'Báo cáo',
      items: [
        {label: 'Báo cáo tiến độ mua hàng theo PO', icon: 'pi pi-fw pi-chart-line', to: '/bao-cao/bao-cao-mua'},
        {label: 'Báo cáo số lượng cần giao tiếp của từng PO', icon: 'pi pi-fw pi-chart-line', to: '/bao-cao/bao-cao-sl'},
        {label: 'Báo cáo xuất nhập tồn', icon: 'pi pi-fw pi-chart-line', to: '/bao-cao/bao-cao-nhap-xuat'},
      ], visible: ['Administrator', 'Manager', 'Employee'].includes(me.roleTypeId)
    },
    {
      label: 'Quản lý',
      items: [
        {label: 'Người dùng', icon: 'pi pi-fw pi-user', to: '/quan-ly/nguoi-dung', visible: ['Administrator', 'Manager'].includes(me.roleTypeId)},
        {label: 'Tổ chức nội bộ', icon: 'pi pi-fw pi-briefcase', to: '/quan-ly/to-chuc', visible: ['Administrator'].includes(me.roleTypeId)},
        {
          label: 'Danh mục', icon: 'pi pi-fw pi-folder',
          items: [
            {label: 'Đơn vị sản phẩm', to: '/quan-ly/danh-muc/don-vi-san-pham'}
          ], visible: ['Administrator', 'Manager'].includes(me.roleTypeId)
        }
      ], visible: ['Administrator', 'Manager'].includes(me.roleTypeId)
    }
  ];

  return (
    <div className="layout-menu-container">
      <AppSubmenu items={appMenus} className="layout-menu" onMenuItemClick={onMenuItemClick} root={true} role="menu"/>
    </div>
  );
}

const AppSubmenu = (props) => {

  const [activeIndex, setActiveIndex] = useState(null)

  const onMenuItemClick = (event, item, index) => {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //execute command
    if (item.command) {
      item.command({originalEvent: event, item: item});
    }

    if (index === activeIndex)
      setActiveIndex(null);
    else
      setActiveIndex(index);

    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item: item
      });
    }
  }

  const onKeyDown = event => {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      event.target.click();
    }
  }

  const renderLinkContent = item => {
    let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
    let badge = item.badge && <Badge value={item.badge}/>

    return (
      <Fragment>
        <i className={item.icon}></i>
        <span>{item.label}</span>
        {submenuIcon}
        {badge}
        <Ripple/>
      </Fragment>
    );
  }

  const renderLink = (item, i) => {
    let content = renderLinkContent(item);

    if (item.to) {
      return (
        <NavLink aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" className={({isActive}) => isActive ? 'p-ripple router-link-active router-link-exact-active' : 'p-ripple'} to={item.to} onClick={(e) => onMenuItemClick(e, item, i)} target={item.target} end>
          {content}
        </NavLink>
      )
    } else {
      return (
        <a tabIndex={0} aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" href={item.url} className="p-ripple" onClick={(e) => onMenuItemClick(e, item, i)} target={item.target}>
          {content}
        </a>
      );
    }
  }

  let items = props.items && props.items.filter(item => item.visible !== false).map((item, i) => {
    let active = activeIndex === i;
    let styleClass = classNames(item.badgeStyleClass, {'layout-menuitem-category': props.root, 'active-menuitem': active && !item.to});

    if (props.root) {
      return (
        <li className={styleClass} key={i} role="none">
          {props.root === true && <Fragment>
						<div className="layout-menuitem-root-text" aria-label={item.label}>{item.label}</div>
						<AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick}/>
					</Fragment>}
        </li>
      );
    } else {
      return (
        <li className={styleClass} key={i} role="none">
          {renderLink(item, i)}
          <CSSTransition classNames="layout-submenu-wrapper" timeout={{enter: 1000, exit: 450}} in={active} unmountOnExit>
            <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick}/>
          </CSSTransition>
        </li>
      );
    }
  });

  return items ? <ul className={props.className} role="menu">{items}</ul> : null;
}