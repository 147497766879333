import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {useDataTable} from '@iamsoftware/react-hooks';

import {SuppliersService as Service} from './SuppliersService';

export const PurchaseOrders = ({display, setDisplay}) => {

  const header = `Đơn hàng nhà cung cấp #${display?.pseudoId} - ${display?.partyName}`;

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(false)}>
      <Screen partyId={display?.partyId}/>
    </Dialog>
  );
}

const Screen = ({partyId}) => {

  const dataKey = 'orderId';

  const {render: renderDataTable} = useDataTable({
    dataKey,
    columns: [
      {field: 'orderId', header: 'Mã đơn hàng', width: 180, matchMode: 'contains'},
      {field: 'entryDate', header: 'Ngày', width: 170, dataType: 'date-time'},
      {field: 'grandTotal', header: 'Giá trị', width: 170, matchMode: 'numeric', dataType: 'number'},
      {field: 'status', header: 'Trạng thái', minWidth: 250, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      if (partyId) {
        return Service.getPurchaseOrders(partyId, lazyLoadEvent);
      } else {
        return Promise.resolve({});
      }
    }
  });

  return (
    <div className="grid mt-2">
      <div className="col-12 py-0">
        {renderDataTable()}
      </div>
    </div>
  );

}