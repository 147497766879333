import {EntityService} from '../../../service/EntityService';

export class IncomingShipmentsService extends EntityService {

  static entity = 'iam-logistics/incoming-shipment';

  static getItems(workEffortId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${workEffortId}/items/list`).then(res => res.data);
  }

  static updateItems(workEffortId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${workEffortId}/items/update`, {listData: data}).then(res => res.data);
  }

  static receiveEntire(workEffortId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${workEffortId}/receive-entire`).then(res => res.data);
  }

  static cancel(workEffortId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${workEffortId}/cancel`).then(res => res.data);
  }

}