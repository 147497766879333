import {createRoot} from 'react-dom/client';
import {HashRouter, Route, Routes} from 'react-router-dom';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import {ConfirmPopup} from 'primereact/confirmpopup';
import {AppConfirmDialog, AppToast, DefaultConf, ScrollToTop} from '@iamsoftware/react-hooks';

import './assets/layout/layout.scss';
import '@iamsoftware/react-hooks/assets/style.scss';
import './App.scss';

import {AppLoading} from './AppLoading';

import App from './app';
import Login from './pages/login';

document.documentElement.style.fontSize = '14px';

DefaultConf.DataTable.IndexColumnHeader = '#';
DefaultConf.DataTable.ItemsField = 'listData';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HashRouter>
    <ScrollToTop>
      <ConfirmPopup/>
      <Routes>
        <Route path="login" element={<Login/>}/>
        <Route path="*" element={<App/>}/>
      </Routes>
      <AppLoading/>
      <AppToast/>
      <AppConfirmDialog/>
    </ScrollToTop>
  </HashRouter>
);