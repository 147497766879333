import {EntityService} from '../../../service/EntityService';

export class TemplatesService extends EntityService {

  static entity = 'iam-payment/template';

  static getList(lazyLoadEvent:string, disabled:string): Promise<any> {
    return this.axios.get(`${this.entity}/list?lazyLoadEvent=${lazyLoadEvent}&disabled=${disabled}`).then(res => res.data);
  }
  static getTemplateCode(printTypeEnumId:string): Promise<any> {
    return this.axios.get(`${this.entity}/code?printTypeEnumId=${printTypeEnumId}`).then(res => res.data);
  }
  static downloadAttachment(printTemplateId:string): Promise<any> {
    return this.axios.get(`${this.entity}/${printTemplateId}/download-attachment`).then(res => res.data);
  }
  static getTemplateType(): Promise<any> {
    return this.axios.get(`iam-common/template-types`).then(res => res.data);
  }
  static getParameters(printTypeEnumId:string): Promise<any> {
    return this.axios.get(`${this.entity}/parameters?printTypeEnumId=${printTypeEnumId}`).then(res => res.data);
  }
  static update(id: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${id}/update`, data).then(res => res.data);
  }


}