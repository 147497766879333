import {Fragment, useEffect, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {FormatDisplay, ToastService, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {FacilitiesService} from '../../../app/kho-van/kho-hang/FacilitiesService';

import {OrderShipmentItems} from './OrderShipmentItems';
import {EntityService} from '../../../service/EntityService';

type ShipmentType = 'incoming' | 'outgoing';

interface ShipmentLabel {
  header: string
  facility: string
}

interface Props {
  Service: EntityService
  type: ShipmentType

  display: any

  setDisplay(display: any): void

  reloadLazyData(): void
}

export const OrderShipments = (props: Props) => {

  const {Service, type, display, setDisplay, reloadLazyData} = props;

  const [label, setLabel] = useState<ShipmentLabel>(null);

  useEffect(() => {
    switch (type) {
      case 'incoming':
        setLabel({
          header: 'nhập',
          facility: 'nhận'
        });
        break;
      default:
        setLabel({
          header: 'xuất',
          facility: 'giao'
        });
    }
  }, [type]);

  const header = `Phiếu ${label?.header} của đơn hàng #${display?.displayId}`;

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '80rem'}} position="top" onHide={() => setDisplay(false)}>
      <Screen Service={Service} type={type} label={label} orderId={display?.orderId} reloadLazyData={reloadLazyData}/>
    </Dialog>
  );
}

const Screen = ({Service, type, label, orderId, reloadLazyData}) => {

  const dataKey = 'workEffortId';

  const [items, setItems] = useState([]);

  const [displayShipmentItems, setDisplayShipmentItems] = useState(null);

  useEffect(() => {
    loadItems(false);
  }, [orderId]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadItems = (reload?: boolean) => {
    if (orderId) {
      Service.getShipments(orderId).then(data => {
        setItems(data.listData);
      });
      reload !== false && reloadLazyData();
    }
  }

  const {render: renderDataTable, selectedItems} = useDataTableBasic({
    tableHeader: `${items?.length} Phiếu ${label.header}`,
    dataKey,
    columns: [
      {field: 'workEffortName', header: `Mã phiếu ${label.header}`, width: 150, matchMode: 'contains'},
      {field: 'facilityPseudoId', header: `Mã kho ${label.facility}`, width: 150, matchMode: 'contains'},
      {field: 'facilityName', header: `Tên kho ${label.facility}`, minWidth: 150, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'},
      {field: 'estimatedCompletionDate', header: 'Ngày dự kiến', width: 140, dataType: 'date'}
    ],
    indexColumnWidth: 45,
    items
  });

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const {renderDialogCrup, doCreate} = useCrup({Service, type, label, orderId, loadItems});

  const {renderToolbar} = useToolbar({
    doCreate,
    leftButtons: <Fragment>
      <Button label="Danh sách sản phẩm" icon="pi pi-box" severity="info" size="small" onClick={() => setDisplayShipmentItems(selectedItem)} disabled={!selectedItem}/>
    </Fragment>,
    buttonLabel: {
      create: `Thêm phiếu ${label.header}`
    }
  });

  return (
    <div className="grid mt-2">
      <div className="col-12">
        {renderToolbar()}
      </div>
      <div className="col-12 py-0">
        {renderDataTable()}
      </div>

      {renderDialogCrup()}

      <OrderShipmentItems type={type} label={label} display={displayShipmentItems} setDisplay={setDisplayShipmentItems}/>
    </div>
  );
}

const useCrup = ({Service, type, label, orderId, loadItems}) => {

  const header = `Phiếu ${label.header}`;
  const dataKey = 'dataKey';

  const [awaitingItems, setAwaitingItems] = useState([]);

  const [facilities, setFacilities] = useState([]);

  const loadAwaitingItems = () => {
    if (orderId) {
      return Service.getAwaitingItems(orderId).then(data => {
        data.listData.forEach(item => {
          item[dataKey] = `${item.orderId}_${item.orderPartSeqId}_${item.orderItemSeqId}`;
          item.vendorNameWithPseudoId = `[${item.vendorPseudoId}] - ${item.vendorName}`;
          item.productNameWithPseudoId = `[${item.pseudoId}] - ${item.itemDescription}`;
        })
        setAwaitingItems(data.listData);
        return data.listData;
      });
    } else {
      return Promise.resolve();
    }
  }

  const onEditorChange = (rowData, column, newValue) => {
    setAwaitingItems(prevState => prevState.map(item => {
      if (item[dataKey] === rowData[dataKey]) {
        return {
          ...item,
          [column.field]: newValue
        }
      }
      return item;
    }));
  }

  const {render: renderAwaitingItems} = useDataTableBasic({
    tableHeader: `${awaitingItems?.length} Sản phẩm`,
    dataKey,
    columns: [
      {field: 'vendorNameWithPseudoId', header: 'Nhà cung cấp', minWidth: 200, matchMode: 'contains', hidden: type === 'outgoing'},
      {field: 'productNameWithPseudoId', header: 'Sản phẩm', minWidth: 200, matchMode: 'contains'},
      {field: 'quantity', header: 'SL đặt hàng', width: 130, matchMode: 'equals', dataType: 'number'},
      {field: 'quantityShipped', header: `SL đã ${label.facility}`, width: 130, matchMode: 'equals', dataType: 'number'},
      {
        field: 'quantityNotShipped', header: `Còn lại`, width: 140, matchMode: 'equals', dataType: 'editor',
        editorConfig: {field: {type: 'InputNumber', InputNumberProps: {min: 0}}, onEditorChange}
      }
    ],
    indexColumnWidth: 45,
    items: awaitingItems
  });

  const searchFacilities = event => {
    const _event: any = {
      filters: {
        statusId: {value: 'FacActive', matchMode: 'equals'},
        facilityName: {value: event.query, matchMode: 'contains'}
      }
    };
    FacilitiesService.getList(JSON.stringify(_event)).then(data => {
      setFacilities(data.listData.map(item => {
        return {value: item.facilityId, label: `[${item.pseudoId}] - ${item.facilityName}`};
      }));
    });
  }

  const createRemainShipment = () => {
    if (form.valid()) {
      const item = form.getValue();
      item.facilityId = item.facility.value;
      delete item.facility;

      Service.createRemainShipment(orderId, item).then(() => {
        ToastService.success();
        close();
        loadItems();
      });
    }
  }

  const {render: renderDialogCrup, create, view, close, form} = useDialogCrup({
    header,
    width: '80rem',
    fields: [
      {
        field: 'facility', header: `Kho ${label.facility}`, required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: facilities, completeMethod: searchFacilities, field: 'label', dropdown: true, forceSelection: true},
        display: 'Create', className: 'md:col-6'
      },
      {field: 'estimatedCompletionDate', header: 'Ngày dự kiến', required: true, InputTextProps: {type: 'date'}, display: 'Create', className: 'md:col-3'},
      {type: 'custom', className: 'md:col-12 mt-1', body: renderAwaitingItems(), display: 'Create'},
      {
        type: 'custom', className: 'md:col-12 mt-1', body: <div className="p-message p-component p-message-info p-message-enter-done">
          <div className="p-message-wrapper">
            <span className="p-message-icon pi pi-check-square"></span>
            <span className="p-message-detail">Đã tạo phiếu {label.header} cho toàn bộ sản phẩm trong đơn hàng.</span>
          </div>
        </div>, display: 'View'
      }
    ],
    createItem: item => {
      item.facilityId = item.facility.value;
      delete item.facility;
      item.items = awaitingItems.filter(item => item.quantityNotShipped > 0).map(item => {
        return {
          orderItemSeqId: item.orderItemSeqId,
          quantityToShip: item.quantityNotShipped
        }
      });
      if (item.items?.length) {
        return Service.createShipment(orderId, item);
      } else {
        ToastService.error(`Chưa có sản phẩm cần ${label.header}`);
        return Promise.reject();
      }
    },
    reloadLazyData: loadItems,
    footerFragment: <Fragment>
      {awaitingItems?.length > 0 && <Button label="Nhập toàn bộ sản phẩm còn lại" icon="pi pi-check" className="mr-2" severity="success" size="small" onClick={createRemainShipment}/>}
    </Fragment>,
    useSaveSplitButton: false
  });

  const doCreate = () => {
    loadAwaitingItems().then(items => {
      if (items?.length) {
        create({estimatedCompletionDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')});
      } else {
        view({});
      }
    });
  }

  return {renderDialogCrup, doCreate};
}