import {useRef} from 'react';
import {Link} from 'react-router-dom';

import {Menu} from 'primereact/menu';
import {classNames} from 'primereact/utils';
import {useDialogCrup} from '@iamsoftware/react-hooks';

import {AuthService} from '../service/AuthService';
import {Households} from '../shared/components/layout/Households';

export const AppTopbar = ({me, mobileTopbarMenuActive, onToggleMenuClick, onMobileTopbarMenuClick, onMobileSubTopbarMenuClick}) => {

  const menu = useRef(null);

  const logout = () => {
    AuthService.logout().then(() => {
      window.location.hash = '/login';
    });
  }

  const {render: renderDialogChangePassword, create: changePassword} = useDialogCrup({
    header: '!Đổi mật khẩu',
    width: '40rem',
    fields: [
      {field: 'oldPassword', header: 'Mật khẩu cũ', required: true, type: 'Password', className: 'md:col-12'},
      {field: 'newPassword', header: 'Mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'},
      {field: 'newPasswordVerify', header: 'Nhập lại mật khẩu mới', required: true, type: 'Password', className: 'md:col-12'}
    ],
    createItem: item => {
      return AuthService.changePassword(item);
    }
  });

  const accountMenus = [
    {label: me.username, icon: 'pi pi-fw pi-user'},
    {label: 'Đổi mật khẩu', icon: 'pi pi-fw pi-key', command: changePassword},
    {separator: true},
    {label: 'Đăng xuất', icon: 'pi pi-fw pi-sign-out', command: logout}
  ];
  return (
    <div className="layout-topbar">
      <Link to="/dashboard" className="layout-topbar-logo">
        <img src="/iam/IAM-Logo-200.png" alt="IAM"/>
      </Link>

      <button type="button" className="p-link layout-menu-button layout-topbar-button" onClick={onToggleMenuClick}>
        <i className="pi pi-bars"/>
      </button>

      <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={onMobileTopbarMenuClick}>
        <i className="pi pi-ellipsis-v"/>
      </button>

      <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': mobileTopbarMenuActive})}>
        <li>
          <div className="house-holds">
            <p className="mb-0"><b>{me.partyName}</b></p>
            <p className="text-right">{me.username}</p>
          </div>
        </li>
        <li>
          <div className="layout-topbar-button">
          </div>
        </li>
        <li>
            <div className="house-holds">
              <Households/>
            </div>
        </li>
        <li>
          <button className="p-link layout-topbar-button" title="Cài đặt ứng dụng đồng bộ" onClick={onMobileSubTopbarMenuClick}>
            <i className="pi pi-cog" />
            <span>Cài đặt ứng dụng</span>
          </button>
        </li>
        <li>
          <button className="p-link layout-topbar-button" onClick={event => menu.current.toggle(event)}>
            <i className="pi pi-user"/>
            <span>Profile</span>
          </button>
          <Menu model={accountMenus} popup ref={menu}/>
        </li>
      </ul>
      {renderDialogChangePassword()}
    </div>
  );
}