import {Fragment, useEffect, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {ConfirmDialogService, FormMethod, ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

import {IncomingShipmentsService as Service} from './IncomingShipmentsService';

export const ShipmentItems = ({display, setDisplay, reloadLazyData, loadItem, setLoadItem, setPartItem, methodView, setMethodView}) => {

  const dataKey = 'workEffortId';

  const [header, setHeader] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [formMethod, setFormMethod] = useState<FormMethod>('View');

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (display?.workEffortName) {
      switch (formMethod) {
        case 'Update':
          setHeader(`Chỉnh sửa sản phẩm trong Phiếu nhập #${display.workEffortName}`);
          break;
        default:
          setHeader(`Sản phẩm trong Phiếu nhập #${display.workEffortName}`);
      }
    }
  }, [display?.workEffortName, formMethod]);

  useEffect(() => {
    setFormMethod('View');
    loadItems();
  }, [display?.workEffortId, loadItem]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPartItem(items.map(item => {
      return {
        orderId: item.orderId,
        orderItemSeqId: item.orderItemSeqId,
        shipmentId: item.shipmentId,
        quantity: item.quantity
      }
    }))
  }, [items])

  const loadItems = () => {
    if (display?.workEffortId) {
      Service.getItems(display.workEffortId).then(data => {
        data.listData.forEach(item => {
          item.productNameWithPseudoId = `[${item.pseudoId}] - ${item.itemDescription}`;
          item.vendorNameWithPseudoId = `[${item.fromPseudoId}] - ${item.fromPartyName}`
        });
        setItems(data.listData);
      });
    }
    setLoadItem(false)
  }

  const issue = () => {
    if (!submitting) {
      ConfirmDialogService.confirm('Xuất toàn bộ', `Xác nhận xuất toàn bộ Sản phẩm trong Phiếu xuất #${display?.workEffortName}?`, () => {
        setSubmitting(true);
        setDisplay(null);
        reloadLazyData();
        setSubmitting(false);
      });
    }
  }

  const updateItems = () => {
    if (!submitting && items?.length) {
      setSubmitting(true);
      setPartItem(items.map(item => {
        return {
          orderId: item.orderId,
          orderItemSeqId: item.orderItemSeqId,
          shipmentId: item.shipmentId,
          quantity: item.quantity
        }
      }))
      setMethodView(false)
      setFormMethod('View');
      setSubmitting(false);
    }
  }

  const backToView = () => {
    setFormMethod('View');
    loadItems();
  }

  const footer = (
    <div className="flex align-items-center justify-content-between ">
      <Fragment>
        <span>Sản phẩm</span>
        {/*{formMethod === 'View' && <div>*/}
        {/*  {display?.statusId === 'WeInProgress' && <Fragment>*/}
        {/*		<Button label="Xuất toàn bộ" icon="pi pi-check" size="small" className="mr-4 mb-1 w-10rem h-2rem" onClick={issue} loading={submitting}/>*/}
        {/*		<Button label="Chỉnh sửa" icon="pi pi-pencil" severity="warning" size="small" className="mr-4 mb-1 w-10rem h-2rem" onClick={() => setFormMethod('Update')}/>*/}
        {/*	</Fragment>}*/}
        {/*  /!*<Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" className="mr-4 mb-1 w-7rem h-2rem" onClick={() => setDisplay(false)}/>*!/*/}
        {/*</div>}*/}
        {/*{formMethod === 'Update' && <div>*/}
        {/*	<Button label="Lưu" icon="pi pi-check" size="small" className="mr-4 mb-1 w-10rem h-2rem" onClick={updateItems} loading={submitting}/>*/}
        {/*	<Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" className="mr-4 mb-1 w-10rem h-2rem" onClick={backToView}/>*/}
        {/*</div>}*/}
      </Fragment>
    </div>
  );
  return (
    <div className="grid">
      <Screen items={items} setItems={setItems} formMethod={formMethod} footer={footer} display={display}/>
    </div>
  );
}

const Screen = ({items, setItems, formMethod, footer, display}) => {

  const dataKey = 'orderItemSeqId';

  const fieldQuantity: any = {field: 'quantity', header: 'Số lượng', width: 130, matchMode: 'equals', dataType: 'number'};
  if (display?.statusId === 'WeInProgress') {
    fieldQuantity.dataType = 'editor';
    const onEditorChange = (rowData, column, newValue) => {
      setItems(prevState => prevState.map(item => {
        if (item[dataKey] === rowData[dataKey]) {
          return {
            ...item,
            [column.field]: newValue
          }
        }
        return item;
      }));
    }
    fieldQuantity.editorConfig = {field: {type: 'InputNumber', InputNumberProps: {min: 0}}, onEditorChange};
  }

  const {render: renderDataTable} = useDataTableBasic({
    tableHeader: footer,
    dataKey,
    columns: [
      {field: 'vendorNameWithPseudoId', header: 'Nhà cung cấp', minWidth: 335, matchMode: 'contains'},
      {field: 'productNameWithPseudoId', header: 'Sản phẩm', minWidth: 300, matchMode: 'contains'},
      {...fieldQuantity},
      {field: 'quantityAccepted', header: 'Đã nhập', width: 140, matchMode: 'equals', dataType: 'number'}
    ],
    indexColumnWidth: 45,
    items
  });


  return (
    <div className="grid mt-2">
      <div className="col-12 py-0">
        {renderDataTable()}
      </div>
    </div>
  )
}