import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {useDataTable, useForm, useToolbar} from '@iamsoftware/react-hooks';
import {FormatDisplay} from '@iamsoftware/react-hooks';
import moment from 'moment';
import {ReportPlanService as Service} from "../bao-cao-nhap-xuat/reportAssetChangeServices";
export default function BaoCaoNhapXuat() {
    const header ='Báo cáo xuất nhập tồn';

    const [items, setItems] = useState([]);

    const [facilities, setFacilities] = useState([]);

    useEffect(() => {
        Service.getFacilities().then(({listData}) => {
            setFacilities(listData.map(item => {
                renderForm.setValue(item)
                return {value: item.facilityId, label: item.facilityName};
            }));
        });
        reloadLazyData()
    }, []);

    const {render: renderDataTable, reloadLazyData} = useDataTable({
        tableHeader: header,
        columns: [
            {field: 'pseudoId', header: 'Mã sản phẩm', minWidth: 100, matchMode: 'contains'},
            {field: 'productName', header: 'Tên sản phẩm', minWidth: 200, matchMode: 'contains'},
            {field: 'localizedName', header: 'Tên nước ngoài', minWidth: 200, matchMode: 'contains'},
            {field: 'quantityIssued', header: 'Tổng xuất', minWidth: 50, matchMode: 'contains'},
            {field: 'quantityReceived', header: 'Tổng nhập', minWidth: 50, matchMode: 'contains'},

        ],
        indexColumnWidth: 45,
        getList: lazyLoadEvent => {
            return Service.getList(lazyLoadEvent, renderForm.getValue());
        },

    });
    const renderForm = useForm({
        fields: [
            {field: 'fromDate', header: 'Từ ngày',  InputTextProps: {type: 'date'}, className: 'md:col-3', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
            {field: 'thruDate', header: 'Đến ngày',  InputTextProps: {type: 'date'}, className: 'md:col-3', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
            {field: 'facilityId', header: 'Kho', type: 'Dropdown',DropdownProps: {options:facilities},className: 'md:col-4', labelClassName: 'md:col-2', inputClassName: 'md:col-10'},
            {
                type: 'custom', body: <Button icon="pi pi-search" className="p-button-rounded p-button-success" onClick={reloadLazyData}/>, className: 'md:col-1'
            }
        ],
        layout: 'Horizontal'

    });
    return (
        <div className="grid">
            <div className="col-10">
                {renderForm.render()}
            </div>
            <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 18rem)'}}>
                {renderDataTable()}
            </div>

        </div>
    )
}