import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ConfirmDialogService, ToastService, useDataTable, useToolbar} from '@iamsoftware/react-hooks';

import {PurchasesOrdersService as Service} from './PurchasesOrdersService';

import {useCrupPO} from './CrupPO';
import {ImportExcel} from '../../../shared/components/ImportExcel';

export const PurchasesOrders = () => {

  const header = 'Đơn hàng mua';
  const dataKey = 'orderId';
  const [headerResult, setHeaderResult] = useState(null);
  useEffect(() => {
    setHeaderResult(tabHeader)
  }, []);
  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    tableHeader: headerResult,
    dataKey,
    columns: [
      {
        field: 'displayId', header: 'Mã đơn hàng', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => Service.get(rowData.orderId).then(data => {
            doUpdatePO(rowData.orderId, data, false)
          })
          }>{rowData.displayId}</a>
        }
      }, {field: 'placedDate', header: 'Ngày lập', width: 120, matchMode: 'contains', dataType: 'date'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'},
      {field: 'vendorPseudoId', header: 'Mã NCC', width: 150, matchMode: 'contains'},
      {field: 'vendorName', header: 'Tên NCC', minWidth: 200, matchMode: 'contains'},
      {field: 'grandTotal', header: 'Tổng tiền', width: 140, matchMode: 'numeric', dataType: 'number'},
      {field: 'orderName', header: 'Ghi chú', minWidth: 200, matchMode: 'contains', dataType: 'html'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    actionColumnWidth: 120,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Xem', className: 'p-button-info', command: () => doUpdate(true, item)},
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(false, item)},
            {icon: 'pi pi-times', title: 'Hủy đơn hàng', className: 'p-button-danger', command: () => doCancel(item)},
          ]
        })

      }
    },
  });

  const {renderCrupPO, doCreatePO, doUpdatePO} = useCrupPO({reloadLazyData});

  const doUpdate = (doView: boolean, rowData: any) => {
    Service.get(rowData.orderId).then(data => {
      doUpdatePO(rowData.orderId, data, doView);
    });
  }

  const doCancel = (rowData) => {
    Service.cancel(rowData[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    });
  }


  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{header}</span>
          <div>
            <Button label={'Tạo mới'} icon="pi pi-plus" size={'small'} severity={'success'} style={{height:'25px'}} className={'mr-2'} onClick={()=>doCreatePO()}></Button>
            <ImportExcel column={{field: 'displayId', header: 'Số PO'}}
                         downloadTemplateUrl={`${Service.baseURL}/${Service.entity}/download-excel-template`} uploadExcel={contentFile => Service.uploadExcel(contentFile)}/>
          </div>
        </Fragment>
      </div>
    </div>
  )
  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 14rem)'}}>
        {renderDataTable()}
      </div>
      {renderCrupPO()}
    </div>
  )
}