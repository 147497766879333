import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ConfirmDialogService, ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';
import {CustomersService as Service} from './CustomersService';

export const useFacilities = ({readOnly}, partyId, displayContent) => {

  const dataKey = 'dataKey';

  const [customerFacs, setCustomerFacs] = useState([]);
  const [items, setItems] = useState([]);
  const [deleteFacs, setDeleteFacs] = useState([]);


  useEffect(() => {
    if (customerFacs?.length) {
      customerFacs.forEach((item, index) => {
        if (!item[dataKey]) {
          item[dataKey] = `${Date.now()}_${Math.random()}_${index}`;
        }
      });
    }
    // else {
    //   addBlankItem();
    // }
  }, [customerFacs?.length ,displayContent]); // eslint-disable-line react-hooks/exhaustive-deps

  const onEditorChange = (rowData, column, newValue) => {
    setCustomerFacs(prevFacilities => prevFacilities.map(prevFacility => {
      if (prevFacility[dataKey] === rowData[dataKey]) {
        return {
          ...prevFacility,
          [column.field]: newValue
        }
      }
      return prevFacility;
    }));
  }
  const addBlankItem = () => {
    setCustomerFacs(prevFacilities => {
      prevFacilities.push({});
      return [...prevFacilities];
    });
  }

  const header = (
    <div className="flex align-items-center justify-content-between ">
      <span>Xưởng nhận hàng</span>
      {!readOnly && <div>
				<Button label="Thêm xưởng" icon="pi pi-plus" className="mr-2 mb-1 w-12rem h-2rem" severity="success" size="small" onClick={addBlankItem}/>
			</div>}
    </div>
  );

  const {render: renderFacilities, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: header,
    dataKey,
    columns: [
      {field: 'pseudoId', header: 'Mã xưởng', width: 200, dataType: 'editor', editorConfig: {onEditorChange, readOnly}},
      {field: 'facilityName', header: 'Tên xưởng', minWidth: 300, dataType: 'editor', editorConfig: {onEditorChange, readOnly}},
    ],
    indexColumnWidth: 45,
    items: customerFacs,
    actionColumnWidth: 50,
    selectionMode: 'checkbox',
  });

  //them actions vao item neu readOnly = true, xoa di neu = false
  useEffect(()=>{
    setSelectedItems([])
    if (readOnly === false){
      customerFacs.forEach((customerFac) => {
        customerFac.actions = [
          {icon: 'pi pi-trash', title: 'Xóa', className: 'p-button-danger', command: () => deleteFacilities(customerFac)},
        ]
      });
    }else {
      customerFacs.forEach((customerFac) =>{
        delete customerFac.actions
      });
    }
  },[customerFacs, displayContent,customerFacs?.length])

  useEffect(() => {
    setItems(selectedItems);
  }, [selectedItems])

  const deleteFacilities = (rowData) => {
      ConfirmDialogService.confirm('Xóa xưởng', `Xác nhận xóa xưởng này ra khỏi danh sách?`, () => {
        setCustomerFacs(prevItems => {
          const result = []
          result.push(rowData)
          setDeleteFacs(prevItems.filter((prevItem => result.includes(prevItem))))
          return prevItems.filter((prevItem => !result.includes(prevItem)))
        })
      })
  };

  const getFacilities = () => {
    return customerFacs.filter(contactParty => contactParty.facilityName);
  }

  return {renderFacilities, getFacilities, setCustomerFacs, deleteFacs, setDeleteFacs};
}