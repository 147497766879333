import {EntityService} from '../../../service/EntityService';

export class ProductsToBuyService extends EntityService {
  static entity = 'iam-sale/orders/product-info';

  static getList(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
  }

  static getSynTime(): Promise<any> {
    return this.axios.get(`${this.entity}/last-sync-time`).then(res => res.data);
  }
  static doSynTime(): Promise<any> {
    return this.axios.put(`${this.entity}/last-sync-time`).then(res => res.data);
  }

  static getOrdering(productId: any): Promise<any> {
    return this.axios.get(`iam-product/products/process-purchase-orders?productId=${productId}`).then(res => res.data)
  }
  static getTrans(productId: any): Promise<any> {
    return this.axios.get(`iam-product/products/process-sale-orders?productId=${productId}`).then(res => res.data)
  }
}