import {Route, Routes} from 'react-router-dom';

import {Templates} from './bieu-mau';
import {Payments} from "./thanh-toan";


export default function PaymentFile() {

  return (
    <Routes>
      <Route path="bieu-mau" element={<Templates/>}/>
      <Route path="thanh-toan" element={<Payments/>}/>
    </Routes>
  );
}
