import {Route, Routes} from 'react-router-dom';
import BaoCaoMua from './bao-cao-mua';
import BaoCaoSl from './bao-cao-sl';
import BaoCaoNhapXuat from "./bao-cao-nhap-xuat";

export default function Reports() {

  return (
    <Routes>
      <Route path="bao-cao-mua" element={<BaoCaoMua/>}/>
      <Route path="bao-cao-sl" element={<BaoCaoSl/>}/>
        <Route path="bao-cao-nhap-xuat" element={<BaoCaoNhapXuat/>}/>
    </Routes>
  );
}
