import {Route, Routes} from 'react-router-dom';

import {Accounts} from './nguoi-dung';
import Category from './danh-muc';
import {Internal} from './to-chuc'

export default function System() {

  return (
    <Routes>
      <Route path="nguoi-dung" element={<Accounts/>}/>
      <Route path="danh-muc/*" element={<Category/>}/>
        <Route path="to-chuc" element={<Internal/>}/>
    </Routes>
  );
}
