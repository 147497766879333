import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {OutgoingShipmentsService as Service} from './OutgoingShipmentsService';
import {FacilitiesService} from '../kho-hang/FacilitiesService';

import {ShipmentItems} from './ShipmentItems';

export const OutgoingShipments = () => {

  const header = 'Xuất kho';
  const dataKey = 'workEffortId';

  const [facilities, setFacilities] = useState([]);
  const [methodView, setMethodView] = useState(false)
  const [displayShipmentItems, setDisplayShipmentItems] = useState(null);
  const [loadItem, setLoadItem] = useState(null);
  const [partItem, setPartItem] = useState([]);
  const [active, setActive] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [content, setContent] = useState(null);

  useEffect(() => {
    setContent(footer)
  }, [displayShipmentItems, partItem])

  const {render: renderDataTable, selectedItems, setSelectedItems, reloadLazyData} = useDataTable({
    tableHeader: header,
    dataKey,
    columns: [
      {
        field: 'workEffortName', header: 'Mã phiếu xuất', width: 200, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => Service.get(rowData.workEffortId).then(data => {
            if (data.estimatedStartDate) {
              data.estimatedStartDate = FormatDisplay.date(new Date(data.estimatedStartDate), 'YYYY-MM-DD');
            }
            if (data.facilityId) {
              data.facility = {value: data.facilityId, label: `[${data.facilityPseudoId}] - ${data.facilityName}`};
            }
            if (data.customerPseudoId) {
              data.customer = `[${data.customerPseudoId}] - ${data.customerName}`;
            }
            setDisplayShipmentItems(rowData)
            setSelectedItems(rowData)
            if (data.statusId === 'WeInProgress') {
              setActive(false)
            } else {
              setActive(true)
            }
            update(data);
          })}>{rowData.workEffortName}</a>
        }
      },
      {field: 'facilityPseudoId', header: 'Mã kho giao', width: 150, matchMode: 'contains'},
      {field: 'facilityName', header: 'Tên kho giao', minWidth: 150, matchMode: 'contains'},
      {field: 'customerPseudoId', header: 'Mã khách hàng', width: 150, matchMode: 'contains'},
      {field: 'customerName', header: 'Khách hàng', minWidth: 250, matchMode: 'contains'},
      {field: 'estimatedStartDate', header: 'Ngày dự kiến', width: 140, dataType: 'date'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'},
      {field: 'description', header: 'Ghi chú', minWidth: 150, matchMode: 'contains', dataType: 'html'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 80,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(item)},
            {icon: 'pi pi-times', title: 'Hủy', className: 'p-button-danger', command: () => doCancel(item)},
          ]
        })
      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    }
  });


  const searchFacilities = event => {
    const _event: any = {
      filters: {
        statusId: {value: 'FacActive', matchMode: 'equals'},
        facilityName: {value: event.query, matchMode: 'contains'}
      }
    };
    FacilitiesService.getList(JSON.stringify(_event)).then(data => {
      setFacilities(data.listData.map(item => {
        return {value: item.facilityId, label: `[${item.pseudoId}] - ${item.facilityName}`};
      }));
    });
  }


  const {render: renderDialogCrup, update, view} = useDialogCrup({
    header,
    dataKey,
    width: '70rem',
    fields: [
      {field: 'workEffortName', header: 'Mã phiếu xuất', required: true, className: 'md:col-3', disabled: active},
      {field: 'status', header: 'Trạng thái', className: 'md:col-2', disabled: true},
      {
        field: 'facility', header: 'Kho xuất', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: facilities, completeMethod: searchFacilities, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-4', disabled: active
      },
      {field: 'estimatedStartDate', header: 'Ngày dự kiến', required: true, InputTextProps: {type: 'date'}, className: 'md:col-3', disabled: active},
      {field: 'customer', header: 'Khách hàng', className: 'md:col-5', disabled: true},
      {field: 'description', header: 'Ghi chú', className: 'md:col-7'},
      {
        type: 'custom', body: <Fragment>
          <ShipmentItems display={displayShipmentItems} setDisplay={setDisplayShipmentItems} reloadLazyData={reloadLazyData} loadItem={loadItem}
                         setLoadItem={setLoadItem} setPartItem={setPartItem} methodView={methodView} setMethodView={setMethodView}/>
        </Fragment>
      }
    ],
    updateItem: (id, item) => {
      item.facilityId = item.facility.value;
      delete item.facility;
      return Service.update(id, item);
    },
    footerFragment: content,
    reloadLazyData
  });

  const issue = (rowData) => {
    if (!submitting) {
      ConfirmDialogService.confirm('Xuất toàn bộ', `Xác nhận xuất toàn bộ Sản phẩm trong Phiếu xuất #${rowData?.workEffortName}?`, () => {
        setSubmitting(true)
        Service.issue(rowData[dataKey]).then(() => {
          ToastService.success();
          setLoadItem(true)
          reloadLazyData();
        }).finally(() => {
          setSubmitting(false)
        })
      });
    }
  }

  const updateItems = (rowData) => {
    if (!submitting && partItem?.length) {
      ConfirmDialogService.confirm('Lưu và xuất toàn bộ', `Xác nhận lưu và xuất toàn bộ Sản phẩm trong Phiếu xuất #${rowData?.workEffortName}?`, () => {
        setSubmitting(true);
        Service.updateItems(rowData[dataKey], partItem).then(() => {
          setPartItem([]);
          ToastService.success();
          setLoadItem(true)
        }).finally(() => {
          Service.issue(rowData[dataKey])
          reloadLazyData();
          setSubmitting(false);
        })
      });
    }
  }

  const footer = (
    (displayShipmentItems?.statusId === 'WeInProgress') && <Fragment>
			<Button label="Xuất toàn bộ" icon="pi pi-check" severity="help" className="p-button-sm" loading={submitting} onClick={() => issue(displayShipmentItems)} disabled={displayShipmentItems?.statusId !== 'WeInProgress'}></Button>
			<Button label="Lưu và xuất" icon="pi pi-check" severity="success" className="p-button-sm" loading={submitting} onClick={() => updateItems(displayShipmentItems)} disabled={displayShipmentItems?.statusId !== 'WeInProgress'}></Button>
		</Fragment>
  )


  const doUpdate = (rowData) => {
    setDisplayShipmentItems(rowData)
    Service.get(rowData[dataKey]).then(data => {
      if (data.estimatedStartDate) {
        data.estimatedStartDate = FormatDisplay.date(new Date(data.estimatedStartDate), 'YYYY-MM-DD');
      }
      if (data.facilityId) {
        data.facility = {value: data.facilityId, label: `[${data.facilityPseudoId}] - ${data.facilityName}`};
      }
      if (data.customerPseudoId) {
        data.customer = `[${data.customerPseudoId}] - ${data.customerName}`;
      }
      if (data.statusId === 'WeInProgress') {
        setActive(false)
      } else {
        setActive(true)
      }
      update(data);
    });
  }


  const doCancel = (rowData) => {
    ConfirmDialogService.confirm('Huỷ phiếu xuất', `Xác nhận huỷ phiếu xuất #${rowData?.workEffortName}?`, () => {
      Service.cancel(rowData[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    });

  }

  return (
    <div>
      <div className="grid">
        <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 16.5rem)'}}>
          {renderDataTable()}
        </div>
        {renderDialogCrup()}
      </div>
    </div>
  )
}