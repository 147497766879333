import {EntityService} from '../../../service/EntityService';

export class SalesOrdersService extends EntityService {

  static entity = 'iam-sale/orders';

  static getAwaitingItems(orderId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${orderId}/not-shipped`).then(res => res.data);
  }
  static getPseudoId(): Promise<any> {
    return this.axios.get(`${this.entity}/shipment-pseudoId`).then(res => res.data);
  }

  static getShipments(orderId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${orderId}/shipments/list`).then(res => res.data);
  }

  static getShipmentsDetail(workEffortId: string): Promise<any> {
    return this.axios.get(`${this.entity}/shipments/${workEffortId}/detail`).then(res => res.data);
  }

  static updateShipmentsDetail(workEffortId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/shipments/${workEffortId}/update`, data).then(res => res.data);
  }
  static issueShipments(workEffortId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/shipments/${workEffortId}/issue`, data).then(res => res.data);
  }
  static cancelShipment(workEffortId: string): Promise<any> {
    return this.axios.put(`${this.entity}/shipments/${workEffortId}/cancel`).then(res => res.data);
  }

  static createRemainShipment(orderId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${orderId}/shipments/create-remain`, data).then(res => res.data);
  }

  static createShipment(orderId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${orderId}/shipments/create`, data).then(res => res.data);
  }

  static items(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/items`).then(res => res.data);
  }

  static cancel(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/cancel`).then(res => res.data);
  }

  static calender() {
    return this.axios.get(`${this.entity}/calender-so`).then(res => res.data);
  }
  static displayId() {
    return this.axios.get(`${this.entity}/displayId`).then(res => res.data);
  }

  static find(term: string): Promise<any> {
    return this.axios.get(`iam-product/products/find?term=${term}`).then(res => res.data);
  }
  static productInfo(data: any) {
    return this.axios.post(`${this.entity}/product-info`, data).then(res => res.data);
  }
}