import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {TabView, TabPanel} from 'primereact/tabview';
import {ConfirmDialogService, ToastService, useDataTable, useDataTableBasic, useToolbar} from '@iamsoftware/react-hooks';
import {CrupPGItems} from "./CrupPGItems";

import {OutgoingShipmentsService as Service} from './../xuat-kho/OutgoingShipmentsService';

export const usePG = ({workEffortId,method}) => {
  const dataKey = 'workEffortId';
  const [shipmentChild, setShipmentChild] = useState([]);
  const [customerId, setCustomerId] = useState([]);
  const [items, setItems] = useState([]);
  const [displayShipmentContents, setDisplayShipmentContents] = useState(null);
  const [shipment, setShipment] = useState([]);
  const [header, setHeader] = useState(null)
  const [displayDialog, setDisplayDialog] = useState(null)
  const [prodItems, setProdItems] = useState([])


  const {render: renderShipments, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: header,
    dataKey,
    columns: [
      {field: 'workEffortName', header: 'Mã phiếu xuất', minWidth: 150, matchMode: 'contains'},
      {field: 'facilityPseudoId', header: 'Mã kho giao', minWidth: 100, matchMode: 'contains'},
      {field: 'facilityName', header: 'Tên kho giao', minWidth: 200, matchMode: 'contains'},
      {field: 'amountTotal', header: 'Thành tiền', minWidth: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'estimatedStartDate', header: 'Ngày dự kiến', minWidth: 100, dataType: 'date'},
      {field: 'status', header: 'Trạng thái', minWidth: 150, matchMode: 'contains'},
      {field: 'description', header: 'Ghi chú', minWidth: 150, matchMode: 'contains', dataType: 'html'}
    ],
    indexColumnWidth: 45,
    items: shipmentChild,
    selectionMode: 'checkbox',
  });

  // const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  useEffect(() => {
    if(method === 'Create' && shipmentChild.length > 0){
      let x = shipmentChild.map(item=>{return item.workEffortId})

      Service.prodByList(JSON.stringify(x)).then(data=>{
        setProdItems(data.listData);
      })
    }
  }, [method,shipmentChild.length]);

  useEffect(() => {
    setItems([]);
    if (selectedItems) {
      setItems(prevItems => {
        prevItems.push({
          workEffortIds: selectedItems.map(item => {
            return item.workEffortId
          })
        })
        return prevItems
      })
    }
  }, [selectedItems])

  const doDelete = () => {
    if (selectedItems) {
      ConfirmDialogService.confirm('Xóa phiếu xuất', `Xác nhận xóa phiếu xuất ra khỏi danh sách?`, () => {
        setShipmentChild(prevItems => {
          const result = []
          selectedItems.forEach(item => {
            result.push(item)
          })
          return prevItems.filter((prevItem => !result.includes(prevItem)))
        });
      });
    }
    setSelectedItems([])
  }

  const tab = (
    <div className="flex align-items-center justify-content-between ">
      <Fragment>
        <span>Chi tiết phiếu xuất</span>
        <div>
          {(method === 'Update')&&<Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
																					size="small" onClick={() => setDisplayShipmentContents(Date.now())}/>}
          {(method !== 'View')&&<Button label="Xóa" icon="pi pi-times" severity="danger" className="mr-4 mb-1 w-7rem h-2rem"
																				size="small" onClick={doDelete} disabled={(selectedItems.length <= 0)}/>}
        </div>
        <CrupPGItems display={displayShipmentContents} setDisplay={setDisplayShipmentContents}
                     customerId={customerId} setShipmentChild={setShipmentChild} shipmentChild={shipmentChild}></CrupPGItems>
      </Fragment>
    </div>
  );
  useEffect(() => {
    setHeader(tab);
  }, [displayShipmentContents, selectedItems, method])

  useEffect(() => {
    setSelectedItems([])
  }, [displayDialog])


  useEffect(() => {
    if (workEffortId && shipmentChild.length !== 0 && method !== "Create") {
      Service.totalDetailSyn(workEffortId).then(item => {
        setProdItems(item.listData)
      })
    }else {
      setProdItems([])
    }
  }, [workEffortId, method])


  const tableProd = useDataTableBasic({
    tableHeader: 'Chi tiết sản phẩm',
    dataKey: 'pseudoId',
    columns: [
      {field: 'pseudoId', header: 'Mã sản phẩm', width: 100, matchMode: 'contains', className: 'md:col-12'},
      {field: 'itemDescription', header: 'Tên sản phẩm', minWidth: 100, matchMode: 'contains', className: 'md:col-12'},
      {field: 'quantity', header: 'Số lượng', minWidth: 100, matchMode: 'contains', className: 'md:col-12', dataType: 'number'},
      {field: 'quantityUom', header: 'Đơn vị tính', minWidth: 100, matchMode: 'contains', className: 'md:col-12',},
      {field: 'grandTotal', header: 'Thành tiền', minWidth: 100, matchMode: 'contains', className: 'md:col-12', dataType: 'number'}],
    indexColumnWidth: 45,
    items: prodItems,
  })

  const renderShipment = () => {
    return (
      <div>
        <TabView className="p-tabview-sm">
          <TabPanel header="Phiếu xuất">
            <div className="grid-nogutter" >
              {renderShipments()}
            </div>
          </TabPanel>
          <TabPanel header="Sản phẩm">
            <div className="grid-nogutter">
              {tableProd.render()}
            </div>
          </TabPanel>
        </TabView>
      </div>
    )
  }

  return {renderShipment, setShipmentChild, shipmentChild, setCustomerId, setShipment, setDisplayDialog ,setProdItems};
}