import {EntityService} from '../../../service/EntityService';

export class ReportPlanService extends EntityService {
    static  entity = 'iam-logistics/assets/change-history';
    static getList(lazyLoadEvent: string, params?: any): Promise<any> {
        return this.axios.post(`${this.entity}/?lazyLoadEvent=${lazyLoadEvent}`, params).then(res => res.data);

    }
    static getFacilities(): Promise<any> {
        return this.axios.get(`iam-logistics/facilities/list`).then(res => res.data);

    }
}