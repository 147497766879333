import {EntityService} from '../../service/EntityService';

export class DashboardService extends EntityService {
  static entity = '/iam-common/dashboard';

  static getSaleTotal(fromDate: any, thruDate: any): Promise<any> {
    return this.axios.get(`${this.entity}/sale-total?fromDate=${fromDate}&thruDate=${thruDate}`).then(res => res.data);
  }

  static getPurchaseTotal(fromDate: any, thruDate: any): Promise<any> {
    return this.axios.get(`${this.entity}/purchase-total?fromDate=${fromDate}&thruDate=${thruDate}`).then(res => res.data);
  }

  static getNewCustomer(fromDate: any, thruDate: any): Promise<any> {
    return this.axios.get(`${this.entity}/new-cus?fromDate=${fromDate}&thruDate=${thruDate}`).then(res => res.data);
  }

  static getSaleAmountPeriod(lastNMonth: any): Promise<any> {
    return this.axios.get(`${this.entity}/sale-amount-period?lastNMonth=${lastNMonth}`).then(res => res.data);
  }
  static getPurchaseAmountPeriod(lastNMonth: any): Promise<any> {
    return this.axios.get(`${this.entity}/purchase-amount-period?lastNMonth=${lastNMonth}`).then(res => res.data);
  }
  static getPaymentDueIncome(): Promise<any> {
    return this.axios.get(`${this.entity}/payment-due-income`).then(res => res.data);
  }
}