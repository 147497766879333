import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {TabView, TabPanel} from 'primereact/tabview';
import moment from 'moment';
import {AutoComplete} from "primereact/autocomplete";


import {FormatDisplay, ToastService, useDataTableBasic, useDialogCrup, useFileUploader, useForm, useToolbar} from '@iamsoftware/react-hooks';

import {PaymentService as Service} from './PaymentServices';
import {UserStorage} from '../../../service/UserStorage';
import {QuantityUomsService} from '../../quan-ly/danh-muc/don-vi-san-pham/QuantityUomsService';
import {TemplatesService} from '../bieu-mau/TemplatesService';

export const useCrupPayment = ({reloadLazyData}) => {

  const [display, setDisplay] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [workEffortList, setWorkEffortList] = useState(null)
  const [bankList, setBankList] = useState(null)
  const [workEffortDetail, setWorkEffortDetail] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantityUoms, setQuantityUoms] = useState([]);
  const [uomEnumId, setUomEnumId] = useState('');
  const [templateList, setTemplateList] = useState(null)
  const [toPaymentMethod, setToPaymentMethod] = useState('');
  const [toPaymentMethodId, setToPaymentMethodId] = useState(null);
  const addBankTooltip = 'Thêm ngân hàng';
  const updateTooltip = 'Cập nhật sản phẩm';

  const searchQuantityUoms = event => {
    const _event: any = {
      filters: {
        disabled: {value: 'N', matchMode: 'equals'},
        description: {value: event.query, matchMode: 'contains'}
      }
    };
    QuantityUomsService.getList(JSON.stringify(_event)).then(data => {
      setQuantityUoms(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.uomId, label: item.description};
      }));
    });
  }

  const ownerPartyId = UserStorage.get('household');
  const dataKey = 'productId'


  const searchBankList = event => {
    Service.getOrgBankList(ownerPartyId).then(data => {
      setBankList(data.listData.map(item => {
        return {value: item.paymentMethodId, label: `[${item.accountNumber}] - ${item.bankName}`};
      }));
    });
  }
  const searchWorkEffortList = event => {
    Service.getWorkEffortList().then(data => {
      setWorkEffortList(data.listData.map(item => {
        return {value: item.workEffortId, label: item.workEffortName};
      }));
    });
  }

  const {render: renderDialogCreateBank, create: createBank} = useDialogCrup({
    header: '!Thêm mới tài khoản ngân hàng',
    submitLabel: 'Xác nhận',
    width: '40rem',
    fields: [
      {field: 'accountNumber', header: 'Số tài khoản', required: true, className: 'md:col-12'},
      {field: 'bankName', header: 'Tên ngân hàng', required: true, className: 'md:col-12'}
    ],
    createItem: item => {
      item.partyId = ownerPartyId
      return Service.createOrgBankList(item);
    },
    checkResponseError: data => {
      let error = '';
      if (data?.isSuccess === 'N') {
        error = data.messages;
      }
      return error;
    }
  });

  const itemTemplate = (item) => {
    return (
      <div className="flex align-items-center">
        <div>{item.label}</div>
      </div>
    );
  };
  const onFileSelect = async file => {
    if (file.key) {
      window.open(file.src);
    } else {
      const src = window.URL.createObjectURL(new Blob([file], {type: file.type}));
      window.open(src)
    }
  }

  const {renderFileUploader: renderContents, getFiles: getContents, deletedExistingFiles: deletedContents, setFileUploaderDisabled} = useFileUploader({
    onSelect: onFileSelect,
  });

  const form = useForm({
    fields: [
      {
        field: 'workEffort', header: 'Phiếu gộp', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: workEffortList, completeMethod: searchWorkEffortList, field: 'label', dropdown: true},
        className: 'md:col-5', required: true
      },
      {field: 'agreementDate', header: 'Ngày hợp đồng', required: true, className: 'md:col-3', InputTextProps: {type: 'date'}},
      {field: 'agreementNo', header: 'Số hợp đồng', required: true, className: 'md:col-4'},
      {field: 'customer', header: 'Khách hàng', disabled: true, className: 'md:col-5'},
      {field: 'invoiceNo', header: 'Số hóa đơn', required: true, className: 'md:col-3'},
      {field: 'invoiceSerial', header: 'Ký hiệu hóa đơn', required: true, className: 'md:col-4'},
      {field: 'referenceNumber', header: 'Mã đơn hàng', required: true, className: 'md:col-2'},

      {field: 'invoiceTotal', header: 'Tổng tiền', required: true, className: 'md:col-3', type: 'InputNumber'},
      {field: 'invoiceTotalInWords', header: 'Bằng chữ', required: true, className: 'md:col-3'},
      /*{
        field: 'toPaymentMethod', header: 'Tài khoản nhận', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: bankList, completeMethod: searchBankList, field: 'label', dropdown: true},
        className: 'md:col-2', required: true
      },*/
      {
        field: 'toPaymentMethod', header: 'Tài khoản nhận', type: 'custom', className: 'md:col-4',
        body:
          <div>
            <div>
              Tài khoản nhận
            </div>
            <div className='p-inputgroup mt-2'>
              <AutoComplete value={toPaymentMethod} suggestions={bankList} completeMethod={searchBankList} itemTemplate={itemTemplate} onChange={(e) => {
                setToPaymentMethod(e.value.label);
                setToPaymentMethodId(e.value.value)
              }} dropdown/>
              <Button icon="pi pi-plus" style={{height: '30px'}} severity="success" tooltip={addBankTooltip} onClick={() => createBank()}/>
            </div>
          </div>

      },]
  });
  const _value = form.getValue();
  const attachmentForm = useForm({
    fields: [{
      field: 'contentFile', header: 'File đính kèm', type: 'FileUploader', FileUploaderProps: {render: renderContents}, className: 'md:col-12'
    },]
  })

  const {render: renderEffortDetail, selectedItems} = useDataTableBasic({
    tableHeader: `${workEffortDetail?.length} Sản phẩm`,
    indexColumnWidth: 40,
    columns: [
      {field: 'productNameWithlocalizedName', header: 'Tên sản phẩm', className: 'md:col-6'},
      {field: 'quantity', header: 'SL', width: 80, matchMode: 'equals', dataType: 'number'},
      {field: 'unitAmount', header: 'Đơn giá', width: 130, matchMode: 'equals', dataType: 'number'},
      {field: 'quantityUom', header: 'ĐVT', width: 100, matchMode: 'contains'},
      {field: 'itemTotal', header: 'Thành tiền', width: 140, matchMode: 'equals', dataType: 'number'},
      {
        field: 'productNameWithPseudoId', header: 'Action', width: 110, dataType: 'custom', customCell(rowData: any): any {
          return <div>
            <Button icon="pi pi-pencil" className='mr-1' size='small' severity="warning" tooltip={updateTooltip}
                    onClick={() => doUpdateProduct(rowData)}></Button>
          </div>
        }
      },
    ],
    items: workEffortDetail
  });

  useEffect(() => {
    const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;
    setSelectedItem(selectedItem);
  }, [selectedItems]);

  const {render: renderTemplateDetail, selectedItems: selectedTemplates} = useDataTableBasic({
    dataKey: 'printTemplateId',
    indexColumnWidth: 40,
    columns: [
      {
        field: 'templateName', header: 'Tên biểu mẫu', minWidth: 600, matchMode: 'contains'
      },
      {field: 'printTypeEnum', header: 'Loại biểu mẫu', minWidth: 200, matchMode: 'contains'},
      {field: 'description', header: 'Ghi chú', minWidth: 150, matchMode: 'contains'},
    ],
    items: templateList,
    selectionMode: 'checkbox',

  });

  const getTemplateList = () => {
    TemplatesService.getList('{"first":0,"rows":20,"page":0,"sortField":null,"sortOrder":null,"filters":{}}', 'N').then(data => {
      setTemplateList(data.listData)
    })
  }

  const {render: renderDialogCrup, update, form: itemForm} = useDialogCrup({
    header: '!Sản phẩm',
    dataKey,
    width: '60rem',
    fields: [
      {field: 'productNameWithlocalizedName', header: 'Tên sản phẩm', disabled: true, className: 'md:col-12'},
      {
        field: 'qtyUom', header: 'Đơn vị tính', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: quantityUoms, completeMethod: searchQuantityUoms, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-12'
      },
      {field: 'quantity', header: 'SL', required: true, className: 'md:col-6'},
      {field: 'unitAmount', header: 'Đơn giá', required: true, className: 'md:col-6', type: 'InputNumber'},

      {field: 'itemTotal', header: 'Thành tiền', className: 'md:col-12', disabled: true, type: 'InputNumber'},
    ],
    updateItem: (id, item) => {
      setWorkEffortDetail(prevState => {
        for (const part_item of prevState) {
          if (part_item[dataKey] === id) {
            part_item.unitAmount = item.unitAmount;
            part_item.quantity = item.quantity;
            part_item.quantityUomId = item.qtyUom?.value;
            part_item.quantityUom = item.qtyUom?.label;
            part_item.operatorPartyId = item.operatorParty?.value;
            part_item.operatorPseudoId = item.operatorParty?.pseudoId;
            part_item.operatorName = item.operatorParty?.partyName;
            part_item.itemTotal = item.unitAmount > 0 && item.quantity > 0 ? item.unitAmount * item.quantity : 0;
            break;
          }
          break;
        }
        return [...prevState];
      });
      return Promise.resolve(item);
    },
    hideToastMessage: true
  });

  const product = itemForm.getValue();
  useEffect(() => {
    const _value = itemForm.getRawValue();
    _value.unitAmount = product.unitAmount;
    _value.qtyUom = product.qtyUom;
    _value.itemTotal = _value.unitAmount > 0 && _value.quantity > 0 ? _value.unitAmount * _value.quantity : 0;
    itemForm.setValue(_value);
  }, [product?.quantity, product?.unitAmount]);


  useEffect(() => {
    const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;
    setSelectedItem(selectedItem)
  }, [selectedItems]); //

  useEffect(() => {
    if (_value?.workEffort?.value) {
      Service.getWorkEffortInfo(_value.workEffort?.value).then(data => {
        setUomEnumId(data.currencyUomId)
        data.workEffort = _value.workEffort
        if (data.items?.length) {
          data.items.forEach((item, index) => {
            if (!item[dataKey]) {
              item[dataKey] = `${Date.now()}_${Math.random()}_${index}`;
            }
            if (!item.productNameWithlocalizedName) {
              item.productNameWithlocalizedName = `[${item.productName}] `;
              if (item.localizedName) {
                item.productNameWithlocalizedName += `- ${item.localizedName}`;
              }
            }
          });
        }
        setWorkEffortDetail(data.items)
        data.agreementDate = FormatDisplay.date(moment().endOf('day').toDate(), 'YYYY-MM-DD')
        data.customer = `[${data.customerPseudoId}] - ${data.customerName}`
        form.setValue(data)
      })
    }
  }, [_value?.workEffort]);

  const doPlan = () => {
    form.reset();
    setDisplay(Date.now());
    setWorkEffortDetail('')
    getTemplateList()
  }
  const onSubmit = async event => {
    if (form.valid()) {
      const input = form.getValue()

      input.toPaymentMethodId = toPaymentMethodId
      input.workEffortId = input.workEffort.value
      input.items = JSON.stringify(workEffortDetail)
      input.contentFiles = await getContents(false);
      if (input.contentFiles.length === 1) {
        input.contentFile = input.contentFiles[0];
        delete input.contentFiles;
      }

      if (selectedTemplates?.length) {
        let tempIds = {
          printTemplates: selectedTemplates.map(item => {
            return item?.printTemplateId
          })
        }
        input.printTemplateIds = JSON.stringify(tempIds.printTemplates)
      }
      Service.createPayment(Service.toFormData(input)).then(() => {
        ToastService.success();
        reloadLazyData();
        setDisplay(null)
      });
      event.preventDefault();
    }
  }

  const doUpdateProduct = (selectedItem) => {
    selectedItem.product = {value: selectedItem.productId, label: `[${selectedItem.pseudoId}] - ${selectedItem.productName}`};
    selectedItem.qtyUom = {value: selectedItem.quantityUomId, label: selectedItem.quantityUom};
    selectedItem.operatorParty = {
      pseudoId: selectedItem.operatorPseudoId,
      partyName: selectedItem.operatorName,
      value: selectedItem.operatorPartyId,
      label: `[${selectedItem.operatorPseudoId}] - ${selectedItem.operatorName}`
    };
    update(selectedItem);
  }

  const footer = (
    <div className="flex justify-content-end">
      {!disabled && <Button label="Tạo mới" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  const render = () => {
    return (
      <Fragment>
        <Dialog header="Thông tin thanh toán" footer={footer} visible={!!display} style={{width: '80rem'}} draggable={false} onHide={() => setDisplay(null)}>
          <div className="grid pt-2">
            <div className="col-12">
              {form.render()}
              <TabView className="p-tabview-sm">
                <TabPanel header="Sản phẩm">
                  <div className="md:col-12">
                    {renderEffortDetail()}
                  </div>
                </TabPanel>
                <TabPanel header="Biểu mẫu">
                  <div className="md:col-12">
                    {renderTemplateDetail()}
                  </div>
                </TabPanel>
                <TabPanel header="File đính kèm">
                  <div className='md:col-12'>
                    {attachmentForm.render()}
                  </div>
                </TabPanel>
              </TabView>
            </div>
            {renderDialogCreateBank()}
            {renderDialogCrup()}
          </div>
        </Dialog>
      </Fragment>
    );
  }

  return {renderCrupPayment: render, doPlan};
}