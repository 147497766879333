import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {AutoComplete} from 'primereact/autocomplete';

import {ConfirmDialogService, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {QuantityUomsService} from '../../quan-ly/danh-muc/don-vi-san-pham/QuantityUomsService';
import {AccountsService} from '../../quan-ly/nguoi-dung/AccountsService';
import {SalesOrdersService} from "./SalesOrdersService";

export const useCrupSOItems = ({display, disabled, setDisplayProductContents, reloadLazyData}) => {

  const dataKey = 'dataKey';

  const [items, setItems] = useState([]);

  const [products, setProducts] = useState([]);
  const [productName, setProductName] = useState(null);
  const [quantityUoms, setQuantityUoms] = useState([]);
  const [operatorParties, setOperatorParties] = useState([]);
  const [deleteOrderItemSeqIds] = useState([])
  const [headerResult, setHeaderResult] = useState(null);
  useEffect(() => {
    if (display) {
      setSelectedItems(null);
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (display && items?.length) {
      items.forEach((item, index) => {
        if (!item[dataKey]) {
          item[dataKey] = `${Date.now()}_${Math.random()}_${index}`;
        }
        if (!item.productNameWithPseudoId) {
          item.productNameWithPseudoId = `[${item.pseudoId}] - ${item.productName}`;
          if (item.localizedName) {
            item.productNameWithPseudoId += ` | ${item.localizedName}`;
          }
        }
        item.actions = [
          {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(item)},
          {icon: 'pi pi-trash', title: 'Xóa', className: 'p-button-danger', command: () => doDelete(item)},
        ]
      });
    }
    setHeaderResult(tabHeader)
  }, [display, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchProducts = event => {
    SalesOrdersService.find(event.query).then(data => {
      setProducts(data.listData.map(item => {
        let index = 1
        let label = `[${item.pseudoId}] - ${item.productName}`;
        if (item.localizedName) {
          label += ` | ${item.localizedName}`;
          index++
        }
        return {...item, value: item.productId, label: label};
      }));
    });
    // products.map((product,index)=>{
    //   if (product.statusId === "ProdInactive") {
    //     products.splice(index)
    //   }
    // })
  }

  const searchQuantityUoms = event => {
    const _event: any = {
      filters: {
        disabled: {value: 'N', matchMode: 'equals'},
        description: {value: event.query, matchMode: 'contains'}
      }
    };
    QuantityUomsService.getList(JSON.stringify(_event)).then(data => {
      setQuantityUoms(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.uomId, label: item.description};
      }));
    });
  }
  const searchOperatorParties = event => {
    const _event: any = {
      filters: {
        disabled: {value: 'N', matchMode: 'equals'},
        partyName: {value: event.query, matchMode: 'contains'}
      }
    };
    AccountsService.getList(JSON.stringify(_event)).then(data => {
      setOperatorParties(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`};
      }));
    });
  }

  const {render: renderDialogCrup, create, update, form} = useDialogCrup({
    header: '!Sản phẩm',
    dataKey,
    width: '50rem',
    fields: [

      {
        field: 'product', header: 'Tên sản phẩm', type: 'custom', className: 'md:col-11 mt-4 ',
        body:
          <div className={'p-inputgroup'}>
            <AutoComplete value={productName} suggestions={products} completeMethod={searchProducts} onChange={e => setProductName(e.value)} field="label" dropdown={true} placeholder="Tên sản phẩm"/>
            {/*  <span className="p-inputgroup-addon" style={{background: '#22C55E', color: 'white', cursor: 'pointer'}} onClick={() => createProduct()}>*/}
            {/*  <i className="pi pi-plus"/>*/}
            {/*</span>*/}
          </div>
      },
      {
        field: 'image', type: 'custom', body: <Button icon="pi pi-image" rounded style={{width: '33px', height: '33px'}} severity="success" onClick={() => setDisplayProductContents(productName)}/>, displayDependency(item: any): boolean {
          return !!productName;
        }, className: 'md:col-1 pl-0 pt-4'
      },
      {field: 'unitListPrice', header: 'Đơn giá', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'quantity', header: 'Số lượng', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {
        field: 'qtyUom', header: 'Đơn vị tính', required: true, type: 'AutoComplete',
        AutoCompleteProps: {field: 'label', suggestions: quantityUoms, completeMethod: searchQuantityUoms, dropdown: true, forceSelection: true},
        className: 'md:col-4'
      },
      {field: 'discountPercentage', header: 'Tỷ lệ CK', type: 'InputNumber', InputNumberProps: {min: 0, maxFractionDigits: 2, suffix: '%'}, className: 'md:col-4'},
      {field: 'discountAmount', header: 'Số tiền CK', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'sourcePercentage', header: 'Thuế suất', type: 'InputNumber', InputNumberProps: {min: 0, max: 100, maxFractionDigits: 2, suffix: '%'}, className: 'md:col-4'},
      {field: 'amountAlreadyIncluded', header: 'Tiền thuế', type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'amountExcludeTax', header: 'Thành tiền trước thuế', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {field: 'amountTotal', header: 'Thành tiền sau thuế', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-4'},
      {
        field: 'operatorParty', header: 'Nhân viên phụ trách', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: operatorParties, completeMethod: searchOperatorParties, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-6'
      },
      {field: 'customerContactName', header: 'Người liên hệ', className: 'md:col-6'},
      {field: 'comments', header: 'Ghi chú', type: 'Editor', className: 'md:col-12'}
    ],
    createItem: item => {
      setItems(prevItems => {
        prevItems.push({
          dataKey: `${Date.now()}_${Math.random()}`,
          productId: productName.value,
          pseudoId: productName.pseudoId,
          productName: productName.productName,
          itemDescription: productName.productName,
          unitListPrice: item.unitListPrice,
          quantity: item.quantity,
          quantityUomId: item.qtyUom?.value,
          quantityUom: item.qtyUom?.label,
          discountPercentage: item.discountPercentage,
          discountAmount: item.discountAmount,
          sourcePercentage: item.sourcePercentage,
          amountAlreadyIncluded: item.amountAlreadyIncluded,
          amountExcludeTax: item.amountExcludeTax,
          amountTotal: item.amountTotal,
          operatorPartyId: item.operatorParty?.value,
          operatorPseudoId: item.operatorParty?.pseudoId,
          operatorName: item.operatorParty?.partyName,
          customerContactName: item.customerContactName,
          comments: item.comments
        });
        return prevItems;
      });
      setProductName(null)
      return Promise.resolve(item);
    },
    updateItem: (id, item) => {
      setItems(prevItems => {
        for (const prevItem of prevItems) {
          if (prevItem[dataKey] === id) {
            prevItem.unitListPrice = item.unitListPrice;
            prevItem.quantity = item.quantity;
            prevItem.quantityUomId = item.qtyUom?.value;
            prevItem.quantityUom = item.qtyUom?.label;
            prevItem.discountPercentage = item.discountPercentage;
            prevItem.discountAmount = item.discountAmount;
            prevItem.sourcePercentage = item.sourcePercentage;
            prevItem.amountAlreadyIncluded = item.amountAlreadyIncluded;
            prevItem.amountExcludeTax = item.amountExcludeTax;
            prevItem.amountTotal = item.amountTotal;
            prevItem.operatorPartyId = item.operatorParty?.value;
            prevItem.operatorPseudoId = item.operatorParty?.pseudoId;
            prevItem.operatorName = item.operatorParty?.partyName;
            prevItem.customerContactName = item.customerContactName;
            prevItem.comments = item.comments;
            break;
          }
        }
        return prevItems;
      });
      return Promise.resolve(item);
    },
    hideToastMessage: true
  });

  const product = form.getRawValue();
  useEffect(() => {
    if (productName) {
      const _value = form.getRawValue();
      _value.unitListPrice = productName.salePrice;
      _value.qtyUom = {value: productName.amountUomId, label: productName.uomName};
      form.setValue(_value);
    }
  }, [productName]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const _value = form.getRawValue();
    if (product?.quantity && product?.unitListPrice) {
      if (product?.discountPercentage) {
        _value.discountAmount = product?.quantity * product.unitListPrice * product.discountPercentage / 100
      }
      _value.amountExcludeTax = product?.quantity * product.unitListPrice - _value?.discountAmount
      if (product?.sourcePercentage) {
        _value.amountAlreadyIncluded = _value.amountExcludeTax * product.sourcePercentage / 100

      }
      _value.amountTotal = _value?.amountExcludeTax + _value?.amountAlreadyIncluded
    }
    form.setValue(_value)
  }, [product?.quantity, product?.unitListPrice, product?.discountPercentage, product?.sourcePercentage]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: headerResult,
    dataKey,
    indexColumnWidth: 40,
    columns: [

      {
        field: 'productNameWithPseudoId', header: 'Sản phẩm', minWidth: 400, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <div>{rowData.productNameWithPseudoId} <i className="pi pi-image ml-2" onClick={() => setDisplayProductContents(rowData)}></i></div>
        }
      },
      {field: 'quantityUom', header: 'ĐVT', width: 70, matchMode: 'contains'},
      {field: 'unitListPrice', header: 'Đơn giá', width: 100, matchMode: 'equals', dataType: 'number'},
      {field: 'quantity', header: 'SL', width: 90, matchMode: 'equals', dataType: 'number'},
      {field: 'discountPercentage', header: 'Tỷ lệ CK', width: 90, matchMode: 'equals', dataType: 'number'},
      {field: 'discountAmount', header: 'Số tiền CK', width: 90, matchMode: 'equals', dataType: 'number'},
      {field: 'amountExcludeTax', header: 'Thành tiền', width: 100, matchMode: 'equals', dataType: 'number'},
      {field: 'sourcePercentage', header: 'Thuế suất', width: 100, matchMode: 'equals', dataType: 'number'},
      {field: 'amountAlreadyIncluded', header: 'Tiền thuế', width: 100, matchMode: 'equals', dataType: 'number'},
      {field: 'amountTotal', header: 'Tổng tiền', width: 100, matchMode: 'equals', dataType: 'number'},
      {field: 'operatorName', header: 'NV phụ trách', minWidth: 200, matchMode: 'contains'},
      {field: 'customerContactName', header: 'Người liên hệ', minWidth: 200, matchMode: 'contains'},
    ],
    items,
    actionColumnWidth: 80,
  });


  const doCreate = () => {
    create();
  }

  const doUpdate = (rowData) => {
    setProductName({
      productId: rowData.productId,
      pseudoId: rowData.pseudoId,
      productName: rowData.productName,
      value: rowData.productId,
      label: `[${rowData.pseudoId}] - ${rowData.productName}`
    })
    rowData.qtyUom = {value: rowData.quantityUomId, label: rowData.quantityUom};
    rowData.operatorParty = {
      pseudoId: rowData.operatorPseudoId,
      partyName: rowData.operatorName,
      value: rowData.operatorPartyId,
      label: `[${rowData.operatorPseudoId}] - ${rowData.operatorName}`
    };
    update(rowData);
  }

  const doDelete = (rowData) => {
    ConfirmDialogService.confirm('Xóa sản phẩm', `Xác nhận xóa sản phẩm [${rowData.pseudoId}] - ${rowData.productName} ra khỏi danh sách?`, () => {
      setItems(prevItems => {
        deleteOrderItemSeqIds.push(rowData.orderItemSeqId)
        return prevItems.filter(prevItem => prevItem[dataKey] !== rowData[dataKey]);
      });
    });
  }

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{items?.length} Sản phẩm</span>
          {!disabled && <div>
						<Button label={'Tạo mới'} icon="pi pi-plus" style={{height: '25px'}} size={'small'} severity={'success'} className={'mr-2'} onClick={() => create()}></Button>
					</div>}
        </Fragment>
      </div>
    </div>
  )
  const renderProducts = () => {
    return (
      <div className="grid">
        <div className="col-12 py-0" style={{minHeight: '15.5rem', maxHeight: 'calc(100vh - 35.4rem)'}}>
          {render()}
        </div>
        {renderDialogCrup()}
      </div>
    );
  }

  return {renderProducts, items, setItems, deleteOrderItemSeqIds};
}