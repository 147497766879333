import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from "primereact/inputtext";
import {MultiSelect} from 'primereact/multiselect';
import {TabView, TabPanel} from 'primereact/tabview';

import {FormatDisplay, ToastService, useDataTableBasic, useForm} from '@iamsoftware/react-hooks';

import {PurchasesOrdersService as Service} from './PurchasesOrdersService';
import {useCrupPOParts} from './CrupPOParts';
import {ProductContents} from '../../ban-hang/san-pham/ProductContents';
import {SuppliersService} from '../nha-cung-cap/SuppliersService';
import {PurchaseOrderShipments} from './OrderShipments';

export const useCrupPO = ({reloadLazyData}) => {

  const [orderId, setOrderId] = useState('');
  const [display, setDisplay] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [displayId, setDisplayId] = useState('');
  const [creating, setCreating] = useState(false)
  const [displayProductContents, setDisplayProductContents] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0)
  const [displayShipments, setDisplayShipments] = useState(null);
  const [orderData, setOrderData] = useState(null);

  const getDisplayId = () => {
    Service.displayId().then(data => {
      setDisplayId(data.displayId)
    })
  }
  const onDeleteOrderItem = orderItemSeqId => {
    if (orderItemSeqId) {
      const _value = form.getRawValue();
      if (!_value.deleteOrderItemSeqIds) {
        _value.deleteOrderItemSeqIds = [orderItemSeqId];
      } else {
        _value.deleteOrderItemSeqIds.push(orderItemSeqId);
      }
      form.setValue(_value);
    }
  }
  const {renderParts, parts, setParts} = useCrupPOParts({disabled, onDeleteOrderItem, setDisplayProductContents});

  const form = useForm({
    fields: [
      {field: 'deleteOrderPartSeqIds', type: 'hidden'},
      {field: 'deleteOrderItemSeqIds', type: 'hidden'},
      {
        field: 'pseudoId', type: 'custom', className: 'md:col-2', body: <div>
          <div>
            <div>
              Mã đơn hàng<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={displayId} onChange={e => setDisplayId(e.target.value)}/>
              <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getDisplayId}>
            <i className="pi pi-sync"/>
          </span>
            </div>
          </div>
        </div>
      },
      {field: 'placedDate', header: 'Ngày lập', InputTextProps: {type: 'date'}, className: 'md:col-2'},
      {
        field: 'supplierPartyIds', type: 'custom', className: 'md:col-8', body: <div>
          <div>
            <div>
              Nhà cung cấp<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <SupplierParties creating={creating} data={orderData} setParts={setParts}/>
            </div>
          </div>
        </div>
      },
      {field: 'status', header: 'Trạng thái', className: 'md:col-2', disabled: true},
      {field: 'orderName', header: 'Ghi chú', className: 'md:col-10'},
    ],
    disabled
  });
  const onDeleteOrderPart = orderPartSeqId => {
    if (orderPartSeqId) {
      const _value = form.getRawValue();
      if (!_value.deleteOrderPartSeqIds) {
        _value.deleteOrderPartSeqIds = [orderPartSeqId];
      } else {
        _value.deleteOrderPartSeqIds.push(orderPartSeqId);
      }
      form.setValue(_value);
    }
  }

  const doCreate = async () => {
    setOrderId(null);
    setCreating(true)
    form.reset();
    setParts([]);
    setDisabled(false);
    setActiveIndex(0)
    await Service.displayId().then(data => {
      setDisplayId(data.displayId)
      form.setValue(
        {
          placedDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'),
          status: "Tạo mới"
        });
    })

    setDisplay({create: Date.now()});
  }

  const doUpdate = async (orderId: string, data: any, doView: boolean) => {
    setOrderId(orderId);
    setParts(data.parts);
    setCreating(false)
    setActiveIndex(0)
    if (data.placedDate) {
      data.placedDate = FormatDisplay.date(new Date(data.placedDate), 'YYYY-MM-DD')
    }
    form.setValue(data)
    setDisplayId(data.displayId)
    if (doView === true) {
      setDisabled(true);
      setOrderData(data)
      setDisplay({update: true});
    } else {
      setOrderData(data)
      setDisabled(false);
      setDisplay({update: true});
    }
  }
  const inject = () => {
    const item = form.getValue();
    item.parts = parts;
    return item;
  }

  const onSubmit = event => {
    if (!submitting && form.valid()) {
      setSubmitting(true);
      let promise;
      if (orderId) {
        promise = Service.update(orderId, inject());
      } else {
        promise = Service.create(inject());
      }
      promise.then(() => {
        setDisplay(null);
        ToastService.success();
        reloadLazyData();
      }).finally(() => {
        setSubmitting(false);
      });

    }
    event.preventDefault();
  }
  useEffect(() => {
      if (activeIndex === 1 && orderData) {
        setDisplayShipments(orderData)
      }
    },
    [activeIndex, orderData])
  const footer = (
    <div className="flex justify-content-end">
      {!disabled && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  const render = () => {
    return (
      <Fragment>
        <Dialog header="Đơn hàng mua" footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
          {display?.create &&
						<div className="grid pt-2">
							<div className="col-12">
                {form.render()}
							</div>
							<div className={'col-12'}>
                {renderParts()}
							</div>
						</div>
          }

          {display?.update &&
						<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
							<TabPanel header="Thông tin đơn hàng ">
								<div className="grid pt-2">
									<div className="col-12">
                    {form.render()}
									</div>
									<div className={'col-12'}>
                    {renderParts()}
									</div>
								</div>
							</TabPanel>
							<TabPanel header="Phiếu nhập">
								<PurchaseOrderShipments Service={PurchaseOrderShipments} type="incoming" display={displayShipments} setDisplay={setDisplayShipments} reloadLazyData={reloadLazyData}/>
							</TabPanel>
						</TabView>
          }
        </Dialog>
        <ProductContents display={displayProductContents} setDisplay={setDisplayProductContents}/>
      </Fragment>
    );
  }

  return {renderCrupPO: render, doCreatePO: doCreate, doUpdatePO: doUpdate};
}

const SupplierParties = ({setParts, creating, data}) => {
  const [selectedSupplierParties, setSelectedSupplierParties] = useState([])
  const [supplierParties, setSupplierParties] = useState([]);

  useEffect(() => {
    if (data) {
      const tmp = []
      data.parts.map(part => {
        part.partyId = part.vendorPartyId;
        part.partyName = part.vendorPartyName;
        tmp.push(part)
        return part
      })
      setSelectedSupplierParties(tmp)
    }
  }, [data])
  useEffect(() => {
    if (selectedSupplierParties) {
      setParts(selectedSupplierParties)
    }
  }, [selectedSupplierParties])
  useEffect(() => {
    if (creating === true) {
      setSelectedSupplierParties([])
      setParts([])
      SuppliersService.getList('').then(({listData}) => {
        setSupplierParties(listData.map(it => {
          it.items = []
          delete it.disabled;
          return it;
        }));
      })
    }
  }, [])
  useEffect(() => {
    if (selectedSupplierParties?.length > 0 && creating === false && supplierParties.length === 0) {
      SuppliersService.getList('').then(({listData}) => {
        setSupplierParties(listData.map(it => {
          delete it.disabled;
          for (const selectedSupplierParty of selectedSupplierParties) {
            if (selectedSupplierParty.vendorPartyId === it.partyId) {
              return selectedSupplierParty
            }
          }
          return it
        }));
      })
    }
  }, [selectedSupplierParties]); // eslint-disable-line react-hooks/exhaustive-deps

  return (<MultiSelect value={selectedSupplierParties} onChange={(e) => setSelectedSupplierParties(e.value)} options={supplierParties} optionLabel="partyName"
                       placeholder="Chọn nhà cung cấp" className="w-full md:w-20rem" disabled={false}/>);
}
