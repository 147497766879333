import {Fragment, useEffect, useState} from 'react';

import {SelectButton} from 'primereact/selectbutton';
import {Fieldset} from 'primereact/fieldset';
import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext";

import {ToastService, useDataTable, useDialogCrup, useForm, useToolbar, validateTaxCode} from '@iamsoftware/react-hooks';

import {CustomersService as Service} from './CustomersService';
import {PartyService} from '../../../service/PartyService';

import {ImportExcel} from '../../../shared/components/ImportExcel';
import {useFacilities} from './Facilities';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];

export const Customers = () => {

  const header = 'Khách hàng';
  const dataKey = 'partyId';

  const [statusId, setStatusId] = useState(statuses[0].value);
  const [requiredParams, setRequiredParams] = useState(null);
  const [readOnly, setReadOnly] = useState(false)
  const [crupMethod, setCrupMethod] = useState(null);
  const [partyId, setPartyId] = useState(null);
  const [displayContent, setDisplayContent] = useState(false);
  const [headerResult, setHeaderResult] = useState(null);
  const [customerPseudoId, setCustomerPseudoId] = useState('');

  useEffect(() => {
    setHeaderResult(tabHeader)
  }, []);
  useEffect(() => {
    if (statusId) {
      setRequiredParams({disabled: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, setSelectedItems, reloadLazyData} = useDataTable({
    tableHeader: headerResult,
    dataKey,
    columns: [
      {
        field: 'pseudoId', header: 'Mã khách hàng', width: 200, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => Service.get(rowData.partyId).then(data => {
            commonInfoForm.setValue(data);
            setPartyId(data.partyId)
            if (data.contactParties.length > 0) {
              contactInfoForm.setValue(data.contactParties[0])
            } else {
              contactInfoForm.setValue([])
            }
            setCustomerFacs(data.customerFacs);
            setSelectedItems(rowData)
            update(data);
          })}>{rowData.pseudoId}</a>
        }
      },
      {field: 'partyName', header: 'Tên khách hàng', minWidth: 250, matchMode: 'contains'},
      {field: 'telNumber', header: 'Số điện thoại', width: 150, matchMode: 'contains'},
      {field: 'address1', header: 'Địa chỉ', minWidth: 300, matchMode: 'contains'},
      {field: 'lastUpdatedStamp', header: 'Ngày tạo', width: 120, matchMode: 'contains', dataType: 'date'}
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    actionColumnWidth: 150,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Xem', className: 'p-button-info', command: () => doUpdate(true, item)},
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(false, item)},
          ]
          if (statusId === 'N') {
            item.actions.push({icon: 'pi pi-lock', title: 'Khóa', className: 'p-button-danger', command: () => doDisable(item)})
          } else if (statusId === 'Y') {
            item.actions.push({icon: 'pi pi-lock-open', title: 'Mở khóa', className: 'p-button-success', command: () => doEnable(item)})
          }
        })

      }
    },
    requiredParams
  });

  const getByTaxCode = () => {
    const _value = commonInfoForm.getValue();
    if (customerPseudoId) {
      PartyService.getByTaxCode(customerPseudoId).then(({data}) => {
        if (data?.partyName) {
          _value.partyName = data.partyName;
          _value.address1 = data.address1;
          commonInfoForm.setValue(_value);
        }
      });
    } else {
      ToastService.error('Mã số thuế không hợp lệ');
    }
  }

  const {renderFacilities, getFacilities, setCustomerFacs, deleteFacs, setDeleteFacs} = useFacilities({readOnly: crupMethod === 'View'}, partyId, displayContent);

  const commonInfoForm = useForm({
    fields: [
      {
        field: 'pseudoId', type: 'custom', className: 'md:col-3', body: <div>
          <div>
            <div>
              Mã khách hàng<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={customerPseudoId} onChange={e => setCustomerPseudoId(e.target.value)}/>
              {crupMethod !== 'View' && <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getByTaxCode}>
            <i className="pi pi-search"/>
          </span>}
            </div>
          </div>
        </div>
      },
      {field: 'partyName', header: 'Tên khách hàng', required: true, className: 'md:col-9'},
      {field: 'phoneNumber', header: 'Số điện thoại', className: 'md:col-3'},
      {field: 'address1', header: 'Địa chỉ', required: true, className: 'md:col-9'},
    ],
    readOnly
  });
  const contactInfoForm = useForm({
    fields: [
      {field: 'partyName', header: 'Tên người liên hệ', className: 'md:col-3'},
      {field: 'emailAddress', header: 'Email', className: 'md:col-3'},
      {field: 'phoneNumber', header: 'Số điện thoại người liên hệ', className: 'md:col-3'},
      {field: 'jobTitle', header: 'Chức vụ', className: 'md:col-3'},
      {field: 'partyId', type: 'hidden'}
    ],
    readOnly
  })
  const {render: renderDialogCrup, create, update, view, display, formMethod} = useDialogCrup({
    header,
    dataKey,
    width: '90rem',
    fields: [
      {
        type: 'custom', className: 'md:col-12 mb-3',
        body: <Fieldset legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
      },
      {
        type: 'custom', className: 'md:col-12 mb-3',
        body: <Fieldset legend="Thông tin liên hệ">
          {contactInfoForm.render()}
        </Fieldset>
      },
      {type: 'custom', body: renderFacilities()},
    ],
    createItem: async item => {
      item.customerFacs = getFacilities();
      item.contactParties = [contactInfoForm.getValue()]
      return Service.create(await inject(item));
    },
    updateItem: async (id, item) => {
      if (deleteFacs) {
        item.facilityIds = deleteFacs.map(item => {
          return item.facilityId
        })
        Service.deleteFacilities(id, item);
        delete item.facilityIds;
      }
      setDeleteFacs([])
      item.customerFacs = getFacilities();
      item.contactParties = [contactInfoForm.getValue()]
      return Service.update(id, await inject(item));
    },
    reloadLazyData
  });

  useEffect(() => {
    setDisplayContent(display)
  }, [display]);

  const inject = item => {
    const commonInfo = commonInfoForm.getValue();
    item.pseudoId = customerPseudoId;
    return Object.assign(item, commonInfo);
  }

  useEffect(() => {
    setCrupMethod(formMethod);
  }, [formMethod]);

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    contactInfoForm.reset();
    commonInfoForm.reset();
    setCustomerFacs([])
    setCustomerPseudoId('')
    create();
  }

  const doUpdate = (doView: boolean, rowData) => {
    Service.get(rowData[dataKey]).then(data => {
      setCustomerPseudoId(data.pseudoId)
      setPartyId(data.partyId)
      commonInfoForm.setValue(data);
      if (data.contactParties.length > 0) {
        contactInfoForm.setValue(data.contactParties[0])
      } else {
        contactInfoForm.setValue([])
      }
      setCustomerFacs(data.customerFacs)
      if (doView === true) {
        setReadOnly(true)
        view(data);
      } else {
        setReadOnly(false)
        update(data);
      }
    });

  }

  const doDisable = (rowData) => {
    Service.disable(rowData[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    });

  }
  const doEnable = (rowData) => {
    Service.enable(rowData[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    });

  }
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{header}</span>
          <div>
            <Button label={'Tạo mới'} icon="pi pi-plus" size={'small'} severity={'success'} style={{height: '25px'}} className={'mr-2'} onClick={() => doCreate()}></Button>
            <ImportExcel column={{field: 'pseudoId', header: 'Mã khách hàng'}}
                         downloadTemplateUrl={`${Service.baseURL}/iam-party/customers/download-excel-template`} uploadExcel={contentFile => Service.uploadExcel(contentFile)}/>
          </div>
        </Fragment>
      </div>
    </div>
  )


  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12">
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 14rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
    </div>
  )
}