import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {ToastService, useDataTable, useDialogCrup, useFileUploader, useToolbar} from '@iamsoftware/react-hooks';

import {ProductsService as Service} from './ProductsService';
import {QuantityUomsService} from '../../quan-ly/danh-muc/don-vi-san-pham/QuantityUomsService';

import {ImportExcel} from '../../../shared/components/ImportExcel';
import {ProductContents} from './ProductContents';
import {CrupPurchaseHistory} from './PurchaseHistory'

const statuses = [
  {value: 'ProdActive', label: 'Đang hoạt động'},
  {value: 'ProdInactive', label: 'Ngừng hoạt động'}
];

export const Products = () => {

  const header = 'Sản Phẩm';
  const dataKey = 'productId';

  const [statusId, setStatusId] = useState(statuses[0].value);
  const [requiredParams, setRequiredParams] = useState(null);

  const [quantityUoms, setQuantityUoms] = useState([]);
  const [originGeos, setOriginGeos] = useState([]);

  const [contents, setContents] = useState(null);

  const [displayProductContents, setDisplayProductContents] = useState(null);
  const [displayPurchaseHistory, setDisplayPurchaseHistory] = useState(null);
  const [headerResult, setHeaderResult] = useState(null);
  useEffect(() => {
    if (statusId) {
      setRequiredParams({statusId: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
    setHeaderResult(tabHeader)

  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    tableHeader: headerResult,
    dataKey,
    columns: [
      {
        field: 'pseudoId', header: 'Mã sản phẩm', width: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => Service.get(rowData.productId).then(data => {
            setContents(null)
            if (data.amountUomId) {
              data.qtyUom = {value: data.amountUomId, label: data.uomName};
            }
            if (data.originGeoId) {
              data.originGeo = {value: data.originGeoId, label: data.geoName};
            }

            if (data.productContents?.length) {
              const _contents = [];
              for (const productContent of data.productContents) {
                _contents.push({
                  key: productContent.productContentId,
                  contentType: productContent.contentType,
                  fileName: productContent.fileName,
                  src: `${Service.baseURL}/${Service.entity}/${data[dataKey]}/contents/${productContent.productContentId}`
                });
              }
              setContents(_contents);
            }
            setFileUploaderDisabled(false);
            update(data);
          })}>{rowData.pseudoId}</a>
        }
      },
      {field: 'productName', header: 'Tên sản phẩm', minWidth: 400, matchMode: 'contains'},
      {field: 'localizedName', header: 'Tên nước ngoài', minWidth: 400, matchMode: 'contains'},
      {field: 'salePrice', header: 'Giá bán', dataType: 'number', width: 150, matchMode: 'contains'},
      {field: 'purchasePrice', header: 'Giá mua', dataType: 'number', width: 150, matchMode: 'contains'},
      {field: 'uomName', header: 'Đơn vị tính', width: 120, matchMode: 'contains'},
      {field: 'geoName', header: 'Quốc gia', width: 150, matchMode: 'contains'},
      {field: 'description', header: 'Mô tả', minWidth: 150, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 175,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Xem', className: 'p-button-info', command: () => doUpdate(true, item)},
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(false, item)},
            {icon: 'pi pi-image', title: "Hình ảnh", className: 'p-button-help', command: () => setDisplayProductContents(item)},
            {icon: 'pi pi-history', title: "Lịch sử mua hàng", className: 'p-button-info', command: () => setDisplayPurchaseHistory(item)},
          ]
          if (statusId === 'ProdActive') {
            item.actions.push({icon: 'pi pi-lock', title: 'Khóa', className: 'p-button-danger', command: () => doDisable(item)})
          } else if (statusId === 'ProdInactive') {
            item.actions.push({icon: 'pi pi-lock-open', title: 'Mở khóa', className: 'p-button-success', command: () => doEnable(item)})
          }
        })

      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const searchQuantityUoms = event => {
    const _event: any = {
      filters: {
        disabled: {value: 'N', matchMode: 'equals'},
        description: {value: event.query, matchMode: 'contains'}
      }
    };
    QuantityUomsService.getList(JSON.stringify(_event)).then(data => {
      setQuantityUoms(data.listData.map(item => {
        return {value: item.uomId, label: item.description};
      }));
    });
  }

  const searchOriginGeos = event => {
    const _event: any = {
      filters: {geoName: {value: event.query, matchMode: 'contains'}}
    };
    Service.getOriginGeos(JSON.stringify(_event)).then(data => {
      setOriginGeos(data.listData.map(item => {
        return {value: item.geoId, label: item.geoName};
      }));
    });
  }

  const onFileSelect = async file => {
    if (file.key) {
      window.open(file.src);
    } else {
      const src = window.URL.createObjectURL(new Blob([file], {type: file.type}));
      window.open(src)
    }
  }

  const {renderFileUploader: renderContents, getFiles: getContents, deletedExistingFiles: deletedContents, setFileUploaderDisabled} = useFileUploader({
    onSelect: onFileSelect,
    existingFiles: contents
  });

  const {render: renderDialogCrup, create, update, view} = useDialogCrup({
    header,
    dataKey,
    width: '85rem',
    formGroups: [
      {className: 'col-7', start: 0, end: 7},
      {className: 'col-5', start: 7, end: 9}
    ],
    fields: [
      {field: 'pseudoId', header: 'Mã sản phẩm', required: true, className: 'md:col-12'},
      {field: 'productName', header: 'Tên sản phẩm', required: true, className: 'md:col-12'},
      {field: 'localizedName', header: 'Tên nước ngoài', className: 'md:col-12'},
      {field: 'salePrice', header: 'Giá bán', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-6'},
      {field: 'purchasePrice', header: 'Giá mua', required: true, type: 'InputNumber', InputNumberProps: {min: 0}, className: 'md:col-6'},
      {
        field: 'qtyUom', header: 'Đơn vị tính', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: quantityUoms, completeMethod: searchQuantityUoms, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-6'
      },
      {
        field: 'originGeo', header: 'Xuất xứ', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: originGeos, completeMethod: searchOriginGeos, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-6'
      },
      {field: 'description', header: 'Mô tả', type: 'InputTextarea', className: 'custom-text-area'},
      {
        field: 'productContents', header: 'Ảnh sản phẩm', type: 'FileUploader', FileUploaderProps: {render: renderContents}, className: 'md:col-12'
      },
      {field: 'referenceUrl', header: 'Link tham khảo', type: 'InputTextarea', className: 'md:col-12'}
    ],
    createItem: async item => {
      return Service.create(await inject(item));
    },
    updateItem: async (id, item) => {
      return Service.update(id, await inject(item));
    },
    reloadLazyData: data => {
      reloadLazyData();
    }
  });

  const inject = async item => {
    item.amountUomId = item.qtyUom.value;
    delete item.qtyUom;

    if (item.originGeo) {
      item.originGeoId = item.originGeo.value;
      delete item.originGeo;
    }

    item.productContents = await getContents(false);
    if (item.productContents.length === 1) {
      item.productContent = item.productContents[0];
      delete item.productContents;
    }
    if (deletedContents?.length) {
      if (deletedContents.length === 1) {
        item.deleteProductContentId = deletedContents[0];
      } else {
        item.deleteProductContentIds = deletedContents;
      }
    }

    return Service.toFormData(item);
  }

  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    setFileUploaderDisabled(false);
    create();
  }

  const doUpdate = (doView: boolean, rowData) => {
    setContents(null)
    Service.get(rowData.productId).then(data => {
      if (data.amountUomId) {
        data.qtyUom = {value: data.amountUomId, label: data.uomName};
      }
      if (data.originGeoId) {
        data.originGeo = {value: data.originGeoId, label: data.geoName};
      }

      if (data.productContents?.length) {
        const _contents = [];
        for (const productContent of data.productContents) {
          _contents.push({
            key: productContent.productContentId,
            contentType: productContent.contentType,
            fileName: productContent.fileName,
            src: `${Service.baseURL}/${Service.entity}/${data[dataKey]}/contents/${productContent.productContentId}`
          });
        }
        setContents(_contents);
      }
      if (doView === true) {
        setFileUploaderDisabled(true);
        view(data);
      } else {
        setFileUploaderDisabled(false);
        update(data);
      }
    });

  }

  const doDisable = (rowData) => {
    Service.disable(rowData[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    });

  }
  const doEnable = (rowData) => {
    Service.enable(rowData[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    });
  }
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{header}</span>
          <div>
            <Button label={'Tạo mới'} icon="pi pi-plus" style={{height:'25px'}} size={'small'} severity={'success'} className={'mr-2'} onClick={() => doCreate()}></Button>
            <ImportExcel column={{field: 'pseudoId', header: 'Mã sản phẩm'}}
                         downloadTemplateUrl={`${Service.baseURL}/${Service.entity}/download-excel-template`} uploadExcel={contentFile => Service.uploadExcel(contentFile)}/>
          </div>
        </Fragment>
      </div>
    </div>
  )

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>

      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 14rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
      <CrupPurchaseHistory display={displayPurchaseHistory} setDisplay={setDisplayPurchaseHistory}></CrupPurchaseHistory>
      <ProductContents display={displayProductContents} setDisplay={setDisplayProductContents}/>
    </div>
  )
}