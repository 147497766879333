import {EntityService} from '../../../service/EntityService';

export class CustomersService extends EntityService {

  static entity = 'iam-manager/customers';

  static uploadExcel(contentFile: any): Promise<any> {
    return this.axios.post(`/iam-party/customers/upload-excel`, this.toFormData({contentFile})).then(res => res.data);
  }

  static find(term: string): Promise<any> {
    return this.axios.get(`/iam-party/customers/find?term=${term}`).then(res => res.data);
  }

  static getFacilities(partyId: string, lazyLoadEvent?: string): Promise<any> {
    return this.axios.get(`${this.entity}/${partyId}/list-fac`, {params: {lazyLoadEvent}}).then(res => res.data);
  }
  static deleteFacilities(partyId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${partyId}/delete-fac`, data).then(res => res.data);
  }

  static createFacility(partyId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${partyId}/create-fac`, data).then(res => res.data);
  }

  static listCus(): Promise<any> {
    return this.axios.get(`${this.entity}/list`).then(res => res.data);
  }

  static createBank(id: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${id}/create-bank-acc`, data).then(res => res.data);
  }

  static updateBank(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/update-bank-acc`, data).then(res => res.data);
  }

}
