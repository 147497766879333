import {Route, Routes} from 'react-router-dom';

import {Facilities} from './kho-hang';
import {IncomingShipments} from './nhap-kho';
import {OutgoingShipments} from './xuat-kho';
import {MergeShipments} from './gop-phieu-xuat'
import KiemKho from './ton-kho';

export default function Logistics() {

  return (
    <Routes>
      <Route path="kho-hang" element={<Facilities/>}/>
      <Route path="ton-kho" element={<KiemKho/>}/>
      <Route path="nhap-kho" element={<IncomingShipments/>}/>
      <Route path="xuat-kho" element={<OutgoingShipments/>}/>
        <Route path="gop-phieu-xuat" element={<MergeShipments/>}/>
    </Routes>
  );
}
