import {Fragment, useEffect, useState} from 'react';
import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext";

import {ToastService, useDataTable, useDialogCrup, useToolbar, validateTaxCode} from '@iamsoftware/react-hooks';

import {InternalService as Service} from './InternalService';
import {PartyService} from "../../../service/PartyService";

export const Internal = () => {

  const header = 'Tổ chức';
  const dataKey = 'partyId';
  const [crupMethod, setCrupMethod] = useState(null);
  const [headerResult, setHeaderResult] = useState(null);
  const [partyPseudoId, setPartyPseudoId] = useState(null);
  useEffect(() => {
    setHeaderResult(tabHeader)

  }, [])
  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    tableHeader: headerResult,
    dataKey,
    columns: [
      {
        field: 'pseudoId', header: 'Mã tổ chức', minWidth: 100, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => Service.get(rowData.partyId).then(data => {
            update(data);
          })}>
            {rowData.pseudoId}</a>
        }
      }, {field: 'partyName', header: 'Tên tổ chức', minWidth: 150, matchMode: 'contains'},
      {field: 'comments', header: 'Tên nước ngoài', minWidth: 150, matchMode: 'contains'},
      {field: 'telNumber', header: 'Số điện thoại', minWidth: 100, matchMode: 'contains'},
      {field: 'address1', header: 'Địa chỉ', width: 200, matchMode: 'contains'},
      {field: 'externalId', header: 'Ghi tắt', minWidth: 50, matchMode: 'contains'},
      {field: 'createTime', header: 'Ngày tạo', minWidth: 100, matchMode: 'contains', dataType: 'date'},

    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    actionColumnWidth: 120 ,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Xem', className: 'p-button-info', command: () => doUpdate(true, item)},
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(false, item)},
            {icon: 'pi pi-times', title: 'Xóa', className: 'p-button-danger', command: () => doDelete(item)},

          ]
        })

      }
    },
  });

  const getByTaxCode = () => {
    const _value = form.getValue()
    if (partyPseudoId && validateTaxCode(partyPseudoId)) {
      PartyService.getByTaxCode(partyPseudoId).then(({data}) => {
        if (data?.partyName) {
          _value.partyName = data.partyName;
          _value.address1 = data.address1;
          form.setValue(_value);
        }
      });
    } else {
      ToastService.error('Mã số thuế không hợp lệ');
    }
  }
  const {render: renderDialogCrup, create, update, view, form, formMethod} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      // {field: 'pseudoId', header: 'Mã tổ chức', required: true, className: 'md:col-3'},
      // {
      //   field: 'getByTaxCode', type: 'custom', body: <Button icon="pi pi-search" rounded severity="success" onClick={getByTaxCode} disabled={crupMethod !== 'Create'}/>, className: 'md:col-1 pl-0 pt-4'
      // },
      {
        field: 'pseudoId', type: 'custom', className: 'md:col-4', body: <div>
          <div>
            <div>
              Mã tổ chức<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={partyPseudoId} onChange={e => setPartyPseudoId(e.target.value)}/>
              {crupMethod !== 'View' && <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getByTaxCode}>
            <i className="pi pi-search"/>
          </span>}
            </div>
          </div>
        </div>
      },
      {field: 'partyName', header: 'Tên tổ chức', required: true, className: 'md:col-8'},
      {field: 'externalId', header: 'Ghi tắt', className: 'md:col-4'},
      {field: 'comments', header: 'Tên nước ngoài', className: 'md:col-8'},
      {field: 'emailAddress', header: 'Email', className: 'md:col-4'},
      {field: 'contactNumber', header: 'Số điện thoại', className: 'md:col-8'},
      {field: 'address1', header: 'Địa chỉ', type: 'InputTextarea', className: 'md:col-12'}
    ],
    createItem: async item => {
      return Service.create(item);
    },
    updateItem: async (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });
  useEffect(() => {
    setCrupMethod(formMethod);
  }, [formMethod]);


  const doCreate = () => {
    setPartyPseudoId('')
    create();
  }
  const doUpdate = (doView: boolean,rowData) => {
      Service.get(rowData[dataKey]).then(data => {
        setPartyPseudoId(rowData.pseudoId)
        if (doView === true) {
          view(data);
        } else {
          update(data);
        }
      });

  }

  const doDelete = (rowData) => {
      Service.delete(rowData[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    }


  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{header}</span>
          <div>
            <Button label={'Tạo mới'} icon="pi pi-plus" style={{height:'25px'}} size={'small'} severity={'success'} className={'mr-2'} onClick={() => doCreate()}></Button>
          </div>
        </Fragment>
      </div>
    </div>
  )
  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 11.4rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
    </div>
  )
}