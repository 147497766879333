import {Fragment, useEffect, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {ToastService} from '@iamsoftware/react-hooks';

import {HouseholdsService as Service} from './HouseholdsService';
import {AppStorage, UserStorage} from '../../../service/UserStorage';

import {DialogHouseholds} from './DialogHouseholds';

export const Households = () => {

  const [display, setDisplay] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [householdData, setHouseholdData] = useState(null)
  useEffect(() => {
    if (UserStorage.get('household')) {
      Service.get(UserStorage.get('household'))
        .then(data => {
          if (data?.partyId) {
            setHouseholdData(data)
          } else {
            setDisplay(true);
          }
        }
      )
        .catch((errorMessage)=>{
          setDisplay(true);
        })
    } else {
      setDisplay(true);
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const doSelect = () => {
    if (selectedItem) {
      UserStorage.set('household', selectedItem.partyId);
      window.location.reload();
    }
  }

  const doHide = () => {
    if (householdData) {
      setDisplay(false);
    } else {
      ToastService.error('Bạn chưa chọn tổ chức');
    }
  }
  const me = JSON.parse(AppStorage.get('me'));
  const footer = (
    <div>
      <Button label="Chọn tổ chức" icon="pi pi-check" className="p-button-sm mr-2" type="button" onClick={doSelect} disabled={!selectedItem}/>
      <Button label="Đóng" icon="pi pi-times" className="p-button-outlined p-button-secondary p-button-sm" type="button" onClick={doHide}/>
    </div>
  );

  return (
    <Fragment>
      <div className="house-holds" onClick={() => setDisplay(true)}>
        <p className="mb-0"><b>{householdData?.partyName}</b></p>
        <p className="text-right">{householdData?.pseudoId}</p>
      </div>

      <Dialog header="Lựa chọn tổ chức" footer={footer} visible={display} style={{width: '70rem'}} position="top" onHide={doHide}>
        <DialogHouseholds setSelectedItem={setSelectedItem}/>
      </Dialog>
    </Fragment>
  );
}