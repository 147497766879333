import {Fragment, useEffect, useState} from 'react';
import {ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

import {SalesOrdersService as Service} from './SalesOrdersService';

import {ProductContents} from '../san-pham/ProductContents';


export const ProductLabel = ({display, setDisplay, items, setItems}) => {
  const [displayProductContents, setDisplayProductContents] = useState(null);

  useEffect(() => {
    if (display) {
      Service.get(display).then(data => {
        data.items.forEach((item, index) => {
          item['dataKey'] = `${item.orderId}_${item.orderPartSeqId}_${item.orderItemSeqId}_${index}`;
          item.productNameWithPseudoId = `[${item.pseudoId}] - ${item.productName}`;
          if (item.localizedName) {
            item.productNameWithPseudoId += ` | ${item.localizedName}`;
          }

          item.quantityStamp = item.quantity;
        });
        setItems(data.items);
      });
    }
  }, [display]);
  const onEditorChange = (rowData, column, newValue) => {
    setItems(prevItems => prevItems.map(prevItem => {
      if (prevItem['dataKey'] === rowData['dataKey']) {
        return {
          ...prevItem,
          [column.field]: newValue
        }
      }
      return prevItem;
    }));
  }

  const {render: renderItems} = useDataTableBasic({

    tableHeader: `${items?.length} Sản phẩm`,
    dataKey: 'dataKey',
    indexColumnWidth: 40,
    columns: [
      {
        field: 'productNameWithPseudoId', header: 'Sản phẩm', minWidth: 170, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <div>{rowData.productNameWithPseudoId} <i className="pi pi-image ml-2" onClick={() => setDisplayProductContents(rowData)}></i></div>
        }
      },
      {field: 'quantity', header: 'SL', width: 90, matchMode: 'equals', dataType: 'number'},
      {field: 'quantityUom', header: 'ĐVT', width: 100, matchMode: 'contains'},
      {field: 'customerContactName', header: 'Người liên hệ', minWidth: 150, matchMode: 'contains'},
      {field: 'receivedDate', header: 'Ngày nhập', minWidth: 150, dataType: 'editor', editorConfig: {onEditorChange}},
      {field: 'quantityStamp', header: 'SL tem in', minWidth: 150, dataType: 'editor', editorConfig: {onEditorChange, field: {type: 'InputNumber'}}},
      {field: 'shouldPrint', header: 'In', width: 120, dataType: 'editor', editorConfig: {onEditorChange, field: {type: 'Checkbox'}}, className: 'text-center'}
    ],
    items
  });




  return (
    <Fragment>
      {renderItems()}
      <ProductContents display={displayProductContents} setDisplay={setDisplayProductContents}/>
    </Fragment>
  );
}