import {EntityService} from '../../../service/EntityService';

export class InternalService extends EntityService {

    static entity = 'iam-party/org-internal';

    static getRoles(): Promise<any> {
        return this.axios.get(`${this.entity}/roles`).then(res => res.data);
    }
    static getList(lazyLoadEvent: string, params?: any): Promise<any> {
        return this.axios.get(`${this.entity}/list?lazyLoadEvent=${lazyLoadEvent}`, {params}).then(res => res.data);
    }

}
