import { Dropdown } from 'primereact/dropdown';
import {useEffect, useState} from 'react';
import moment from 'moment';
import { Chart } from 'primereact/chart';
import { InputNumber } from 'primereact/inputnumber';
import {DashboardService as Service} from './DashboardService';
import {Link} from 'react-router-dom';

export default function Dashboard(){
  const [times, setTimes] = useState('THISFISCALYEAR')
  const [times1, setTimes1] = useState('THISFISCALYEAR')
  const [times2, setTimes2] = useState('THISFISCALYEAR')
  const [saleTotal, setSaleTotal] = useState(0)
  const [purchaseTotal, setPurchaseTotal] = useState(0)
  const [newCustomer, setNewCustomer] = useState(0)
  const [textValue, setTextValue] = useState(6)
  const [textValue1, setTextValue1] = useState(6)
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [chartData1, setChartData1] = useState({});
  const [chartOptions1, setChartOptions1] = useState({});
  const [dueCount, setDueCount] = useState(0)
  const [incomeCount, setIncomeCount] = useState(0)

  const getPeriodValue = (period?: string) =>{
    let fromDate = new Date();
    let thruDate = new Date();

    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const quarters = ['FISCALQUARTER1', 'FISCALQUARTER2', 'FISCALQUARTER3', 'FISCALQUARTER4'];

    if (period && months.indexOf(period) >= 0){
      fromDate = moment().month(months.indexOf(period)).startOf('month').toDate();
      thruDate = moment().month(months.indexOf(period)).endOf('month').toDate();
    }else if ((period && quarters.indexOf(period) >= 0)){
      fromDate = moment().quarter(quarters.indexOf(period) + 1).startOf('quarter').toDate();
      thruDate = moment().quarter(quarters.indexOf(period) + 1).endOf('quarter').toDate();
    }else {
      switch (period) {
        case 'TODAY':
          fromDate = moment().startOf('day').toDate();
          thruDate = moment().endOf('day').toDate();
          break;
        case 'THISWEEK':
          fromDate = moment().startOf('isoWeek').toDate();
          thruDate = moment().endOf('isoWeek').toDate();
          break;
        case 'THISWEEKTODATE':
          fromDate = moment().startOf('isoWeek').toDate();
          break;
        case 'THISMONTH':
          fromDate = moment().startOf('month').toDate();
          thruDate = moment().endOf('month').toDate();
          break;
        case 'THISMONTHTODATE':
          fromDate = moment().startOf('month').toDate();
          break;
        case 'THISFISCALQUARTER':
          fromDate = moment().startOf('quarter').toDate();
          thruDate = moment().endOf('quarter').toDate();
          break;
        case 'THISFISCALQUARTERTODATE':
          fromDate = moment().startOf('quarter').toDate();
          break;
        case 'THISFISCALYEAR':
          fromDate = moment().startOf('year').toDate();
          thruDate = moment().endOf('year').toDate();
          break;
        case 'THISFISCALYEARTODATE':
          fromDate = moment().startOf('year').toDate();
          break;
        case 'FIRSTSIXMONTH':
          fromDate = moment().startOf('year').toDate();
          thruDate = moment().month(5).endOf('month').toDate();
          break;
        case 'LASTSIXMONTH':
          fromDate = moment().month(6).startOf('month').toDate();
          thruDate = moment().endOf('year').toDate();
          break;
        case 'YESTERDAY':
          fromDate = moment().subtract(1, 'day').startOf('day').toDate();
          thruDate = moment().subtract(1, 'day').endOf('day').toDate();
          break;
        case 'LASTWEEK':
          fromDate = moment().subtract(1, 'week').startOf('isoWeek').toDate();
          thruDate = moment().subtract(1, 'week').endOf('isoWeek').toDate();
          break;
        case 'LASTMONTH':
          fromDate = moment().subtract(1, 'month').startOf('month').toDate();
          thruDate = moment().subtract(1, 'month').endOf('month').toDate();
          break;
        case 'LASTFISCALQUARTER':
          fromDate = moment().subtract(1, 'quarter').startOf('quarter').toDate();
          thruDate = moment().subtract(1, 'quarter').endOf('quarter').toDate();
          break;
        case 'LASTFISCALYEAR':
          fromDate = moment().subtract(1, 'year').startOf('year').toDate();
          thruDate = moment().subtract(1, 'year').endOf('year').toDate();
          break;
        case 'NEXTWEEK':
          fromDate = moment().add(1, 'week').startOf('isoWeek').toDate();
          thruDate = moment().add(1, 'week').endOf('isoWeek').toDate();
          break;
        case 'NEXTMONTH':
          fromDate = moment().add(1, 'month').startOf('month').toDate();
          thruDate = moment().add(1, 'month').endOf('month').toDate();
          break;
        case 'NEXTFISCALQUARTER':
          fromDate = moment().add(1, 'quarter').startOf('quarter').toDate();
          thruDate = moment().add(1, 'quarter').endOf('quarter').toDate();
          break;
        case 'NEXTFISCALYEAR':
          fromDate = moment().add(1, 'year').startOf('year').toDate();
          thruDate = moment().add(1, 'year').endOf('year').toDate();
          break;
      }
    }
    return {fromDate, thruDate};
  }

  //Chart bán ra
  useEffect(() => {
    if (textValue){
      const lineOptions = {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      };
      const lineData = {
        labels: new Array<any>(),
        datasets:[{
          label: 'đồng',
          data: new Array<any>(),
          fill: false,
          borderColor:'#42A5F5',
          tension: .4
        }]
      }
      Service.getSaleAmountPeriod(textValue).then(({listData}) =>{
        if (listData){
          listData.forEach(item =>{
            lineData.labels.push(item.label);
            lineData.datasets[0].data.push(item.value);
          })
          setChartData(lineData)
          setChartOptions(lineOptions)
        }
      })
    }
  }, [textValue]);

  //Chart mua vào
  useEffect(() => {
    if (textValue1){
      const lineOptions = {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      };
      const lineData = {
        labels: new Array<any>(),
        datasets:[{
          label: 'đồng',
          data: new Array<any>(),
          fill: false,
          borderColor:'#42A5F5',
          tension: .4
        }]
      }
      Service.getPurchaseAmountPeriod(textValue1).then(({listData}) =>{
        if (listData){
          listData.forEach(item =>{
            lineData.labels.push(item.label);
            lineData.datasets[0].data.push(item.value);
          })
          setChartData1(lineData)
          setChartOptions1(lineOptions)
        }
      })
    }
  }, [textValue1]);

  //Card đơn hàng bán
  useEffect(() => {
    if (times){
      const {fromDate, thruDate} = getPeriodValue(times)
      Service.getSaleTotal(new Date(fromDate).getTime(), new Date(thruDate).getTime()).then(data =>{
        setSaleTotal(data.number)
      })
    }
  }, [times]);

  //Card đơn hàng mua
  useEffect(() => {
    if (times1){
      const {fromDate, thruDate} = getPeriodValue(times1)
      Service.getPurchaseTotal(new Date(fromDate).getTime(), new Date(thruDate).getTime()).then(data =>{
        setPurchaseTotal(data.number)
      })
    }
  }, [times1]);

  //Card kh mới
  useEffect(() => {
    if (times2){
      const {fromDate, thruDate} = getPeriodValue(times2)
      Service.getNewCustomer(new Date(fromDate).getTime(), new Date(thruDate).getTime()).then(data =>{
        setNewCustomer(data.number)
      })
    }
  }, [times2]);

  //Thanh toan
  useEffect(() => {
    Service.getPaymentDueIncome().then(data =>{
      setDueCount(data.dueCount)
      setIncomeCount(data.incomeCount)
    })
  }, []);

  return (
    <div className="grid">
      <div className="col-12 lg:col-4 xl:col-4">
        <div className="card stats-2">
          <div className="flex justify-content-between mb-3">
            <div className="flex">
              <span className="block font-medium mt-2">Số đơn hàng bán được tạo
                <Dropdown className="ml-1 p-dropdown-dasdboard" options={ReportPeriods} value={times} onChange={(e) => setTimes(e.value)} optionLabel="label"/>
              </span>
            </div>
          </div>
          <span style={{fontSize: '2rem'}} className="font-medium">{saleTotal}</span>
          <span className="ml-1">đơn hàng</span>
        </div>
      </div>
      <div className="col-12 lg:col-4 xl:col-4">
        <div className="card stats-1">
          <div className="flex justify-content-between mb-3">
            <div className="flex">
              <span className="block font-medium mt-2">Số đơn hàng mua được tạo
                <Dropdown className="ml-1 p-dropdown-dasdboard" options={ReportPeriods} value={times1} onChange={(e) => setTimes1(e.value)} optionLabel="label"/>
              </span>
            </div>
          </div>
          <span style={{fontSize: '2rem'}} className="font-medium">{purchaseTotal}</span>
          <span className="ml-1">đơn hàng</span>
        </div>
      </div>
      <div className="col-12 lg:col-4 xl:col-4">
        <div className="card stats-3">
          <div className="flex justify-content-between mb-3">
            <div className="flex">
              <span className="block font-medium mt-2">Số khách hàng mới
                <Dropdown className="ml-1 p-dropdown-dasdboard" options={ReportPeriods} value={times2} onChange={(e) => setTimes2(e.value)} optionLabel="label"/>
              </span>
            </div>
          </div>
          <span style={{fontSize: '2rem'}} className="font-medium">{newCustomer}</span>
          <span className="ml-1">khách hàng</span>
        </div>
      </div>
      <div className="col-12 lg:col-4 xl:col-4">
        <div className="card">
          <span style={{fontSize:'1.4rem'}}><strong>Thanh toán</strong></span>
          <div className="mt-3">
            <table>
              <a href='#/mua-hang/thanh-toan?id=due'>
                <tr>
                  <th style={{textAlign: 'left', paddingBottom: '1rem', width:'220px'}}><span style={{color: 'blue'}}>&#9679;</span> Số thanh toán đã quá hạn</th>
                  <th style={{padding: '0 0 1rem 1rem'}}>{dueCount}</th>
                </tr>
              </a>
              <a href='#/mua-hang/thanh-toan?id=income'>
                <tr>
                  <th style={{textAlign: 'left', width:'220px'}}><span style={{color: 'orange'}}>&#9679;</span> Số thanh toán sắp đến hạn</th>
                  <th style={{padding: '0 0 1rem 1rem'}}>{incomeCount}</th>
                </tr>
              </a>
            </table>
          </div>
        </div>
      </div>
      <div className="col-12 lg:col-4 xl:col-4">
      </div>
      <div className="col-12 lg:col-4 xl:col-4">
      </div>
      <div className="col-12 lg:col-6 xl:col-6">
        <div className="card">
          <div className="flex justify-content-between align-items-center">
            <h5>Tổng tiền đơn hàng bán ra</h5>
            <div>
              <InputNumber allowEmpty={false} className="config-input mb-3" value={textValue} onChange={(e) => setTextValue(e.value)} locale="vi-VN" min={1} max={24}/>
              <span>Tháng gần đây</span>
            </div>
          </div>
          <div className="p-card-content">
            <Chart type="line" data={chartData} options={chartOptions}/>
          </div>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-6">
        <div className="card">
          <div className="flex justify-content-between align-items-center">
            <h5>Tổng tiền đơn hàng mua vào</h5>
            <div>
              <InputNumber allowEmpty={false} className="config-input mb-3" value={textValue1} onChange={(e) => setTextValue1(e.value)} locale="vi-VN" min={1} max={24}/>
              <span>Tháng gần đây</span>
            </div>
          </div>
          <div className="p-card-content">
            <Chart type="line" data={chartData1} options={chartOptions1}/>
          </div>
        </div>
      </div>
    </div>
  )
}

const ReportPeriods = [
  {value: 'THISWEEK', label: 'Tuần này'},
  {value: 'THISMONTH', label: 'Tháng này'},
  {value: 'THISFISCALQUARTER', label: 'Quý này'},
  {value: 'THISFISCALYEAR', label: 'Năm nay'},
  {value: 'FIRSTSIXMONTH', label: '6 tháng đầu năm'},
  {value: 'LASTSIXMONTH', label: '6 tháng cuối năm'},
  {value: 'JAN', label: 'Tháng 1'},
  {value: 'FEB', label: 'Tháng 2'},
  {value: 'MAR', label: 'Tháng 3'},
  {value: 'APR', label: 'Tháng 4'},
  {value: 'MAY', label: 'Tháng 5'},
  {value: 'JUN', label: 'Tháng 6'},
  {value: 'JUL', label: 'Tháng 7'},
  {value: 'AUG', label: 'Tháng 8'},
  {value: 'SEP', label: 'Tháng 9'},
  {value: 'OCT', label: 'Tháng 10'},
  {value: 'NOV', label: 'Tháng 11'},
  {value: 'DEC', label: 'Tháng 12'},
  {value: 'FISCALQUARTER1', label: 'Quý I'},
  {value: 'FISCALQUARTER2', label: 'Quý II'},
  {value: 'FISCALQUARTER3', label: 'Quý III'},
  {value: 'FISCALQUARTER4', label: 'Quý IV'},
  {value: 'LASTWEEK', label: 'Tuần trước'},
  {value: 'LASTMONTH', label: 'Tháng trước'},
  {value: 'LASTFISCALQUARTER', label: 'Quý trước'},
  {value: 'LASTFISCALYEAR', label: 'Năm trước'}
];