import {Fragment, useEffect, useRef, useState} from 'react';
import {Panel} from 'primereact/panel';
import {Menu} from 'primereact/menu';
import {Dialog} from 'primereact/dialog';
import {InputText} from "primereact/inputtext";
import {Message} from 'primereact/message';
import {Button} from 'primereact/button';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTableBasic, useDialogCrup, useForm, useToolbar} from '@iamsoftware/react-hooks';

import {FacilitiesService} from '../../kho-van/kho-hang/FacilitiesService';

import {OrderShipmentItems} from '../../../shared/components/order/OrderShipmentItems';
import {PurchasesOrdersService, PurchasesOrdersService as EntityService} from './PurchasesOrdersService';

type ShipmentType = 'incoming' | 'outgoing';

interface Props {
  Service: EntityService
  type: ShipmentType
  display: any

  setDisplay(display: any): void

  reloadLazyData(): void
}

export const PurchaseOrderShipments = (props: Props) => {

  const {Service, type, display, setDisplay, reloadLazyData} = props;
  const configMenu = useRef(null);
  const toast = useRef(null);
  const [workEffortId, setWorkEffortId] = useState('')
  const menuItems = [
    {
      label: 'Cập nhật',
      icon: 'pi pi-pencil',
      command: () => {
        setDisplayDetail(workEffortId)
      }
    },
    {
      label: 'Nhập',
      icon: 'pi pi-arrow-down',
      command: () => {
        issue({receivedDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD')})
      }
    },
    {
      label: 'Hủy phiếu nhập',
      icon: 'pi pi-times',
      command: () => {
        doDelete()
      }
    }
  ];

  const dataKey = 'workEffortId';

  const [items, setItems] = useState([]);

  const [displayShipmentItems, setDisplayShipmentItems] = useState(null);
  const [displayDetail, setDisplayDetail] = useState(null);
  const [doReload, setDoReload] = useState(null)

  useEffect(() => {
    loadItems(false);
  }, [display?.orderId, doReload]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadItems = (reload?: boolean) => {
    if (display?.orderId) {
      PurchasesOrdersService.getShipments(display?.orderId).then(data => {
        setItems(data.listData);
      });
      reload !== false && reloadLazyData();
    }
  }
  const doDelete = () => {
    ConfirmDialogService.confirm('Hủy phiếu nhập', `Xác nhận hủy phiếu nhập này?`, () => {
      PurchasesOrdersService.cancelShipment(workEffortId).then(data => {
        setDoReload(Date.now())
      })
    })
  }
  const {render: renderIssue, update: issue} = useDialogCrup({
    header: '!Nhập hàng',
    width: '40rem',
    fields: [
      {field: 'receivedDate', header: 'Ngày nhập', InputTextProps: {type: 'date'}, required: true, className: 'md:col-12'},
    ],
    updateItem: item => {
      return PurchasesOrdersService.receiveEntireShipments(workEffortId, item).then(() => {
        setDoReload(Date.now())
      });
    }
  });
  const {renderDialogCrup, doCreate} = useCrup({Service, type, orderId: display?.orderId, loadItems, setDoReload});

  const content = (
    <div className="flex justify-content-between flex-wrap" style={{width: '100%'}}>
      <div className="flex align-items-center">
        <i className={'pi pi-check-square'}></i>
        <b>{items.length === 0 && <div className="ml-2">Đơn hàng chưa có phiếu nhập</div>}</b>
        <b>{items.length > 0 && <div className="ml-2">Đơn hàng hiện có {items?.length} phiếu nhập</div>}</b>
      </div>
      <Button severity={'success'} className={'ml-5'} size={'small'} style={{width: '150px'}} label={'Thêm phiếu nhập'} visible={display?.disabled !== true} onClick={() => doCreate()}/>
    </div>
  );
  const creatingHeader = (
    <div className="col-12 flex">
      <Message
        style={{
          border: 'solid #cc8925',
          borderWidth: '0 0 0 10px',
          color: '#696cff'
        }}
        className="border-primary w-full justify-content-start"
        severity="info"
        content={content}
      />
    </div>)

  return (
    <div className="grid mt-2">
      {items.length === 0 && creatingHeader}
      {items.length > 0 && creatingHeader}
      {items.length > 0 && items.map(item => {
        const headerTemplate = (options) => {
          const className = `${options.className} justify-content-space-between`;
          return (
            <div className={className}>
              <div className="flex align-items-center gap-2">
                <span className="font-bold">Phiếu nhập {item.workEffortName}</span>
              </div>
              <div>
                <Menu model={menuItems} popup ref={configMenu} id="config_menu"/>
                <button className="p-panel-header-icon p-link mr-2" onClick={(e) => {
                  configMenu?.current?.toggle(e);
                  setWorkEffortId(item?.workEffortId)
                }}>
                  <span className="pi pi-cog"></span>
                </button>
                {options.togglerElement}
              </div>
            </div>
          );
        };
        return (
          <Panel headerTemplate={headerTemplate} toggleable={true} className={'col-12'}>
            <SaleShipmentItems data={item}/>
          </Panel>)
      })}
      {renderIssue()}
      {renderDialogCrup()}
      <OrderShipmentItems type={type} label={{header: 'nhập', facility: 'giao'}} display={displayShipmentItems} setDisplay={setDisplayShipmentItems}/>
      <PurchaseShipmentDetail display={displayDetail} setDisplay={setDisplayDetail} setDoReload={setDoReload}/>
    </div>
  );
}
const useCrup = ({Service, type, orderId, loadItems, setDoReload}) => {

  const header = `Phiếu nhập`;
  const dataKey = 'dataKey';

  const [awaitingItems, setAwaitingItems] = useState([]);
  const [workEffortName, setWorkEffortName] = useState('')
  const [facilities, setFacilities] = useState([]);

  const loadAwaitingItems = () => {
    if (orderId) {
      return PurchasesOrdersService.getAwaitingItems(orderId).then(data => {
        data.listData.forEach(item => {
          item[dataKey] = `${item.orderId}_${item.orderPartSeqId}_${item.orderItemSeqId}`;
          item.vendorNameWithPseudoId = `[${item.vendorPseudoId}] - ${item.vendorName}`;
          item.productNameWithPseudoId = `[${item.pseudoId}] - ${item.itemDescription}`;
          item.quantityToShip = item.quantityNotShipped
        })
        setAwaitingItems(data.listData);
        return data.listData;
      });
    } else {
      return Promise.resolve();
    }
  }

  const onEditorChange = (rowData, column, newValue) => {
    setAwaitingItems(prevState => prevState.map(item => {
      if (item[dataKey] === rowData[dataKey]) {
        return {
          ...item,
          [column.field]: newValue
        }
      }
      return item;
    }));
  }

  const {render: renderAwaitingItems} = useDataTableBasic({
    tableHeader: `${awaitingItems?.length} Sản phẩm`,
    dataKey,
    columns: [
      {field: 'vendorNameWithPseudoId', header: 'Nhà cung cấp', minWidth: 200, matchMode: 'contains', hidden: type === 'outgoing'},
      {field: 'productNameWithPseudoId', header: 'Sản phẩm', minWidth: 200, matchMode: 'contains'},
      {field: 'quantity', header: 'SL đặt hàng', width: 130, matchMode: 'equals', dataType: 'number'},
      {field: 'quantityShipped', header: `SL đã  nhập`, width: 130, matchMode: 'equals', dataType: 'number'},
      {
        field: 'quantityToShip', header: `Còn lại`, width: 140, matchMode: 'equals', dataType: 'editor',
        editorConfig: {field: {type: 'InputNumber', InputNumberProps: {min: 0}}, onEditorChange}
      }
    ],
    indexColumnWidth: 45,
    items: awaitingItems
  });

  const searchFacilities = event => {
    const _event: any = {
      filters: {
        statusId: {value: 'FacActive', matchMode: 'equals'},
        facilityName: {value: event.query, matchMode: 'contains'}
      }
    };
    FacilitiesService.getList(JSON.stringify(_event)).then(data => {
      setFacilities(data.listData.map(item => {
        return {value: item.facilityId, label: `[${item.pseudoId}] - ${item.facilityName}`};
      }));
    });
  }
  const getWorkEffortName = () => {
    PurchasesOrdersService.getPseudoId().then(data => {
      setWorkEffortName(data.workEffortName)
    })
  }


  const {render: renderDialogCrup, create, view, close, form} = useDialogCrup({
    header,
    width: '80rem',
    fields: [
      {
        field: 'workEffortName', header: `Mã phiếu nhập`, className: 'md:col-4', type: 'custom', body:
          <div>
            <div>
              Mã phiếu nhập<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={workEffortName} onChange={e => setWorkEffortName(e.target.value)}/>
              <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getWorkEffortName}>
            <i className="pi pi-sync"/>
          </span>
            </div>
          </div>

      },
      {
        field: 'facility', header: `Kho giao`, required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: facilities, completeMethod: searchFacilities, field: 'label', dropdown: true, forceSelection: true},
        display: 'Create', className: 'md:col-4'
      },
      {field: 'estimatedCompletionDate', header: 'Ngày dự kiến', required: true, InputTextProps: {type: 'date'}, display: 'Create', className: 'md:col-4'},
      {type: 'custom', className: 'md:col-12 mt-1', body: renderAwaitingItems(), display: 'Create'},
      {
        type: 'custom', className: 'md:col-12 mt-1', body: <div className="p-message p-component p-message-info p-message-enter-done">
          <div className="p-message-wrapper">
            <span className="p-message-icon pi pi-check-square"></span>
            <span className="p-message-detail">Đã tạo phiếu nhập cho toàn bộ sản phẩm trong đơn hàng.</span>
          </div>
        </div>, display: 'View'
      }
    ],
    createItem: item => {
      item.workEffortName = workEffortName
      item.facilityId = item.facility.value;
      delete item.facility;
      item.items = awaitingItems.filter(item => item.quantityToShip > 0).map(item => {
        return {
          orderItemSeqId: item.orderItemSeqId,
          quantityToShip: item.quantityToShip
        }
      });
      if (item.items?.length) {
        return PurchasesOrdersService.createShipment(orderId, item).then(() => {
          setDoReload(Date.now())
        });
      } else {
        ToastService.error(`Chưa có sản phẩm cần nhập`);
        return Promise.reject();
      }
    },
    reloadLazyData: loadItems,
    useSaveSplitButton: false
  });
  const doCreate = () => {
    loadAwaitingItems().then(async items => {
      if (items?.length) {
        await PurchasesOrdersService.getPseudoId().then(res => {
          setWorkEffortName(res.workEffortName)
          create({
            estimatedCompletionDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'),
          });
        })
      } else {
        view({});
      }
    });
  }

  return {renderDialogCrup, doCreate};
}
const SaleShipmentItems = ({data}) => {

  useEffect(() => {
    if (data?.workEffortId) {
      if (data.estimatedCompletionDate) {
        data.estimatedCompletionDate = FormatDisplay.date(new Date(data.estimatedCompletionDate), 'YYYY-MM-DD')
      }
      infoForm.setValue(data)
    }
  }, [data])
  const infoForm = useForm({
    fields: [
      {field: 'workEffortName', header: 'Mã phiếu', className: 'md:col-3'},
      {field: 'facilityName', header: 'Kho nhập', className: 'md:col-3'},
      {field: 'estimatedCompletionDate', header: 'Dự kiến giao', InputTextProps: {type: 'date'}, className: 'md:col-2'},
      {field: 'grandTotal', header: 'Giá trị', type: 'InputNumber', className: 'md:col-2'},
      {field: 'status', header: 'Trạng thái', className: 'md:col-2', disabled: true},
    ],
    readOnly: true,
  });
  const {render: renderDataTable} = useDataTableBasic({
    tableHeader: 'Danh sách sản phẩm',
    columns: [
      {field: 'pseudoId', header: 'Mã sản phẩm', minWidth: 100},
      {field: 'itemDescription', header: 'Tên sản phẩm', minWidth: 300},
      {field: 'quantityUom', header: 'ĐVT', minWidth: 75},
      {field: 'quantity', header: 'Số lượng', minWidth: 100, dataType: 'number'},
      {field: 'quantityAccepted', header: 'Đã nhập', minWidth: 75, dataType: 'number'},
      {field: 'quantityOnHandTotal', header: 'Tồn hiện tại', minWidth: 100, dataType: 'number'},
    ],
    indexColumnWidth: 45,
    items: data.items
  });
  return (
    <div className="col-12 pb-0 m-0">
      <div className={'col-12'}>
        {infoForm.render()}
      </div>

      <div className={'col-12'}>
        {renderDataTable()}
      </div>
    </div>)

}
const PurchaseShipmentDetail = ({display, setDisplay, setDoReload}) => {
  const [data, setData] = useState(null)
  const [items, setItems] = useState([])
  const [facilities, setFacilities] = useState([]);
  const dataKey = 'dataKey';

  useEffect(() => {
    if (display) {
      PurchasesOrdersService.getShipmentsDetail(display).then(res => {
        if (res.entryDate) {
          res.entryDate = FormatDisplay.date(new Date(res.entryDate), 'YYYY-MM-DD')
        }
        res.items.forEach((item, index) => {
          item[dataKey] = `${item.orderId}_${index}_${item.orderItemSeqId}`;
        })
        if (res.facilityId) {
          res.facility = {value: res.facilityId, label: `[${res.facilityPseudoId}] - ${res.facilityName}`};
        }
        setData(res)
        setItems(res.items)
        infoForm.setValue(res)
      })
    }
  }, [display])

  const searchFacilities = event => {
    const _event: any = {
      filters: {
        statusId: {value: 'FacActive', matchMode: 'equals'},
        facilityName: {value: event.query, matchMode: 'contains'}
      }
    };
    FacilitiesService.getList(JSON.stringify(_event)).then(data => {
      setFacilities(data.listData.map(item => {
        return {value: item.facilityId, label: `[${item.pseudoId}] - ${item.facilityName}`};
      }));
    });
  }

  const infoForm = useForm({
    fields: [
      {field: 'workEffortName', header: 'Mã phiếu', className: 'md:col-3'},
      {
        field: 'facility', header: 'Kho nhập', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: facilities, completeMethod: searchFacilities, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-4'
      },
      {field: 'entryDate', header: 'Dự kiến giao', InputTextProps: {type: 'date'}, className: 'md:col-2'},
      {field: 'status', header: 'Trạng thái', className: 'md:col-2', disabled: true},
    ],
  });


  const onEditorChange = (rowData, column, newValue) => {
    setItems(prevState => prevState.map(item => {
      if (item[dataKey] === rowData[dataKey]) {
        return {
          ...item,
          [column.field]: newValue
        }
      }
      return item;
    }));
  }
  const {render: renderDataTable} = useDataTableBasic({
    tableHeader: 'Danh sách sản phẩm',
    columns: [
      {field: 'pseudoId', header: 'Mã sản phẩm', minWidth: 100},
      {field: 'itemDescription', header: 'Tên sản phẩm', minWidth: 300},
      {field: 'quantityUom', header: 'ĐVT', minWidth: 75},
      {
        field: 'quantity', header: 'Số lượng', minWidth: 100, dataType: 'editor',
        editorConfig: {field: {type: 'InputNumber', InputNumberProps: {min: 0}}, onEditorChange}
      },
      {field: 'quantityAccepted', header: 'Đã nhập', minWidth: 75, dataType: 'number'},
      {field: 'quantityOnHandTotal', header: 'Tồn hiện tại', minWidth: 100, dataType: 'number'},
    ],
    indexColumnWidth: 45,
    items: items
  });
  const onSubmit = () => {
    const info = infoForm.getValue();
    info.facilityId = info.facility.value;
    info.items = items
    if (infoForm.valid()) {
      PurchasesOrdersService.updateShipmentsDetail(display, info).then(res => {
        setDisplay(null)
        setDoReload(Date.now())
      })
    }
  }
  const footer = (
    <div className="flex justify-content-end">
      <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );
  return (
    <Dialog header={`Phiếu nhập ${data?.workEffortName}`} footer={footer} visible={!!display} style={{width: '80rem'}} position="top" onHide={() => setDisplay(false)}>
      {infoForm.render()}
      {renderDataTable()}
    </Dialog>
  )

}