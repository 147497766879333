import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Fieldset} from 'primereact/fieldset';
import {
  ConfirmDialogService,
  ToastService,
  useDataTable,
  useDialogCrup,
  useForm,
  useToolbar
} from '@iamsoftware/react-hooks';

import {OutgoingShipmentsService as Service} from './../xuat-kho/OutgoingShipmentsService';
import {CustomersService} from "../../ban-hang/khach-hang/CustomersService";
import {usePG} from "../gop-phieu-xuat/CrupPG";
import {ImportExcel} from "../../../shared/components/ImportExcel";
import {CrupPGItems} from "./CrupPGItems";

export const MergeShipments = () => {
  const header = 'Phiếu gộp';
  const dataKey = 'workEffortId';
  const [display, setDisplay] = useState(null);
  const [customerIds, setCustomerIds] = useState(null);
  const [workEffortId, setWorkEffortId] = useState(null);
  const [turnCreate, setTurnCreate] = useState(null);
  const [method, setMethod] = useState(null)
  const [headerResult, setHeaderResult] = useState(null);

  useEffect(() => {
    CustomersService.listCus().then(({listData}) => {
      setCustomerIds(listData.map(item => {
        return {value: item.partyId, label: item.pseudoId + ' - ' + item.partyName}
      }))
    })
    setHeaderResult(tabHeader)

  }, []);

  useEffect(() => {
    setDisplayDialog(display)
  }, [display])


  const {render: renderDataTable, selectedItems, setSelectedItems, reloadLazyData} = useDataTable({
    tableHeader: headerResult,
    dataKey,
    columns: [
      {
        field: 'workEffortName',
        header: 'Mã phiếu gộp',
        minWidth: 100,
        matchMode: 'contains',
        dataType: 'custom',
        customCell(rowData: any): any {
          return <a onClick={() => Service.totalDetail(rowData.workEffortId).then(data => {
            setWorkEffortId(data.workEffortId)
            setShipmentChild(data.shipmentChild);
            if (data.shipmentChild) {
              const sum = data.shipmentChild.map(item => {
                return item.amountTotal
              })
              data.total = parseInt(sum.reduce((a, v) => a = a + v, 0))
            }
            update(data);
            setSelectedItems(rowData)
          })}>{rowData.workEffortName}</a>
        }
      },
      {field: 'entryDate', header: 'Ngày tạo', width: 140, matchMode: 'contains', dataType: 'date'},
      {field: 'originShipment', header: 'Mã phiếu xuất', minWidth: 150, matchMode: 'contains',},
      {field: 'prodTotal', header: 'Tổng sản phẩm', width: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'grandTotal', header: 'Tổng tiền', width: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'description', header: 'Ghi chú', minWidth: 200, matchMode: 'contains',}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 120,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Xem', className: 'p-button-info', command: () => doUpdate(true, item)},
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(false, item)},
            {icon: 'pi pi-times', title: 'Hủy', className: 'p-button-danger', command: () => doCancel(item)},

          ]
        })

      }
    },
    getList: lazyLoadEvent => {
      return Service.totalList(lazyLoadEvent);
    }

  });


  const {renderShipment, setShipmentChild, setDisplayDialog, shipmentChild, setCustomerId, setProdItems} = usePG({workEffortId, method})


  const {render: renderDialogCrup, create, update, view, form, formMethod} = useDialogCrup({
    header: 'phiếu gộp',
    dataKey,
    width: '90rem',
    fields: [
      {field: 'workEffortName', header: 'Mã phiếu gộp', className: 'md:col-4'},
      {
        field: 'customerPartyId', required: true,
        header: 'Khách hàng',
        className: 'md:col-8',
        type: 'Dropdown',
        DropdownProps: {options: customerIds},
        displayDependency(item: any): boolean {
          return !item.partyId;
        },
      },
      {field: 'total', header: 'Tổng tiền', className: 'md:col-4', disabled: true, type: 'InputNumber'},
      {field: 'description', header: 'Ghi chú', className: 'md:col-8'},
      {type: 'custom', body: renderShipment()},
    ],
    createItem: item => {

      item.workEffortIds = shipmentChild.map(item => {
        return item.workEffortId
      })
      return Service.total(item)
    },
    updateItem: (id, item) => {
      item.workEffortIds = shipmentChild.map(item => {
        return item.workEffortId
      })
      return Service.totalUpdate(id, item)
    },
    reloadLazyData
  })

  useEffect(() => {
    setMethod(formMethod)
  }, [formMethod]);

  const {customerPartyId} = form.getValue()
  useEffect(() => {
    if (customerPartyId && workEffortId) {
      setShipmentChild([])
      Service.totalDetail(workEffortId).then(data => {
        if (data.customerPartyId === customerPartyId && selectedItems !== null) {
          setShipmentChild(data.shipmentChild)
        } else {
          setShipmentChild([])
        }
      })
    }
  }, [customerPartyId])

  useEffect(() => {
    if (shipmentChild) {
      const sum = shipmentChild.map(item => {
        return item.amountTotal
      })
      form.setValue({
        customerPartyId: form.getValue().customerPartyId,
        description: form.getValue().description,
        total: parseInt(sum.reduce((a, v) => a = a + v, 0)),
        workEffortName: form.getValue().workEffortName
      })
    }
  }, [shipmentChild.length, display])

  useEffect(() => {
    setCustomerId([]);
    setCustomerId(form.getValue().customerPartyId)
  }, [form.getValue().customerPartyId]);


  const doCreate = () => {
    setTurnCreate(Date.now());
    setSelectedItems(null);
    setProdItems([]);
    Service.shipName().then(name => {
      form.setValue(name)
    })
    create();
    setShipmentChild([])
  }
  useEffect(() => {
    if (customerPartyId && turnCreate !== null) {
      const param = [{
        customerPartyId: form.getValue().customerPartyId,
        shipmentChild: []
      }]
      Service.getNotIn(JSON.stringify(param)).then(item => {
        setShipmentChild(item.listData)
      })
    }
  }, [customerPartyId])

  const doUpdate = (doView: boolean, rowData) => {
    setWorkEffortId(rowData.workEffortId)
    setTurnCreate(null)
    Service.totalDetail(rowData[dataKey]).then(data => {
      setWorkEffortId(data.workEffortId)
      setShipmentChild(data.shipmentChild)
      if (doView === true) {
        view(data);
        setDisplay(Date.now());
      } else {
        update(data);
        setDisplay(Date.now());
      }
    });
  }

  const doCancel = (rowData) => {
    ConfirmDialogService.confirmCancel(() => {
      Service.totalDelete(rowData[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });
    });

  }
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{header}</span>
          <div>
            <Button label={'Tạo mới'} icon="pi pi-plus" style={{height: '25px'}} size={'small'} severity={'success'} className={'mr-2'} onClick={() => doCreate()}></Button>
          </div>
        </Fragment>
      </div>
    </div>
  )
  return (<div className="grid">
    <div className="col-12">
      {renderDataTable()}
    </div>
    {renderDialogCrup()}
  </div>)
}