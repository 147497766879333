import {Fragment, useRef, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ToastService, useDataTableBasic} from '@iamsoftware/react-hooks';

export const ImportExcel = ({column, downloadTemplateUrl, uploadExcel}) => {

  const inputExcel = useRef(null);

  const [displayImport, setDisplayImport] = useState(false);
  const [displayResults, setDisplayResults] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [contentFile, setContentFile] = useState(null);

  const [results, setResults] = useState([]);

  const {render: renderResults} = useDataTableBasic({
    columns: [
      {field: 'success', header: 'Import thành công', width: 180, matchMode: 'equals', filterType: 'boolean-char', dataType: 'boolean'},
      {field: 'orderSequence', header: 'STT', width: 120, matchMode: 'equals', dataType: 'number'},
      {...column, minWidth: 140, matchMode: 'contains'},
      {field: 'rowNum', header: 'Dòng lỗi', width: 140, matchMode: 'equals', dataType: 'number'},
      {field: 'error', header: 'Lỗi', minWidth: 140, matchMode: 'contains'}
    ],
    items: results
  });

  const onUploadExcel = e => {
    if (e.target.files && e.target.files.length === 1) {
      setContentFile(e.target.files[0]);
    }
  }

  const onImport = () => {
    if (!submitting && contentFile) {
      setSubmitting(true);

      uploadExcel(contentFile).then(data => {
        setResults(data.resultList);
        setDisplayResults(true);
        inputExcel.current.value = '';
      }).finally(() => setSubmitting(false));
    } else {
      ToastService.error('Chưa chọn File upload.');
    }
  }

  const downloadTemplate = () => {
    window.open(downloadTemplateUrl);
  }

  const footerImport = (
    <div>
      <Button label="Tải File mẫu" icon="pi pi-download" severity="success" size="small" onClick={downloadTemplate}/>
      <Button label="Import" icon="pi pi-file-import" size="small" onClick={onImport} loading={submitting}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplayImport(false)}/>
    </div>
  );

  const footerResult = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplayResults(false)}/>
    </div>
  );

  return (
    <Fragment>
      <Button label="Import Excel" icon="pi pi-file-import" style={{height:'25px'}} size="small" onClick={() => setDisplayImport(true)}/>

      <Dialog header="Import Excel" footer={footerImport} visible={displayImport} style={{width: '40rem'}} position="top" onHide={() => setDisplayImport(false)}>
        <div className="p-fluid formgrid grid">
          <div className="field col-12 md:col-12">
            <input ref={inputExcel} type="file" accept=".xlsx, .xls" onChange={onUploadExcel}/>
          </div>
        </div>
      </Dialog>

      <Dialog header="Kết quả" footer={footerResult} visible={displayResults} style={{width: '90rem'}} position="top" onHide={() => setDisplayResults(false)}>
        {renderResults()}
      </Dialog>
    </Fragment>
  );
}