import {Fragment} from 'react';

import {Button} from 'primereact/button';
import {useDataTable, useForm, useToolbar} from '@iamsoftware/react-hooks';

import {ReportPlanService as Service} from './reportServices';

export default function BaoCaoSl() {

  const header = 'Báo cáo tiến độ mua hàng theo PO';

  // const [items, setItems] = useState([]);
  //
  // const reloadData = () => {
  //     setItems([
  //         {desc: 'safhasf', notShippedCount: 666,notShippedSum:10000,domesticCount:12,domesticSum:100000,
  //             estimatedDeliveryDate: '23/8/2023', foreignCount:4,foreignSum:90000, estimatedShipDate: '24/8/2023', allCount:123,allSum:23498,Note:'abc123'},
  //         {desc: 'saf0ihnas', notShippedCount: 9000}
  //     ]);
  // }

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    columns: [
      {field: 'content', header: 'Nội dung', minWidth: 200, matchMode: 'contains'},
      {field: 'notShippedCount', header: 'Tổng mã hàng chưa giao', minWidth: 100, matchMode: 'contains'},
      {field: 'notShippedSum', header: 'Giá trị', minWidth: 100, matchMode: 'contains'},
      {field: 'domesticCount', header: 'Tổng mã hàng mua trong nước', minWidth: 100, matchMode: 'contains'},
      {field: 'domesticSum', header: 'Giá trị', minWidth: 100, matchMode: 'contains'},
      {field: 'estimatedDeliveryDate', header: 'Dự kiến hàng về', minWidth: 100, matchMode: 'contains'},
      {field: 'foreignCount', header: 'Tổng số mã hàng nhập khẩu', minWidth: 100, matchMode: 'contains'},
      {field: 'foreignSum', header: 'Giá trị', minWidth: 100, matchMode: 'contains'},
      {field: 'estimatedShipDate', header: 'Ngày giao hàng', minWidth: 100, matchMode: 'contains'},
      {field: 'allCount', header: 'Tổng số mã hàng', minWidth: 100, matchMode: 'contains'},
      {field: 'allSum', header: 'Giá trị', minWidth: 100, matchMode: 'contains'},
      {field: 'Note', header: 'Ghi chú', minWidth: 100, matchMode: 'contains'},
    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent, getValue());
    },
  });

  const downloadExcel = () => {
    window.open(`${Service.baseURL}/`);
  }

  const {renderToolbar} = useToolbar({
    leftButtons:
      <Fragment>
        <Button label="Xuất excel" icon="pi pi-file-excel" className="p-button-help" onClick={downloadExcel}/>
      </Fragment>
  });
  const {render: renderForm, getValue} = useForm({
    fields: [
      {field: 'year', header: 'Năm', className: 'md:col-3', labelClassName: 'md:col-4', inputClassName: 'md:col-8'},
      {
        type: 'custom', body: <Button icon="pi pi-search" className="p-button-rounded p-button-success" onClick={reloadLazyData}/>, className: 'md:col-1'
      }
    ],
    layout: 'Horizontal'
  });

  return (
    <div className="grid">
      <div className="col-10">
        {renderForm()}
      </div>
      <div className="col-2">
        {renderToolbar()}
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 18rem)'}}>
        {renderDataTable()}
      </div>

    </div>
  )
}