import {EntityService} from '../../../service/EntityService';

export class InventoriesService extends EntityService {

  static entity = 'iam-logistics/assets';

  static getList(lazyLoadEvent: string, params?: any): Promise<any> {
    return this.axios.get(`${this.entity}/list?lazyLoadEvent=${lazyLoadEvent}&productIds=${params}`).then(res => res.data);
  }

  static receiptHistory(id:string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/receipt-history`).then(res => res.data);
  }

  static issuanceHistory(id:string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/issuance-history`).then(res => res.data);
  }
}