import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from "primereact/inputtext";
import {Fieldset} from 'primereact/fieldset';

import {FormatDisplay, ToastService, useForm, validateTaxCode} from '@iamsoftware/react-hooks';
import {useFacilities} from './Facilities';
import {PartyService} from '../../../service/PartyService';
import {CustomersService as Service} from './CustomersService';

export const useCrupCustomer = ({reloadLazyData}) => {
  const dataKey = 'partyId';

  const [crupMethod, setCrupMethod] = useState(null);
  const [partyId, setPartyId] = useState(null);
  const [displayContent, setDisplayContent] = useState(false);
  const [display, setDisplay] = useState(null)
  const [readOnly, setReadOnly] = useState(false)
  const [customerPseudoId, setCustomerPseudoId] = useState('');

  const {renderFacilities, getFacilities, setCustomerFacs, deleteFacs, setDeleteFacs} = useFacilities({readOnly: crupMethod === 'View'}, partyId, displayContent);
  const getByTaxCode = () => {
    const _value = commonInfoForm.getValue();
    if (customerPseudoId) {
      PartyService.getByTaxCode(customerPseudoId).then(({data}) => {
        if (data?.partyName) {
          _value.partyName = data.partyName;
          _value.address1 = data.address1;
          commonInfoForm.setValue(_value);
        }
      });
    } else {
      ToastService.error('Mã số thuế không hợp lệ');
    }
  }
  const commonInfoForm = useForm({
    fields: [
      {
        field: 'pseudoId', type: 'custom', className: 'md:col-3', body: <div>
          <div>
            <div>
              Mã khách hàng<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={customerPseudoId} onChange={e => setCustomerPseudoId(e.target.value)}/>
              {crupMethod !== 'View' && <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getByTaxCode}>
            <i className="pi pi-search"/>
          </span>}
            </div>
          </div>
        </div>
      },
      {field: 'partyName', header: 'Tên khách hàng', required: true, className: 'md:col-9'},
      {field: 'phoneNumber', header: 'Số điện thoại', className: 'md:col-3'},
      {field: 'address1', header: 'Địa chỉ', required: true, className: 'md:col-9'},
    ],
    readOnly
  });
  const contactInfoForm = useForm({
    fields: [
      {field: 'partyName', header: 'Tên người liên hệ', className: 'md:col-3'},
      {field: 'emailAddress', header: 'Email', className: 'md:col-3'},
      {field: 'phoneNumber', header: 'Số điện thoại người liên hệ', className: 'md:col-3'},
      {field: 'jobTitle', header: 'Chức vụ', className: 'md:col-3'},
      {field: 'partyId', type: 'hidden'}
    ],
    readOnly
  })
  const doCreate = () => {
    contactInfoForm.reset();
    commonInfoForm.reset();
    setCustomerFacs([])
    setCustomerPseudoId('')
    setDisplay(Date.now())
  }
  const doUpdate = (doView: boolean, rowData) => {
    Service.get(rowData[dataKey]).then(data => {
      setCustomerPseudoId(data.pseudoId)
      setPartyId(data.partyId)
      commonInfoForm.setValue(data);
      if (data.contactParties.length > 0) {
        contactInfoForm.setValue(data.contactParties[0])
      } else {
        contactInfoForm.setValue([])
      }
      setCustomerFacs(data.customerFacs)
      if (doView === true) {
        setReadOnly(true)
        // view(data);
      } else {
        setReadOnly(false)
        // update(data);
      }
    });

  }
  const onSubmit = () => {
    const item = Object.assign(commonInfoForm.getValue(), contactInfoForm.getValue())
    if (deleteFacs) {
      item.facilityIds = deleteFacs.map(item => {
        return item.facilityId
      })
      Service.deleteFacilities(partyId, item);
      delete item.facilityIds;
    }
    setDeleteFacs([])
    item.customerFacs = getFacilities();
    item.contactParties = [contactInfoForm.getValue()]
    Service.update(partyId, item).then(() => {
      reloadLazyData()
    });
  }
  const footer = (
    <div className="flex justify-content-end">
      {<Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  const render = () => {
    return (
      <Dialog header="Khách hàng" footer={footer} visible={!!display} style={{width: '90rem'}} onHide={() => setDisplay(null)}>
        <Fieldset className={'mb-3'} legend="Thông tin chung">
          {commonInfoForm.render()}
        </Fieldset>
        <Fieldset className={'mb-3'} legend="Thông tin liên hệ">
          {contactInfoForm.render()}
        </Fieldset>
        {renderFacilities()}
      </Dialog>
    );
  }

  return {renderCustomer: render, doCreateCustomer: doCreate, doUpdateCustomer: doUpdate};
}