import {EntityService} from '../../../service/EntityService';

export class OutgoingShipmentsService extends EntityService {

    static entity = 'iam-logistics/outgoing-shipment';

    static getItems(workEffortId: string): Promise<any> {
        return this.axios.get(`${this.entity}/${workEffortId}/items/list`).then(res => res.data);
    }


    static getNotIn(params: any): Promise<any> {
        return this.axios.get(`${this.entity}/not-in?params=${params}` ).then(res => res.data);

    }

    static updateItems(workEffortId: string, data: any): Promise<any> {
        return this.axios.put(`${this.entity}/${workEffortId}/items/update`, {listData: data}).then(res => res.data);
    }

    static issue(workEffortId: string): Promise<any> {
        return this.axios.put(`${this.entity}/${workEffortId}/issue`).then(res => res.data);
    }

    static cancel(workEffortId: string): Promise<any> {
        return this.axios.put(`${this.entity}/${workEffortId}/cancel`).then(res => res.data);
    }

    static totalUpdate(workEffortId: string, data): Promise<any> {
        return this.axios.put(`${this.entity}/${workEffortId}/total/update`,data).then(res => res.data);
    }

    static total(data: any): Promise<any> {
        return this.axios.post(`${this.entity}/total`, data).then(res => res.data)
    }

    static totalList(lazyLoadEvent:string): Promise<any> {
        return this.axios.get(`${this.entity}/total-list?lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data)
    }


    static totalDetail(workEffortId: string): Promise<any> {
        return this.axios.get(`${this.entity}/${workEffortId}/total/detail`).then(res => res.data)
    }

    static totalDetailSyn(workEffortId: string): Promise<any> {
        return this.axios.get(`${this.entity}/${workEffortId}/total/syn`).then(res => res.data)
    }

    static totalDelete(workEffortId: string): Promise<any> {
        return this.axios.put(`${this.entity}/${workEffortId}/total/delete`).then(res => res.data)
    }
    static totalItems(workEffortId: string): Promise<any> {
        return this.axios.get(`${this.entity}/${workEffortId}/total/items`).then(res => res.data)
    }

    static totalAdd(workEffortId: string, data: any): Promise<any> {
        return this.axios.put(`${this.entity}/${workEffortId}/total/add`, data).then(res => res.data)
    }

    static totalDeleteItem(workEffortId: string, data: any): Promise<any> {
        return this.axios.put(`${this.entity}/${workEffortId}/total/delete-item`,data).then(res => res.data)
    }

    static pdfDetail(workEffortId: string): Promise<any> {
        return this.axios.get(`${this.entity}/${workEffortId}/total/pdf-detail`).then(res => res.data)
    }

    static pdfSyn(workEffortId: string): Promise<any> {
        return this.axios.get(`${this.entity}/${workEffortId}/total/pdf-syn`).then(res => res.data)
    }

    static shipName(): Promise<any> {
        return this.axios.get(`${this.entity}/get-name`).then(res => res.data)
    }
    static prodByList(data:any): Promise<any> {
        return this.axios.get(`${this.entity}/prod-by-list?params=${data}`).then(res => res.data)
    }

}