import {EntityService} from '../../../service/EntityService';

export class PaymentService extends EntityService {

  static entity = 'iam-payment/agreements';

  static getWorkEffortList(): Promise<any> {
    return this.axios.get(`${this.entity}/work-effort-addable`).then(res => res.data);
  }

  static getWorkEffortInfo(workEffortId: string): Promise<any> {
    return this.axios.get(`${this.entity}/incoming-payment-info?workEffortId=${workEffortId}`).then(res => res.data);
  }

  static getOrgBankList(partyId: string): Promise<any> {
    return this.axios.get(`iam-payment/bank-accounts?partyId=${partyId}`).then(res => res.data);
  }

  static getInvoiceContent(agreementId: string, data: any): Promise<any> {
    return this.axios.get(`${this.entity}/${agreementId}/download-attachment`, data).then(res => res.data);
  }

  static createOrgBankList(data: any): Promise<any> {
    return this.axios.post(`iam-payment/bank-accounts`, data).then(res => res.data);
  }

  static createPayment(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/create`, data).then(res => res.data);
  }

  static updatePayment(agreementId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${agreementId}/update`, data).then(res => res.data);
  }

  static getAddableItem(agreementId: string, excludes: Array<string>): Promise<any> {
    return this.axios.get(`${this.entity}/${agreementId}/addable-items?excludeProductIds=${excludes}`).then(res => res.data);
  }

  static getAddableTemplate(agreementId: string, excludes: Array<string>): Promise<any> {
    return this.axios.get(`${this.entity}/${agreementId}/addable-template?excludeTemplateIds=${excludes}`,).then(res => res.data);
  }


}