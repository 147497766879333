import {Fragment, useEffect, useLayoutEffect, useState} from 'react';
import {ConfirmDialogService, FormatDisplay, ToastService, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {PaymentPurchaseServices as Service} from './PaymentPurchaseServices';
import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {useParams} from 'react-router-dom';
import {useSearchParams} from 'react-router-dom';


export default function PurchasePayment() {
  const header = "Danh sách thanh toán";
  const dataKey = "paymentId"
  const [orders, setOrders] = useState(null)
  const [vendorPartyIds, setVendorPartyIds] = useState(null)
  const [bankAccounts, setBankAccounts] = useState(null)
  const [checkPaymentDue, setCheckPaymentDue] = useState(false)
  const [checkPaymentIncome, setCheckPaymentIncome] = useState(false)
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id')

  useEffect(() => {
    reloadLazyData()
  }, [checkPaymentIncome, checkPaymentDue]);

  useEffect(() => {
    if (id === 'due') {
      setCheckPaymentDue(true)
    } else if (id === 'income') {
      setCheckPaymentIncome(true)
    }
  }, [id]);

  const headerTable = (
    <div className="flex justify-content-between align-items-center">
      <span>{header}</span>
      <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 h-2rem"
              size="small" onClick={() => doCreate()}></Button>
    </div>
  )

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: headerTable,
    dataKey,
    indexColumnWidth: 45,
    columns: [
      {
        field: 'paymentId', header: 'Mã phiếu', minWidth: 100, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <span onDoubleClick={() => doUpdate(rowData, true)} style={{color: 'blue'}}>{rowData.paymentId}</span>
        }
      },
      {field: 'dueDate', header: 'Hạn thanh toán', minWidth: 150, matchMode: 'contains', dataType: 'date'},
      {field: 'settlementDate', header: 'Ngày thanh toán', minWidth: 150, matchMode: 'contains', dataType: 'date-time'},
      {field: 'amount', header: 'Số tiền', minWidth: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'orderDisplayId', header: 'Mã đơn hàng', minWidth: 150, matchMode: 'contains'},
      {
        field: 'ncc', header: 'Nhà cung cấp', minWidth: 150, dataType: 'custom', customCell(rowData: any): any {
          return <span>{rowData.toPartyName} [{rowData.toPseudoId}]</span>
        }
      },
      {field: 'toAddress1', header: 'Địa chỉ', minWidth: 200, matchMode: 'contains'},
      {field: 'toContactNumber', header: 'SĐT', minWidth: 150, matchMode: 'contains'},
      {
        field: 'bank', header: 'STK', minWidth: 150, dataType: 'custom', customCell(rowData: any): any {
          return <span>{rowData.toBankName} - {rowData.toAccountNumber}</span>
        }
      },
      {field: 'memo', header: 'Diễn giải', minWidth: 150, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', minWidth: 150, matchMode: 'contains'},
    ],
    getList(lazyLoadEvent: any): Promise<any> {
      let params = {dueCheck: 'N', incomeCheck: 'N'};
      if (checkPaymentDue === true) {
        params.dueCheck = 'Y'
      }
      if (checkPaymentIncome === true) {
        params.incomeCheck = 'Y'
      }
      return Service.getList(lazyLoadEvent, params)
    },
    actionColumnWidth: 120,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = []
          if (item.statusId !== 'PmntDelivered') {
            item.actions.push({icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(item, false)},)
            item.actions.push({icon: 'pi pi-check', title: 'Hoàn thành', className: 'p-button-success', command: () => doDelivery(item)},)
          }
          item.actions.push({icon: 'pi pi-trash', title: 'Hủy', className: 'p-button-danger', command: () => doCancel(item)},)
        })
      }
    }
  })

  const {render: renderDialogCrup, create, update, view, form} = useDialogCrup({
    header: 'Thanh toán',
    dataKey,
    width: '80rem',
    fields: [
      {field: 'orderId', header: 'Mã đơn hàng', className: 'md:col-4', required: true, type: 'Dropdown', DropdownProps: {options: orders}},
      {field: 'vendorPartyId', header: 'Nhà cung cấp', className: 'md:col-4', required: true, type: 'Dropdown', DropdownProps: {options: vendorPartyIds}},
      {field: 'dueDate', header: 'Hạn thanh toán', className: 'md:col-4', InputTextProps: {type: 'date'},},
      {field: 'remindBeforeDueDate', header: 'Nhắc trước hạn', className: 'md:col-4', type: 'InputNumber'},
      {field: 'amount', header: 'Số tiền', className: 'md:col-4', type: 'InputNumber'},
      {field: 'toPaymentMethodId', header: 'Số tài khoản', className: 'md:col-4', type: 'Dropdown', DropdownProps: {options: bankAccounts}},
      {field: 'memo', header: 'Diễn giải', className: 'md:col-12'},
    ],
    createItem(item: any): Promise<any> {
      return Service.create(item)
    },
    updateItem(id: string, item: any): Promise<any> {
      return Service.update(id, item)
    },
    reloadLazyData,
  })

  const formValue = form.getRawValue()

  const doCreate = () => {
    Service.getOrderAddable('', '').then(({listData}) => {
      setOrders(listData.map(item => {
        let amount = 0
        item.parts.map(part => {
          amount += part.partTotal
        })
        return {...item, value: item.orderId, label: item.displayId, amount: amount}
      }))
    })
    formValue.dueDate = FormatDisplay.date(new Date(), 'YYYY-MM-DD')
    form.setValue(formValue)
    create()
  }

  //Fill ncc
  useEffect(() => {
    if (formValue.orderId && orders) {
      orders.forEach(item => {
        if (item.orderId === formValue.orderId) {
          formValue.amount = item.amount
          form.setValue(formValue)
          setVendorPartyIds(item.parts.map(data => {
            return {value: data.vendorPartyId, label: `[${data.vendorPseudoId}] - ${data.vendorPartyName}`}
          }))
        }
      })
    }

  }, [formValue.orderId]);
  //Fill stk
  useEffect(() => {
    if (formValue.vendorPartyId) {
      Service.getBankAccounts(formValue.vendorPartyId).then(({listData}) => {
        setBankAccounts(listData.map(item => {
          return {value: item.paymentMethodId, label: `[${item.accountNumber}] - ${item.bankName}`}
        }))
      })
    }
  }, [formValue.vendorPartyId]);

  const doUpdate = (item: any, doView: boolean) => {
    if (item.paymentId) {
      Service.getOrderAddable(item.orderId, item.orderPartSeqId).then(({listData}) => {
        setOrders(listData.map(item => {
          return {...item, value: item.orderId, label: item.displayId}
        }))
      })
      Service.get(item.paymentId).then(data => {
        data.dueDate = FormatDisplay.date(new Date(data.dueDate), 'YYYY-MM-DD')
        data.vendorPartyId = data.toPartyId
        if (doView === true) {
          view(data)
        } else {
          update(data)
        }
      })
    }
  }

  const doCancel = (item) => {
    if (item.paymentId) {
      ConfirmDialogService.confirm('Hủy thanh toán', 'Xác nhận hủy thanh toán này?', () => {
        Service.doCancel(item.paymentId).then(() => {
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  const doDelivery = (item) => {
    if (item.paymentId) {
      ConfirmDialogService.confirm('Hoàn thành thanh toán', 'Xác nhận hành thành thanh toán này?', () => {
        Service.doDelivery(item.paymentId).then(() => {
          ToastService.success();
          reloadLazyData();
        })
      })
    }
  }

  //Chi check 1 checkbox
  const doPaymentDue = (checked: boolean) => {
    if (checked === true) {
      setCheckPaymentIncome(false)
      setCheckPaymentDue(checked)
    } else {
      setCheckPaymentDue(checked)
    }
  }
  const doPaymentIncome = (checked: boolean) => {
    if (checked === true) {
      setCheckPaymentIncome(checked)
      setCheckPaymentDue(false)
    } else {
      setCheckPaymentIncome(checked)
    }
  }
  //

  //Change checkbox by click label
  const doClickLabel = (type) => {
    if (type === 'due') {
      switch (checkPaymentDue) {
        case true:
          setCheckPaymentDue(false);
          break;
        case false:
          setCheckPaymentDue(true);
          setCheckPaymentIncome(false)
          break;
      }
    } else {
      switch (checkPaymentIncome) {
        case true:
          setCheckPaymentIncome(false);
          break;
        case false:
          setCheckPaymentIncome(true);
          setCheckPaymentDue(false)
          break;
      }
    }
  }

  return (
    <div className="grid">
      <div className="flex mb-3 ml-2 justify-content-between" style={{width: '370px'}}>
        <div className="align-items-center flex">
          <div>
            <Checkbox onChange={e => doPaymentDue(e.checked)} checked={checkPaymentDue}></Checkbox>
          </div>
          <label onClick={() => {
            doClickLabel('due')
          }} className="ml-2 mt-1"><strong>Quá hạn thanh toán</strong></label>
        </div>
        <div className="align-items-center flex">
          <div>
            <Checkbox onChange={e => doPaymentIncome(e.checked)} checked={checkPaymentIncome}></Checkbox>
          </div>
          <label onClick={() => {
            doClickLabel('income')
          }} className="ml-2 mt-1" style={{fontSize: '1rem'}}><strong>Sắp đến hạn thanh toán</strong></label>
        </div>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 17rem)'}}>
        {renderDataTable()}
      </div>
      {renderDialogCrup()}
    </div>
  )
}