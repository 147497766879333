import {Fragment, useEffect, useState} from 'react';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Paginator} from 'primereact/paginator';

import {InputNumber} from 'primereact/inputnumber';
import {Dialog} from 'primereact/dialog';
import {FormatDisplay, useDataTableBasic, useDialogCrup} from '@iamsoftware/react-hooks';
import {SalesOrdersService} from '../../ban-hang/don-hang-ban/SalesOrdersService';

export const CrupPOPartItems = ({part, onItemSelect, doCreate, doUpdate, doDelete}) => {
  const [items, setItems] = useState([])
  const [headerResult, setHeaderResult] = useState(null);
  const [displayProducts, setDisplayProducts] = useState(null)
  const dataKey = 'dataKey';
  useEffect(() => {
    setSelectedItems(null);
    setItems(part?.items)
    setHeaderResult(tabHeader)
  }, [part, part?.items]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (items?.length) {
      items.forEach((item, index) => {
        if (!item[dataKey]) {
          item[dataKey] = `${Date.now()}_${Math.random()}_${index}`;
        }
        if (!item.partyId) {
          item.partyId = part.vendorPartyId
        }
        item.actions = [
          {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(item)},
          {icon: 'pi pi-trash', title: 'Xóa', className: 'p-button-danger', command: () => doDelete(item)},
        ]
      });
    }
    setHeaderResult(tabHeader)
  }, [items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: headerResult,
    dataKey,
    indexColumnWidth: 40,
    columns: [
      {field: 'pseudoId', header: 'Mã sản phẩm', minWidth: 100},
      {field: 'productName', header: 'Tên sản phẩm', minWidth: 300},
      {field: 'quantityUom', header: 'ĐVT', minWidth: 75},
      {field: 'quantity', header: 'Số lượng', minWidth: 100, dataType: 'number'},
      {field: 'unitListPrice', header: 'Đơn giá', minWidth: 100, dataType: 'number'},
      {field: 'discountPercentage', header: 'Tỷ lệ CK', minWidth: 100, dataType: 'number'},
      {field: 'discountAmount', header: 'Số tiền CK', minWidth: 100, dataType: 'number'},
      {field: 'amountExcludeTax', header: 'Thành tiền', minWidth: 100, dataType: 'number'},
      {field: 'sourcePercentage', header: 'Thuế suất', minWidth: 100, dataType: 'number'},
      {field: 'amountAlreadyIncluded', header: 'Tiền thuế ', minWidth: 100, dataType: 'number'},
      {field: 'amountTotal', header: 'Tổng tiền', minWidth: 100, dataType: 'number'},
      {field: 'operatorName', header: 'NV phụ trách', minWidth: 150,},
    ],
    items: items,
    actionColumnWidth: 80
  });
  useEffect(() => {
    const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;
    onItemSelect(part, selectedItem);
  }, [selectedItems]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>Danh sách sản phẩm</span>
          <div>
            <Button label={'Tạo mới'} icon="pi pi-plus" style={{height: '25px'}} size={'small'} severity={'success'} className={'mr-2'} onClick={() => doCreate({partyId: part?.partyId})}></Button>
            <Button label={'Mặt hàng cần mua'} icon="pi pi-plus" style={{height: '25px'}} size={'small'} severity={'success'} className={'mr-2'} onClick={() => setDisplayProducts(items)}></Button>
          </div>
        </Fragment>
      </div>
    </div>
  )
  return (
    <div key={part?.partyId}>
      {render()}
      <PlanningItems display={displayProducts} setDisplay={setDisplayProducts} items={items} setItems={setItems}/>
    </div>
  );
}
const PlanningItems = ({display, setDisplay, items, setItems}) => {
  const [products, setProducts] = useState([])
  const [result, setResult] = useState([])

  useEffect(() => {
    if (display) {
      SalesOrdersService.productInfo({products: display}).then(res => {
        setProducts(res.listData);
      })
      setResult([])
    }
  }, [display])
  const onEditorChange = (rowData, column, newValue) => {
    setProducts(prevItems => prevItems.map(prevItem => {
        if (prevItem['productId'] === rowData['productId']) {
          if (column.field === 'quantity') {
            if (newValue && prevItem?.unitListPrice) {
              if (prevItem?.discountPercentage) {
                prevItem.discountAmount = newValue * prevItem?.unitListPrice * prevItem?.discountPercentage / 100
              } else {
                prevItem.discountAmount = 0
              }
              prevItem.amountExcludeTax = newValue * prevItem?.unitListPrice - prevItem?.discountAmount
              prevItem.amountAlreadyIncluded = prevItem?.amountExcludeTax * prevItem?.sourcePercentage / 100
              prevItem.amountTotal = prevItem?.amountExcludeTax + prevItem?.amountAlreadyIncluded
            } else {
              prevItem.discountAmount = 0
              prevItem.amountExcludeTax = 0
              prevItem.amountAlreadyIncluded = 0
              prevItem.amountTotal = 0
            }
          }
          if (column.field === 'unitListPrice') {
            if (newValue && prevItem?.quantity) {
              prevItem.discountAmount = prevItem?.quantity * prevItem?.quantity * prevItem?.discountPercentage / 100
              prevItem.amountExcludeTax = prevItem?.quantity * prevItem?.quantity - prevItem?.discountAmount
              prevItem.amountAlreadyIncluded = prevItem?.amountExcludeTax * prevItem?.sourcePercentage / 100
              prevItem.amountTotal = prevItem?.amountExcludeTax + prevItem?.amountAlreadyIncluded
            } else {
              prevItem.discountAmount = 0
              prevItem.amountExcludeTax = 0
              prevItem.amountAlreadyIncluded = 0
              prevItem.amountTotal = 0
            }
          }
          if (column.field === 'discountPercentage') {
            if (prevItem?.unitListPrice && prevItem?.quantity) {
              prevItem.discountAmount = prevItem?.quantity * prevItem?.unitListPrice * newValue / 100
              prevItem.amountExcludeTax = prevItem?.quantity * prevItem?.unitListPrice - prevItem?.discountAmount
              prevItem.amountAlreadyIncluded = prevItem?.amountExcludeTax * prevItem?.sourcePercentage / 100
              prevItem.amountTotal = prevItem?.amountExcludeTax + prevItem?.amountAlreadyIncluded
            } else {
              prevItem.discountAmount = 0
              prevItem.amountExcludeTax = 0
              prevItem.amountAlreadyIncluded = 0
              prevItem.amountTotal = 0
            }
          }
          if (column.field === 'discountAmount') {
            if (prevItem?.unitListPrice && prevItem?.quantity) {
              prevItem.amountExcludeTax = prevItem?.quantity * prevItem?.unitListPrice - newValue
              prevItem.amountAlreadyIncluded = prevItem?.amountExcludeTax * prevItem?.sourcePercentage / 100
              prevItem.amountTotal = prevItem?.amountExcludeTax + prevItem?.amountAlreadyIncluded
            } else {
              prevItem.discountAmount = 0
              prevItem.amountExcludeTax = 0
              prevItem.amountAlreadyIncluded = 0
              prevItem.amountTotal = 0
            }
          }
          if (column.field === 'sourcePercentage') {
            if (prevItem?.unitListPrice && prevItem?.quantity) {
              prevItem.discountAmount = prevItem?.quantity * prevItem?.unitListPrice * prevItem?.discountPercentage / 100
              prevItem.amountExcludeTax = prevItem?.quantity * prevItem?.unitListPrice - prevItem?.discountAmount
              prevItem.amountAlreadyIncluded = prevItem?.amountExcludeTax * newValue / 100
              prevItem.amountTotal = prevItem?.amountExcludeTax + prevItem?.amountAlreadyIncluded
            } else {
              prevItem.discountAmount = 0
              prevItem.amountExcludeTax = 0
              prevItem.amountAlreadyIncluded = 0
              prevItem.amountTotal = 0
            }
          }
          if (column.field === 'amountAlreadyIncluded') {
            if (prevItem?.unitListPrice && prevItem?.quantity) {
              prevItem.discountAmount = prevItem?.quantity * prevItem?.unitListPrice * prevItem?.discountPercentage / 100
              prevItem.amountExcludeTax = prevItem?.quantity * prevItem?.unitListPrice - prevItem?.discountAmount
              prevItem.amountTotal = prevItem?.amountExcludeTax + newValue
            } else {
              prevItem.discountAmount = 0
              prevItem.amountExcludeTax = 0
              prevItem.amountAlreadyIncluded = 0
              prevItem.amountTotal = 0
            }
          }
          return {
            ...prevItem,
            [column.field]: newValue
          }
        }
        return prevItem;
      }
    ))
    ;
  }

  const {render} = useDataTableBasic({
    tableHeader: 'Mặt hàng cần mua',
    dataKey: 'productId',
    indexColumnWidth: 40,
    columns: [
      {field: 'pseudoId', header: 'Mã sản phẩm', matchMode: 'contains', minWidth: 100},
      {field: 'productName', header: 'Tên sản phẩm', matchMode: 'contains', minWidth: 300},
      {field: 'estimatedIssueQty', header: 'Cần giao', minWidth: 100, dataType: 'number'},
      {field: 'quantityOnHandTotal', header: 'Tồn hiện tại', minWidth: 100, dataType: 'number'},
      {field: 'estimatedReceiveQty', header: 'Đang đặt mua', minWidth: 100, dataType: 'number'},
      {field: 'estimatedUnavailableQty', header: 'Còn thiếu', minWidth: 100, dataType: 'number'},
      {
        field: 'uomName', header: 'Đơn vị tính', minWidth: 100
      },
      {
        field: 'quantity', header: 'Số lượng', minWidth: 100, dataType: 'editor', editorConfig: {
          onEditorChange, field: {type: 'InputNumber'}
        }
      },
      {
        field: 'unitListPrice', header: 'Đơn giá', minWidth: 100, dataType: 'editor', editorConfig: {
          onEditorChange, field: {type: 'InputNumber'}
        }
      },
      {
        field: 'discountPercentage', header: 'Tỷ lệ CK', minWidth: 100, dataType: 'editor', editorConfig: {
          onEditorChange, field: {type: 'InputNumber', InputNumberProps: {min: 0, maxFractionDigits: 2, suffix: '%'}}
        }
      },
      {
        field: 'discountAmount', header: 'Số tiền CK', minWidth: 100, dataType: 'editor', editorConfig: {
          onEditorChange, field: {type: 'InputNumber'}
        }
      },
      {
        field: 'sourcePercentage', header: 'Thuế suất', minWidth: 100, dataType: 'editor', editorConfig: {
          onEditorChange, field: {type: 'InputNumber', InputNumberProps: {min: 0, maxFractionDigits: 2, suffix: '%'}}
        }
      },
      {
        field: 'amountAlreadyIncluded', header: 'Tiền thuế', minWidth: 100, dataType: 'editor', editorConfig: {
          onEditorChange, field: {type: 'InputNumber', InputNumberProps: {min: 0}}
        }
      },
    ],
    originalProps: {
      paginator: true,
      rows: 10,
      rowsPerPageOptions: [5, 10, 25, 50]
    },
    items: products,
  });

  const onSubmit = () => {
    products.map(product => {
      if (product?.quantity > 0 && product?.unitListPrice > 0) {
        product.dataKey = `${product.productId}_${Date.now()}_${Math.random()}`;
        result.push(product)
      }
    })
    setItems(result)
    setDisplay(false)
  }
  const footer = (
    <div className="flex justify-content-end">
      <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );


  return (
    <Dialog header={`Sản phẩm cần mua`} footer={footer} visible={!!display} style={{width: '100%'}} draggable={false} position="top" onHide={() => setDisplay(false)}>
      {render()}
    </Dialog>
  )

}
