import React, {useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {PurchasesOrdersService as Service} from '../don-hang-mua/PurchasesOrdersService';
import {FormatDisplay} from '@iamsoftware/react-hooks';
import {useCrupPO} from '../don-hang-mua/CrupPO';

export default function LichPO() {

  const [events, setEvents] = useState(null);
  const {renderCrupPO, doUpdatePO} = useCrupPO({reloadLazyData: () => {}});

  useEffect(() => {
    Service.calender().then(data => {
      setEvents(data.listData.map(item => {
        return {id: item.orderId, title:  `Mã đơn: ${item.displayId}`, start: FormatDisplay.date(new Date(item.estimatedShipDate), 'YYYY-MM-DD')}
      }))
    });
  }, []);

  const eventClick = (e) => {
    const {id} = e.event;
    if (id) {
      doView(id);
    }
  }


  const doView = dataKey => {
    Service.get(dataKey).then(data => {
      data.vendorPartyName = `${data.vendorPartyName} <${data.vendorPseudoId}>`;
      doUpdatePO( dataKey,data,true);
    });

  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card calendar">
          <FullCalendar
            events={events}
            eventClick={eventClick}
            initialView="dayGridMonth"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{left: 'prev,next today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay'}}
            editable
            selectable
            selectMirror
            dayMaxEvents
          />
          {renderCrupPO()}
        </div>
      </div>
    </div>
  )
}