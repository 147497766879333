import {EntityService} from '../../../service/EntityService';

export class SuppliersService extends EntityService {

  static entity = 'iam-purchase/suppliers';

  static uploadExcel(contentFile: any): Promise<any> {
    return this.axios.post(`/iam-party/suppliers/upload-excel`, this.toFormData({contentFile})).then(res => res.data);
  }

  static find(term: string): Promise<any> {
    return this.axios.get(`/iam-party/suppliers/find?term=${term}`,).then(res => res.data);
  }

  static getProductPrices(partyId: string, lazyLoadEvent?: string): Promise<any> {
    return this.axios.get(`${this.entity}/${partyId}/listProd`, {params: {lazyLoadEvent}}).then(res => res.data);
  }

  static createProductPrice(partyId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${partyId}/price/create`, data).then(res => res.data);
  }

  static updateProductPrice(partyId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/${partyId}/price/update`, data).then(res => res.data);
  }

  static disableProductPrice(partyId: string, productId: string): Promise<any> {
    return this.axios.put(`${this.entity}/${partyId}/price/disable`, {productId}).then(res => res.data);
  }

  static getPurchaseOrders(partyId: string, lazyLoadEvent?: string): Promise<any> {
    return this.axios.get(`${this.entity}/${partyId}/sup-order`, {params: {lazyLoadEvent}}).then(res => res.data);
  }
  static getBankAccounts(partyId: string): Promise<any> {
    return this.axios.get(`/iam-party/info/bank-accounts?partyId=${partyId}`).then(res => res.data);
  }

}