import {useEffect, useState} from 'react';

import {FormatDisplay, useForm} from '@iamsoftware/react-hooks';
import {SuppliersService} from '../nha-cung-cap/SuppliersService';

export const CrupPOPartForm = ({disabled, part, setPartProperty}) => {
  const [toPaymentMethodIds, setToPaymentMethodIds] = useState([])
  useEffect(() => {
    if (part.remindDate) {
      part.estimatedCompletionDate = FormatDisplay.date(new Date(part.remindDate), 'YYYY-MM-DD');
    }
    if (part.dueDate) {
      part.dueDate = FormatDisplay.date(new Date(part.dueDate), 'YYYY-MM-DD');
    }
    if (!part.vendorPartyId) {
      part.vendorPartyId = part.partyId
    }
    form.setValue(part);
    if (toPaymentMethodIds.length === 0) {
      SuppliersService.getBankAccounts(part.partyId).then(data =>{
        setToPaymentMethodIds(data.listData.map(item => {
          return {value: item.paymentMethodId, label: `[${item.accountNumber}] - ${item.bankName}`};
        }));
      })
    }
  }, [part]); // eslint-disable-line react-hooks/exhaustive-deps

  const form = useForm({
    fields: [
      {field: 'estimatedCompletionDate', header: 'Dự kiến giao', InputTextProps: {type: 'date'}, className: 'md:col-2'},
      {field: 'dueDate', header: 'Hạn thanh toán', InputTextProps: {type: 'date'}, className: 'md:col-2'},
      {field: 'remindBeforeDueDate', header: 'Nhắc trước khi đến hạn', type: 'InputNumber', InputNumberProps: {suffix: ' Ngày'}, className: 'md:col-2'},
      {field: 'toPaymentMethodId', header: 'STK', type: 'Dropdown', DropdownProps: {options: toPaymentMethodIds}, className: 'md:col-4'},],
    disabled
  });

  const _value = form.getRawValue();
  useEffect(() => {
    setPartProperty(part, 'estimatedCompletionDate', _value?.estimatedCompletionDate);
    setPartProperty(part, 'dueDate', _value?.dueDate);
    setPartProperty(part, 'remindBeforeDueDate', _value?.remindBeforeDueDate);
    setPartProperty(part, 'toPaymentMethodId', _value?.toPaymentMethodId);
  }, [_value?.estimatedCompletionDate, _value?.dueDate, _value?.remindBeforeDueDate, _value?.toPaymentMethodId]); // eslint-disable-line react-hooks/exhaustive-deps

  return(form.render())

}