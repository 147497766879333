import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ConfirmDialogService, ToastService, useDataTableBasic, useDialogCrup, useFileUploader, useToolbar} from '@iamsoftware/react-hooks';

import {ProductsService as Service} from './ProductsService';

export const ProductContents = ({display, setDisplay}) => {

  const header = `Hình ảnh #${display?.pseudoId} - ${display?.productName}`;

  const footer = (
    <div>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(false)}/>
    </div>
  );
  return (
    <Dialog header={header} footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(false)}>
      <Screen productId={display?.productId}/>
    </Dialog>
  );
}

const Screen = ({productId}) => {

  const header = 'Hình ảnh';
  const dataKey = 'key';

  const [productContents, setProductContents] = useState([]);
  const [product, setProduct] = useState([]);
  const [originGeos, setOriginGeos] = useState([]);
  const searchOriginGeos = event => {
    const _event: any = {
      filters: {geoName: {value: event.query, matchMode: 'contains'}}
    };
    Service.getOriginGeos(JSON.stringify(_event)).then(data => {
      setOriginGeos(data.listData.map(item => {
        return {value: item.geoId, label: item.geoName};
      }));
    });
  }

  useEffect(() => {
    loadContents();
  }, [productId]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadContents = () => {
    if (productId) {
      Service.get(productId).then(data => {
        setProductContents(data.productContents.map(productContent => {
          return {
            key: productContent.productContentId,
            contentType: productContent.contentType,
            fileName: productContent.fileName,
            src: `${Service.baseURL}/${Service.entity}/${productId}/contents/${productContent.productContentId}`,
          }
        }));
      });
      Service.get(productId).then(data => {
        setProduct([{
          geoName: data.geoName,
          description: data.description,
          referenceUrl: data.referenceUrl
        }]);
      });
    }
  }

  const doUpdate = () => {
      Service.get(productId).then(data => {
        if (data.originGeoId) {
          data.originGeo = {value: data.originGeoId, label: data.geoName};
        }
        setProduct([{
          geoName: data.geoName,
          description: data.description,
          referenceUrl: data.referenceUrl
        }]);
          update(data);
      });
  }




  const doView = rowData => {
    window.open(rowData.src);
  }

  const {render: renderDataTable, selectedItems} = useDataTableBasic({
    dataKey,
    columns: [
      {
        field: 'pseudoId', header: 'Hình ảnh', minWidth: 200, dataType: 'custom', customCell(rowData: any): any {
          return <img src={rowData.src} alt={rowData.fileName} onClick={() => doView(rowData)} style={{maxHeight: '100px'}}/>
        }
      },
    ],
    indexColumnWidth: 45,
    items: productContents
  });

  const {render: renderDataTable2} = useDataTableBasic({
    dataKey:'productId',
    columns: [
      {field: 'geoName', header: 'Quốc gia', width: 150, },
      {field: 'description', header: 'Mô tả', minWidth: 150, },
      {field: 'referenceUrl', header: 'Link tham khảo', minWidth: 150,},
    ],
    items: product
  });

  const onFileSelect = async file => {
    if (file.key) {
      window.open(file.src);
    } else {
      const src = window.URL.createObjectURL(new Blob([file], {type: file.type}));
      window.open(src)
    }
  }

  const {renderFileUploader: renderContents, getFiles: getContents} = useFileUploader({
    onSelect: onFileSelect
  });

  const {render: renderDialogCrup, create} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {
        field: 'productContents', header: 'Ảnh sản phẩm', type: 'FileUploader', FileUploaderProps: {render: renderContents}, className: 'md:col-12'
      },

    ],
    createItem: async item => {
      item.productContents = await getContents(false);
      if (item.productContents.length === 1) {
        item.productContent = item.productContents[0];
        delete item.productContents;
      }
      return Service.createContent(productId, Service.toFormData(item));
    },
    reloadLazyData: loadContents
  });

  const {render: renderDialogCrup2, update} = useDialogCrup({
    header: 'thông tin sản phẩm',
    dataKey: 'productId',
    width: '40rem',
    fields: [
      {field: 'referenceUrl', header: 'Link tham khảo', type: 'InputTextarea', className: 'md:col-12'},
      {field: 'description', header: 'Mô tả', type: 'InputTextarea', className: 'md:col-12'},
      {
        field: 'originGeo', header: 'Quốc gia', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: originGeos, completeMethod: searchOriginGeos, field: 'label', dropdown: true},
        className: 'md:col-12'
      },
    ],
    updateItem: async (id, item) => {
      return Service.update(id, await inject(item));
    },
    reloadLazyData: loadContents
  })

  const inject = async item => {
    if (item.originGeo) {
      item.originGeoId = item.originGeo.value;
      delete item.originGeo;
    }
    return Service.toFormData(item);
  }



  const selectedItem: any = (selectedItems && selectedItems[dataKey]) ? selectedItems : null;

  const doCreate = () => {
    create();
  }

  const doDelete = () => {
    if (selectedItem && selectedItem[dataKey]) {
      ConfirmDialogService.confirmDelete(() => {
        Service.deleteContent(productId, selectedItem[dataKey]).then(() => {
          ToastService.success();
          loadContents();
        });
      });
    }
  }


  const {renderToolbar} = useToolbar({
    doCreate,
    hasSelectedItem: selectedItem,
    doView: () => doView(selectedItem),
    doDelete,
  });

  return (
    <div className="grid mt-2">
      <div className="col-4">
        <div className="grid">
          <div className="col-12">
            {renderToolbar()}
          </div>
          <div className="col-12 py-0">
            {renderDataTable()}
          </div>
        </div>
      </div>

      <div className="col-8">
        <div className="grid">
          <div className="col-6">
            <div >
              <h6>Thông tin sản phẩm</h6>
            </div>
          <div>
          </div>
        </div>

          <div className="col-12 py-0">
            {renderDataTable2()}

          </div>
          <div className="col-12">
            {<Button label="Chỉnh sửa" severity="info" size="small" onClick={doUpdate}/>}
          </div>
      </div>
      </div>
      {renderDialogCrup()}
      {renderDialogCrup2()}
    </div>

  );

}