import {Route, Routes} from 'react-router-dom';

import {PurchasesOrders} from './don-hang-mua';
import {Suppliers} from './nha-cung-cap';
import LichPO from './lich-PO';
import ProductsToBuy from './san-pham-can-mua';
import PurchasePayment from './thanh-toan';

export default function Purchases() {

  return (
    <Routes>
      <Route path="don-hang-mua" element={<PurchasesOrders/>}/>
      <Route path="lich-PO" element={<LichPO/>}/>
      <Route path="nha-cung-cap" element={<Suppliers/>}/>
      <Route path="san-pham-can-mua" element={<ProductsToBuy/>}/>
      <Route path="thanh-toan" element={<PurchasePayment/>}/>
    </Routes>
  );
}
