import {Route, Routes} from 'react-router-dom';

import {QuantityUoms} from './don-vi-san-pham';

export default function Category() {

  return (
    <Routes>
      <Route path="don-vi-san-pham" element={<QuantityUoms/>}/>
    </Routes>
  );
}
