import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ToastService, useDataTable, useDataTableBasic} from '@iamsoftware/react-hooks';

import {PaymentService as Service} from "./PaymentServices";

export const useCrupTemplate = ({doCreateItem}, {agreementId}, printTemplateIds) => {

  const [display, setDisplay] = useState(null);
  const [templates, setTemplates] = useState(null);


  const {render: renderDataTable, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: 'Sản phẩm ',
    dataKey: 'printTemplateId',
    columns: [
      {field: 'templateCode', header: 'Mã biểu mẫu', width: 50, matchMode: 'contains'},
      {field: 'templateName', header: 'Tên biểu mẫu', width: 120, matchMode: 'contains'},
      {field: 'printTypeEnum', header: 'Loại biểu mẫu', width: 140, matchMode: 'contains'},
      {field: 'description', header: 'Ghi chú', width: 140, matchMode: 'contains'},
    ],
    selectionMode: 'checkbox',
    items: templates
  });

  const doPlanTemplate = () => {
    setSelectedItems(null)
    setDisplay(Date.now());
    Service.getAddableTemplate(agreementId, printTemplateIds).then(data=>{
      setTemplates(data.items)
    })
  }
  const onSubmit = event => {
    if (selectedItems?.length) {
      let x = {facilityIds: selectedItems.map(item=>{
          return item.facilityId})}
      setDisplay(Date.now());
      selectedItems.forEach((item) => {
        if (!item.printTemplatesWithId) {
          item.printTemplatesWithId = `[${item.templateCode}] - ${item.templateName}`;
        }
      });
      doCreateItem(selectedItems);
      setDisplay(null);
    } else {
      ToastService.error('Chưa chọn biểu mẫu.');
    }
    event.preventDefault();
  }
  const doPlan = () => {
    setDisplay(Date.now());
  }
  const footer = (
    <div className="flex justify-content-center">
      <Button label="Xác nhận" icon="pi pi-arrow-right" size="small" onClick={onSubmit}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  const renderTemplatePlanning = () => {
    return (
      <Dialog header="Chọn biểu mẫu" footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(null)}>
        <div className="grid pt-2">
          {renderDataTable()}
        </div>
      </Dialog>
    );
  }

  return {renderTemplatePlanning, doPlanTemplate};
}
