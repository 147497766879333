import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {TabView, TabPanel} from 'primereact/tabview';
import {AutoComplete} from "primereact/autocomplete";


import {ConfirmDialogService, FormatDisplay, ToastService, useDataTableBasic, useDialogCrup, useFileUploader, useForm, useToolbar} from '@iamsoftware/react-hooks';


import {PaymentService as Service} from './PaymentServices';
import {UserStorage} from '../../../service/UserStorage';
import {QuantityUomsService} from '../../quan-ly/danh-muc/don-vi-san-pham/QuantityUomsService';
import {useCrupItemProducts} from "./ProductItems";
import {useCrupTemplate} from './TemplateItems';
import moment from 'moment/moment';


export const useCrupPaymentDetail = ({reloadLazyData}) => {

  const [display, setDisplay] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [workEffortList, setWorkEffortList] = useState(null)
  const [itemList, setItemList] = useState(null)
  const [templateList, setTemplateList] = useState(null)
  const [quantityUoms, setQuantityUoms] = useState([]);
  const [contents, setContents] = useState(null);
  const [bankList, setBankList] = useState(null)
  const [agreementId, setAgreementId] = useState(null)
  const [workEffortId, setWorkEffortId] = useState(null)

  const [deleteInvoiceItemSeqIds] = useState([])
  const [deletePrintTemplateIds] = useState([])
  const [printTemplateIds, setPrintTemplateIds] = useState([])
  const [productIds, setProductIds] = useState([])

  const [toPaymentMethod, setToPaymentMethod] = useState('');
  const [toPaymentMethodId, setToPaymentMethodId] = useState(null);


  const updateTooltip = 'Cập nhật sản phẩm';
  const deleteTooltip = 'Xóa sản phẩm';
  const deleteTemplateTooltip = 'Xóa biểu mẫu';
  const addBankTooltip = 'Thêm ngân hàng';

  const searchQuantityUoms = event => {
    const _event: any = {
      filters: {
        disabled: {value: 'N', matchMode: 'equals'},
        description: {value: event.query, matchMode: 'contains'}
      }
    };
    QuantityUomsService.getList(JSON.stringify(_event)).then(data => {
      setQuantityUoms(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.uomId, label: item.description};
      }));
    });
  }
  const ownerPartyId = UserStorage.get('household');
  const templateDataKey = 'printTemplateId'
  const itemDataKey = 'invoiceItemSeqId'

  const onFileSelect = async file => {
    window.open(`${Service.baseURL}/${Service.entity}/${agreementId}/download-attachment?ownerPartyId=${ownerPartyId}&invoiceContentId=${file.key}`);
  }

  const {renderFileUploader: renderContents, getFiles: getContents, deletedExistingFiles: deletedContents, setFileUploaderDisabled} = useFileUploader({
    onSelect: onFileSelect,
    existingFiles: contents
  });
  const searchBankList = event => {
    Service.getOrgBankList(ownerPartyId).then(data => {
      setBankList(data.listData.map(item => {
        return {value: item.paymentMethodId, label: `[${item.accountNumber}] - ${item.bankName}`};
      }));
    });
  }

  const {render: renderDialogCreateBank, create: createBank} = useDialogCrup({
    header: '!Thêm mới tài khoản ngân hàng',
    submitLabel: 'Xác nhận',
    width: '40rem',
    fields: [
      {field: 'accountNumber', header: 'Số tài khoản', required: true, className: 'md:col-12'},
      {field: 'bankName', header: 'Tên ngân hàng', required: true, className: 'md:col-12'}
    ],
    createItem: item => {
      item.partyId = ownerPartyId
      return Service.createOrgBankList(item);
    },
    checkResponseError: data => {
      let error = '';
      if (data?.isSuccess === 'N') {
        error = data.messages;
      }
      return error;
    }
  });

  const searchWorkEffortList = event => {
    Service.getWorkEffortList().then(data => {
      setWorkEffortList(data.listData.map(item => {
        return {value: item.workEffortId, label: item.workEffortName};
      }));
    });
  }
  const doPlanPayment = () => {
    setDisplay(Date.now());
  }
  const itemTemplate = (item) => {
    return (
      <div className="flex align-items-center">
        <div>{item.label}</div>
      </div>
    );
  };
  const form = useForm({
    fields: [
      {
        field: 'workEffort', header: 'Phiếu gộp', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: workEffortList, completeMethod: searchWorkEffortList, field: 'label', dropdown: true},
        className: 'md:col-5', required: true
      },
      {field: 'agreementDate', header: 'Ngày hợp đồng', required: true, className: 'md:col-3', InputTextProps: {type: 'date'}},
      {field: 'agreementNo', header: 'Số hợp đồng', required: true, className: 'md:col-4'},
      {field: 'customer', header: 'Khách hàng', disabled: true, className: 'md:col-5'},
      {field: 'invoiceNo', header: 'Số hóa đơn', required: true, className: 'md:col-3'},
      {field: 'invoiceSerial', header: 'Ký hiệu hóa đơn', required: true, className: 'md:col-4'},
      {field: 'referenceNumber', header: 'Mã đơn hàng', required: true, className: 'md:col-2'},

      {field: 'invoiceTotal', header: 'Tổng tiền', required: true, className: 'md:col-3', type: 'InputNumber'},
      {field: 'invoiceTotalInWords', header: 'Bằng chữ', required: true, className: 'md:col-3'},
      /*{
        field: 'toPaymentMethod', header: 'Tài khoản nhận', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: bankList, completeMethod: searchBankList, field: 'label', dropdown: true},
        className: 'md:col-2', required: true
      },*/
      {
        field: 'toPaymentMethod', header: 'Tài khoản nhận', type: 'custom', className: 'md:col-4',
        body:
          <div>
            <div>
              Tài khoản nhận
            </div>
            <div className='flex justify-content-center mt-2 mr-6 '>
              <AutoComplete value={toPaymentMethod} suggestions={bankList} completeMethod={searchBankList} itemTemplate={itemTemplate} onChange={(e) => {
                setToPaymentMethod(e.value.label);
                setToPaymentMethodId(e.value.value)
              }} dropdown/>
              <Button icon="pi pi-plus" style={{height:'30px'}} severity="success" tooltip={addBankTooltip} onClick={() => createBank()}/>
            </div>
          </div>

      },
      /*{
        field: 'addPaymentMethodId', type: 'custom', body: <Button icon="pi pi-plus" severity="success" onClick={() => createBank()}/>, className: 'md:col-1  pt-4'
      },*/

    ],
    disabled
  });

  const _value = form.getRawValue();

  const attachmentForm = useForm({
    fields: [{
      field: 'contentFile', header: 'File đính kèm', type: 'FileUploader', FileUploaderProps: {render: renderContents}, className: 'md:col-12'
    },]
  })
  useEffect(() => {
    if (_value?.workEffort?.value && form.dirty['workEffort']) {
      Service.getWorkEffortInfo(_value.workEffort?.value).then(data => {
        data.workEffort = _value.workEffort
        data.agreementNo = _value.agreementNo
        data.invoiceNo = _value.invoiceNo
        data.invoiceSerial = _value.invoiceSerial
        data.toPaymentMethod = _value.toPaymentMethod
        data.agreementDate = FormatDisplay.date(new Date(_value.agreementDate), 'YYYY-MM-DD')
        data.customer = `[${data.customerPseudoId}] - ${data.customerName}`
        if (data.items?.length) {
          data.items.forEach((item, index) => {
            if (!item.productId) {
              item.productId = `${Date.now()}_${Math.random()}_${index}`;
            }
            if (!item.productNameWithlocalizedName) {
              item.productNameWithlocalizedName = `[${item.productName}] `;
              if (item.localizedName) {
                item.productNameWithlocalizedName += `- ${item.localizedName}`;
              }
            }
          });
        }
        setItemList(data.items)
        form.setValue(data)
      })

    }
  }, [_value.workEffort]);


  const doCreateProduct = (productItems: Array<any>) => {
    if (productItems?.length) {
      itemList.push(...productItems.map(productItem => {
        return {...productItem}
      }));
    }
  }
  const {renderPurchasePlanning, doPlanProduct} = useCrupItemProducts({doCreateItem: doCreateProduct}, {agreementId: agreementId}, productIds);
  const doCreateTemplate = (templateItems: Array<any>) => {
    if (templateItems?.length) {
      templateList.push(...templateItems.map(templateItem => {
        printTemplateIds.push(templateItem.printTemplateId)
        return {...templateItem}
      }));
    }
  }

  const {renderTemplatePlanning, doPlanTemplate} = useCrupTemplate({doCreateItem: doCreateTemplate}, {agreementId: agreementId}, printTemplateIds);

  const doUpdate = (agreementId: string, data: any, doView: boolean) => {
    form.reset()
    setAgreementId(agreementId)
    setWorkEffortId(data.workEffortId)
    setContents(null)
    // setToPaymentMethod('')
    if (data.items?.length) {
      data.items.forEach((item) => {
        if (!item.productNameWithlocalizedName) {
          item.productNameWithlocalizedName = `[${item.pseudoId}] - ${item.description}`;
        }
      });
    }
    if (data.printTemplates?.length) {
      data.printTemplates.forEach((item) => {
        if (!item.printTemplatesWithId) {
          item.printTemplatesWithId = `[${item.templateCode}] - ${item.templateName}`;
        }
      });
    }
    setItemList(data.items)
    let prodIds = {
      productIds: data.items.map(item => {
        return item.productId
      })
    }
    setProductIds(prodIds.productIds)
    setTemplateList(data.printTemplates)
    data.agreementDate = FormatDisplay.date(data.agreementDate, 'YYYY-MM-DD')
    if (data.paymentId) {
      setToPaymentMethodId(data.toPaymentMethodId);
    }
    if (data.toBankName && data.toAccountNumber) {
      setToPaymentMethod(`[${data.toAccountNumber}] - ${data.toBankName}`);
    }
    if (data.workEffortId) {
      data.workEffort = {value: data.workEffortId, label: data.workEffortName};
    }
    let tempIds = {
      printTemplates: data.printTemplates.map(item => {
        return item?.printTemplateId
      })
    }
    data.customer = `[${data.toPseudoId}] - ${data.toPartyName}`
    setPrintTemplateIds(tempIds.printTemplates)
    form.setValue(data)
    if (data.invoiceContents?.length) {
      const _contents = [];
      for (const invoiceContent of data.invoiceContents) {
        _contents.push({
          key: invoiceContent.invoiceContentId,
          contentType: invoiceContent.contentType,
          fileName: invoiceContent.fileName,
          src: `${Service.baseURL}/${Service.entity}/${invoiceContent.invoiceContentId}/download-attachment?ownerPartyId=${ownerPartyId}`
        });
      }
      setContents(_contents);
    }
    if (doView === true) {
      setDisabled(true);
      setDisplay(Date.now());
    } else {
      setDisabled(false);
      setDisplay(Date.now());
    }
  }


  const {render: renderDialogCrup, update, form: itemForm} = useDialogCrup({
    header: '!Sản phẩm',
    width: '40rem',
    dataKey: itemDataKey,
    fields: [
      {field: 'productNameWithlocalizedName', header: 'Sản phẩm', disabled: true, className: 'md:col-12'},
      {
        field: 'qtyUom', header: 'Đơn vị tính', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: quantityUoms, completeMethod: searchQuantityUoms, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-12'
      },
      {field: 'quantity', header: 'SL', required: true, className: 'md:col-6', type: 'InputNumber'},
      {field: 'amount', header: 'Đơn giá', required: true, type: 'InputNumber', className: 'md:col-6'},
      {field: 'itemTotal', header: 'Thành tiền', className: 'md:col-12', disabled: true, type: 'InputNumber',},

    ],
    updateItem: (id, item) => {
      setItemList(prevState => {
        for (const part_item of prevState) {
          if (part_item[itemDataKey] === id) {
            part_item.amount = item.amount;
            part_item.quantity = item.quantity;
            part_item.quantityUomId = item.qtyUom?.value;
            part_item.quantityUom = item.qtyUom?.label;
            part_item.itemTotal = item.amount > 0 && item.quantity > 0 ? item.amount * item.quantity : 0;
            break;
          }
        }
        return [...prevState];
      });

      return Promise.resolve(item);
    },
    hideToastMessage: true
  });

  const product = itemForm.getRawValue();

  useEffect(() => {
    const _value = itemForm.getRawValue();
    _value.amount = product.amount;
    _value.qtyUom = product.qtyUom;
    _value.itemTotal = _value.amount > 0 && _value.quantity > 0 ? _value.amount * _value.quantity : 0;
    itemForm.setValue(_value);
  }, [product?.quantity, product?.amount]);

  const doDeleteTemplate = (selectedTemplate) => {
    if (selectedTemplate && selectedTemplate[templateDataKey]) {
      ConfirmDialogService.confirm('Xóa biểu mẫu', `Xác nhận xóa biểu mẫu ${selectedTemplate.printTemplatesWithId}  ra khỏi danh sách?`, () => {
        setTemplateList(prevItems => {
          deletePrintTemplateIds.push(selectedTemplate[templateDataKey])
          printTemplateIds.splice(printTemplateIds.indexOf((selectedTemplate[templateDataKey]), 1));
          return prevItems.filter((prevItem => prevItem[templateDataKey] !== selectedTemplate[templateDataKey]));
        });
      });
    }
  }

  const onSubmit = async event => {
    if (form.valid()) {
      const input = form.getValue()
      input.items = JSON.stringify(itemList)
      input.contentFiles = await getContents(false);
      if (input.contentFiles.length === 1) {
        input.contentFile = input.contentFiles[0];
        delete input.contentFiles;
      }

      //sp bi xoa
      if (deleteInvoiceItemSeqIds?.length) {
        input.deleteInvoiceItemSeqIds = JSON.stringify(deleteInvoiceItemSeqIds);
      }

      //file dinh kem bi xoa
      if (deletedContents?.length) {
        input.deleteInvoiceContentIds = JSON.stringify(deletedContents);
      }

      //bieu mau bi xoa
      if (deletePrintTemplateIds?.length) {
        input.deletePrintTemplateIds = JSON.stringify(deletePrintTemplateIds);
      }

      input.workEffortId = input.workEffort?.value
      input.toPaymentMethodId = toPaymentMethodId
      let x = {
        printTemplates: templateList.map(item => {
          return item.printTemplateId
        })
      }
      input.printTemplateIds = JSON.stringify(x.printTemplates)
      Service.updatePayment(agreementId, Service.toFormData(input)).then(() => {
        setDisplay(null)
        setToPaymentMethodId(null)
        event.preventDefault();
        ToastService.success();
        reloadLazyData();
      })
    }
  }

  const productHeader = (
    <div className="flex align-items-center justify-content-between ">
      <Fragment>
        <span> Danh sách sản phẩm </span>
        <div>
          <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                  size="small" onClick={doPlanProduct}/>
        </div>
      </Fragment>
    </div>
  );
  const templateHeader = (
    <div className="flex align-items-center justify-content-between ">
      <Fragment>
        <span> Danh sách biểu mẫu</span>
        <div>
          <Button label="Thêm" icon="pi pi-plus" severity="success" className="mr-4 mb-1 w-7rem h-2rem"
                  size="small" onClick={doPlanTemplate}/>
        </div>
      </Fragment>
    </div>
  );

  const {render: renderItemDetail} = useDataTableBasic({
    tableHeader: productHeader,
    indexColumnWidth: 40,
    columns: [
      {field: 'productNameWithlocalizedName', header: 'Sản phẩm', minWidth: 120, matchMode: 'contains'},
      {field: 'destFacilityPseudoId', header: 'Mã xưởng', width: 100, matchMode: 'contains'},
      {field: 'orderDisplayId', header: 'Mã đơn hàng', width: 150, matchMode: 'contains'},
      {field: 'quantityUom', header: 'ĐVT', width: 100, matchMode: 'contains'},
      {field: 'quantity', header: 'SL', width: 80, matchMode: 'equals', dataType: 'number'},
      {field: 'amount', header: 'Đơn giá', width: 130, matchMode: 'equals', dataType: 'number'},
      {field: 'itemTotal', header: 'Thành tiền', width: 150, matchMode: 'equals', dataType: 'number'},
      {
        field: 'productNameWithPseudoId', header: 'Action', width: 110, dataType: 'custom', customCell(rowData: any): any {
          return <div>
            <Button icon="pi pi-pencil" className='mr-1' size='small' severity="warning" tooltip={updateTooltip}
                    onClick={() => doUpdateProduct(rowData)}></Button>
            <Button icon="pi pi-times" className='mr-1' size='small' severity="danger" tooltip={deleteTooltip}
                    onClick={() => doDeleteProduct(rowData)}></Button>
          </div>
        }
      },
    ],
    items: itemList
  });

  const doUpdateProduct = (selectedProduct) => {
    if (selectedProduct && selectedProduct[itemDataKey]) {
      selectedProduct.qtyUom = {value: selectedProduct.quantityUomId, label: selectedProduct.quantityUom};
      update(selectedProduct);
    }
  }
  const doDeleteProduct = (selectedProduct) => {
    if (selectedProduct && selectedProduct[itemDataKey]) {
      ConfirmDialogService.confirm('Xóa sản phẩm', `Xác nhận xóa sản phẩm ${selectedProduct.productNameWithlocalizedName} ra khỏi danh sách?`, () => {
        deleteInvoiceItemSeqIds.push(selectedProduct[itemDataKey])
        productIds.splice(productIds.indexOf((selectedProduct[itemDataKey]), 1));
        setItemList(prevItems => {
          return prevItems.filter(prevItem => prevItem[itemDataKey] !== selectedProduct[itemDataKey]);
        });
      });
    }
  }

  const {render: renderTemplateDetail} = useDataTableBasic({
    tableHeader: templateHeader,
    dataKey: 'printTemplateId',
    indexColumnWidth: 40,
    columns: [
      {
        field: 'templateName', header: 'Tên biểu mẫu', minWidth: 500, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <div>
            <i className="pi pi-download ml-2"
               onClick={() => window.open(`${Service.baseURL}/${Service.entity}/${agreementId}/download-template?ownerPartyId=${ownerPartyId}&printTemplateId=${rowData?.printTemplateId}`)}
            ></i> {rowData.printTemplatesWithId}</div>
        }
      },
      {field: 'printTypeEnum', header: 'Loại biểu mẫu', minWidth: 200, matchMode: 'contains'},
      {field: 'description', header: 'Ghi chú', minWidth: 150, matchMode: 'contains'},
      {
        field: 'productNameWithPseudoId', header: 'Action', width: 110, dataType: 'custom', customCell(rowData: any): any {
          return <div>
            <Button icon="pi pi-times" className='mr-1' size='small' severity="danger" tooltip={deleteTemplateTooltip}
                    onClick={() => doDeleteTemplate(rowData)}></Button>
          </div>
        }
      },
    ],
    items: templateList
  });


  const footer = (
    <div className="flex justify-content-end">
      {!disabled && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );


  const render = () => {
    return (
      <Fragment>
        <Dialog header="Thông tin thanh toán" footer={footer} visible={!!display} style={{width: '80rem'}} draggable={false} onHide={() => setDisplay(null)}>
          <div className="grid pt-2">
            <div className="col-12">
              {form.render()}
            </div>
            <TabView className="p-tabview-sm">
              <TabPanel header="Sản phẩm">
                <div className="md:col-12 ">
                  {renderItemDetail()}
                </div>
              </TabPanel>
              <TabPanel header="Biểu mẫu">
                <div className="md:col-12">
                  {renderTemplateDetail()}
                </div>
              </TabPanel>
              <TabPanel header="File đính kèm">
                <div className='md:col-12'>
                  {attachmentForm.render()}
                </div>
              </TabPanel>

            </TabView>
          </div>
          {renderDialogCrup()}
          {renderPurchasePlanning()}
          {renderTemplatePlanning()}
          {renderDialogCreateBank()}

        </Dialog>
      </Fragment>
    );
  }

  return {renderCrupPaymentDetail: render, doUpdatePayment: doUpdate, doPlanPayment};
}