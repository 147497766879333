import {Fragment, useEffect, useRef, useState} from 'react';
import {SelectButton} from 'primereact/selectbutton';
import {Toast} from 'primereact/toast';
import {Card} from 'primereact/card';
import {Tooltip} from 'primereact/tooltip';


import {Button} from 'primereact/button';
import {FormatDisplay, ToastService, useDataTable, useDialogCrup, useFileUploader, useForm, useToolbar} from '@iamsoftware/react-hooks';

import {TemplatesService as Service} from './TemplatesService';
import {UserStorage} from "../../../service/UserStorage";
import {ImportExcel} from '../../../shared/components/ImportExcel';

const statuses = [
  {value: 'N', label: 'Đang hoạt động'},
  {value: 'Y', label: 'Ngừng hoạt động'}
];

export const Templates = () => {

  const [statusId, setStatusId] = useState(statuses[0].value);
  const [crupMethod, setCrupMethod] = useState(null);
  const [templateTypes, setTemplateTypes] = useState([])
  const header = 'Biểu mẫu';
  const dataKey = 'printTemplateId';
  const [readOnly, setReadOnly] = useState(false);
  const [parameters, setParameters] = useState(null)
  const [fromDate, setFromdate] = useState(null)
  const [printTypeEnumId, setPrintTypeEnumId] = useState(null)
  const toast = useRef(null);
  const [refLinkTooltip, setRefLinkTooltip] = useState('');
  const [headerResult, setHeaderResult] = useState(null);

  useEffect(() => {
    if (statusId) {
      reloadLazyData();
    } else {
      setStatusId(statuses[0].value);
    }
    setHeaderResult(tabHeader)
  }, [statusId]);

  const searchTemplateTypes = event => {
    Service.getTemplateType().then(data => {
      setTemplateTypes(data.listData.map(item => {
        return {value: item.enumId, label: item.description};
      }));
    });
  }
  const ownerPartyId = UserStorage.get('household');


  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    tableHeader: headerResult,
    dataKey,
    columns: [
      {field: 'printTemplateId', hidden: true},
      {
        field: 'workEffortName', header: 'Mã biểu mẫu', width: 120, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => Service.get(rowData.printTemplateId).then(data => {
            if (data.fromDate) {
              data.fromDate = FormatDisplay.date(new Date(data.fromDate), 'YYYY-MM-DD');
              setFromdate(data.fromDate)
            }
            Service.getParameters(data.printTypeEnumId).then(params => {
              const x = {
                parameters: params.items.map(item => {
                  return `${item.entryName} ${item.title}`
                })
              }
              setParameters(x)
            })
            setPrintTypeEnumId(data.printTypeEnumId)
            setReadOnly(false)
            update(data);
          })
          }>{rowData.templateCode}</a>
        }
      },
      {field: 'printTypeEnum', header: 'Loại biểu mẫu', width: 200, matchMode: 'contains'},
      // {field: 'templateName', header: 'Tên biểu mẫu', width: 300, matchMode: 'contains'},
      {
        field: 'templateName', header: 'Tên biểu mẫu', width: 250, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <div>
            <i className="pi pi-download mr-2"
               onClick={() => window.open(`${Service.baseURL}/${Service.entity}/${rowData.printTemplateId}/download-attachment?ownerPartyId=${ownerPartyId}`)}
            ></i>{rowData.templateName}</div>
        }
      },
      {field: 'description', header: 'Mô tả', width: 300, matchMode: 'contains'},
      {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains'},
      // {field: 'fromDate', header: 'Ngày hiệu lực', dataType: 'date', width: 150},

    ],
    indexColumnWidth: 45,
    actionColumnWidth: 175,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Xem', className: 'p-button-info', command: () => doUpdate(true, item)},
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => doUpdate(false, item)},
          ]
          if (statusId === 'N') {
            item.actions.push({icon: 'pi pi-lock', title: 'Khóa', className: 'p-button-danger', command: () => doDisable(item)})
          } else if (statusId === 'Y') {
            item.actions.push({icon: 'pi pi-lock-open', title: 'Mở khóa', className: 'p-button-success', command: () => doEnable(item)})
          }
        })

      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent, statusId);
    },

  });


  const copyToken = text => async event => {
    try {
      let arr = text.split(' ')
      await navigator.clipboard.writeText(arr[0]);
      setRefLinkTooltip('Đã sao chép');
    } catch (e) {
      setRefLinkTooltip('Sao chép không thành công, Có lỗi xảy ra.');
    }
    event.preventDefault();
  }


  const {render: renderDialogCrup, create, update, view, form, formMethod} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'printTypeEnumId', display: false},
      {field: 'templateCode', header: 'Mã biểu mẫu', required: true, className: 'md:col-6'},
      {
        field: 'printTypeEnum', header: 'Loại biểu mẫu', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: templateTypes, completeMethod: searchTemplateTypes, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-6'
      },
      {field: 'templateName', header: 'Tên biểu mẫu', className: 'md:col-6'},
      {field: 'description', header: 'Mô tả', className: 'md:col-6'},
      {field: 'fromDate', header: 'Ngày hiệu lực', InputTextProps: {type: 'date'}, className: 'md:col-6'},
      {
        field: 'contentFile', header: 'File biểu mẫu', disabled: 'View', required: 'Create', type: 'file', className: 'md:col-6', InputFileProps: {accept: ".doc, .docx"}
      },
      // style='cursor: pointer'
      {
        type: 'custom', className: 'md:col-12 mb-3',
        body:
          <Fragment>
            <Card subTitle="Các trường dữ liệu">
              {parameters?.parameters.map((parameter) =>

                <div key={parameter} className='col-fixed' style={{paddingTop: 3, width: 'max-content'}} onMouseEnter={() => setRefLinkTooltip('Click để sao chép')}>
                  <Button icon='pi pi-copy'
                          onClick={copyToken(parameter)}
                          tooltip={refLinkTooltip}
                          className="p-button-outlined p-button-secondary mr-2 w-2rem h-2rem "/>
                  {parameter}
                </div>
              )}
            </Card>

          </Fragment>

      },

    ],
    updateItem: async (id, item) => {
      return Service.update(id, await inject(item));
    },
    createItem: async item => {
      return Service.create(await inject(item));
    },
    reloadLazyData
  });
  const _value = form.getValue();

  useEffect(() => {
    if (_value?.templateCode && form.dirty['vendorParty']) {

      form.setValue(_value);
    }
  }, [_value?.templateCode]);


  const inject = async item => {
    if (item.contentFile.length) {
      item.contentFile = item.contentFile[0]
    }
    item.printTypeEnumId = printTypeEnumId
    return Service.toFormData(item);
  }


  const doUpdate = (doView: boolean, rowData) => {
    form.reset();
    Service.get(rowData[dataKey]).then(data => {
      if (data.fromDate) {
        data.fromDate = FormatDisplay.date(new Date(data.fromDate), 'YYYY-MM-DD');
        setFromdate(data.fromDate)
      }
      Service.getParameters(data.printTypeEnumId).then(params => {
        const x = {
          parameters: params.items.map(item => {
            return `${item.entryName} ${item.title}`
          })
        }
        setParameters(x)
      })
      setPrintTypeEnumId(data.printTypeEnumId)
      if (doView === true) {
        setReadOnly(true)
        view(data);
      } else {
        setReadOnly(false)
        update(data);
      }
    });
  }
  const doCreate = () => {
    setReadOnly(false)
    form.reset();
    setParameters(null)
    create();
  }

  const doDisable = (rowData) => {
    Service.disable(rowData[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    });

  }
  const doEnable = (rowData) => {
    Service.enable(rowData[dataKey]).then(() => {
      ToastService.success();
      reloadLazyData();
    });
  }


  useEffect(() => {
    setCrupMethod(formMethod);
  }, [formMethod]);

  useEffect(() => {
    if (_value.contentFile.length) {
      _value.templateName = _value?.contentFile[0]?.name
      _value.fromDate = fromDate
      form.setValue(_value)
    }
  }, [_value?.contentFile]);

  useEffect(() => {
    if (_value.printTypeEnum.value) {
      setPrintTypeEnumId(_value.printTypeEnum.value)
      Service.getParameters(_value.printTypeEnum.value).then(params => {
        const x = {
          parameters: params.items.map(item => {
            return `${item.entryName} ${item.title}`
          })
        }
        setParameters(x)
      })
      Service.getTemplateCode(_value.printTypeEnum.value).then(data => {
        _value.templateCode = data.templateCode;
        form.setValue(_value);
      });
    }

  }, [_value.printTypeEnum]);

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{header}</span>
          <div>
            <Button label={'Tạo mới'} icon="pi pi-plus" style={{height: '25px'}} size={'small'} severity={'success'} className={'mr-2'} onClick={() => doCreate()}></Button>
          </div>
        </Fragment>
      </div>
    </div>
  )

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
        <Toast ref={toast}/>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 14rem)'}}>
        {renderDataTable()}
        {renderDialogCrup()}
      </div>
    </div>
  )
}
const ItemTooltip = (rowData) => {
  return (
    <Tooltip>
      <div className="" style={{width: '40rem'}}>
        {rowData}
      </div>
    </Tooltip>
  );
}