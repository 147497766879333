import {Fragment, useEffect, useState} from 'react';
import {FormatDisplay, useDataTable, useDataTableBasic, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';
import {Button} from 'primereact/button';

import {PaymentService as Service} from './PaymentServices';

import {useCrupPayment} from './CrupPayment';
import {useCrupPaymentDetail} from './CrupPaymentDetail';
import {UserStorage} from '../../../service/UserStorage';

export const Payments = () => {

  const [headerResult, setHeaderResult] = useState(null);

  const dataKey = 'agreementId';
  const downloadTooltip = 'Xuất Excel danh sách sản phẩm';
  const ownerPartyId = UserStorage.get('household');
  const header = 'Danh sách thanh toán'
  useEffect(() => {
    setHeaderResult(tabHeader)
  }, [])

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    tableHeader: headerResult,
    dataKey,
    columns: [
      {
        field: 'workEffortName', header: 'Mã phiếu gộp', minWidth: 150, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => Service.get(rowData.agreementId).then(data => {
            doUpdatePayment(rowData.agreementId, data, false);
          })
          }>{rowData.workEffortName}</a>
        }
      },
      {field: 'agreementNo', header: 'Số hợp đồng', width: 130, matchMode: 'contains'},
      {field: 'agreementDate', header: 'Ngày hợp đồng', width: 140, matchMode: 'contains', dataType: 'date'},
      {
        field: 'toPartyName', header: 'Tên khách hàng', width: 200, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.toPseudoId) {
            return <div>
              [{rowData.toPseudoId}] - {rowData.toPartyName}
            </div>;
          }
          return '';
        }
      },
      {
        field: 'toBankAccount', header: 'TK nhận', width: 155, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.toAccountNumber) {
            return <div>
              [{rowData.toAccountNumber}] - {rowData.toBankName}
            </div>;
          }
          return '';
        }
      },
      {field: 'referenceNumber', header: 'Mã đơn hàng', minWidth: 130, matchMode: 'contains'},
      {field: 'invoiceTotal', header: 'Tổng tiền', minWidth: 150, matchMode: 'contains', dataType: 'number'},
      {field: 'invoiceDate', header: 'Ngày tạo', width: 150, dataType: 'date', matchMode: 'contains'},
      // {field: 'status', header: 'Trạng thái', width: 150, matchMode: 'contains', className: 'md:col-4'},

      {
        field: 'files', header: 'Biểu mẫu', width: 220, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.printTemplates) {
            return <div>
              {rowData.printTemplates.map((item, index) => <p key={index} className="mt-2">
                <i className="pi pi-download mr-2"
                   onClick={() => window.open(`${Service.baseURL}/${Service.entity}/${rowData.agreementId}/download-template?ownerPartyId=${ownerPartyId}&printTemplateId=${item?.printTemplateId}`)}
                ></i>{item.templateName}
              </p>)}
            </div>;
          }
          return '';
        }
      },
      {
        field: 'attachment', header: 'File đính kèm', width: 220, dataType: 'custom', customCell(rowData: any): any {
          if (rowData.invoiceContents) {
            return <div>
              {rowData.invoiceContents.map((item, index) => <p key={index} className="mt-2">
                <i className="pi pi-download mr-2" onClick={() =>
                  window.open(`${Service.baseURL}/${Service.entity}/${rowData.agreementId}/download-attachment?ownerPartyId=${ownerPartyId}&invoiceContentId=${item.invoiceContentId}`)
                }></i> {item.fileName}
              </p>)}
            </div>;
          }
          return '';
        }
      },
      {
        field: 'productNameWithPseudoId', header: 'Action', width: 100, dataType: 'custom', customCell(rowData: any): any {
          return <div>
            <Button icon="pi pi-download" className="ml-4" severity="warning" tooltip={downloadTooltip}
                    onClick={() => window.open(`${Service.baseURL}/${Service.entity}/${rowData?.agreementId}/export-products`)}></Button>
          </div>
        }
      },

    ],
    indexColumnWidth: 45,
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },

  });
  const {renderCrupPayment, doPlan} = useCrupPayment({reloadLazyData})
  const {renderCrupPaymentDetail, doUpdatePayment, doPlanPayment} = useCrupPaymentDetail({reloadLazyData})

  const doUpdate = (doView: boolean, rowData) => {
    Service.get(rowData[dataKey]).then(data => {
      doUpdatePayment(rowData[dataKey], data, doView);
    });

    reloadLazyData()
  }

  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{header}</span>
          <div>
            <Button label={'Tạo mới'} icon="pi pi-plus" style={{height: '25px'}} size={'small'} severity={'success'} className={'mr-2'} onClick={() => doPlan()}></Button>
          </div>
        </Fragment>
      </div>
    </div>
  )


  return (
    <div className="grid">
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 14rem)'}}>
        {renderDataTable()}
      </div>
      {renderCrupPayment()}
      {renderCrupPaymentDetail()}
    </div>
  )
}