import {Fragment, useEffect, useState} from 'react';

import {SelectButton} from 'primereact/selectbutton';
import {Button} from 'primereact/button';
import {ToastService, useDataTable, useDialogCrup, useToolbar} from '@iamsoftware/react-hooks';

import {FacilitiesService as Service} from './FacilitiesService';
import {ImportExcel} from '../../../shared/components/ImportExcel';

const statuses = [
  {value: 'FacActive', label: 'Đang hoạt động'},
  {value: 'FacInactive', label: 'Ngừng hoạt động'}
];

export const Facilities = () => {

  const header = 'Kho hàng';
  const dataKey = 'facilityId';

  const [statusId, setStatusId] = useState(statuses[0].value);

  const [requiredParams, setRequiredParams] = useState(null);
  const [headerResult, setHeaderResult] = useState(null);

  useEffect(() => {
    if (statusId) {
      setRequiredParams({statusId: {value: statusId, matchMode: 'equals'}});
    } else {
      setRequiredParams({});
    }
    setHeaderResult(tabHeader)

  }, [statusId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, selectedItems, reloadLazyData} = useDataTable({
    dataKey,
    tableHeader:headerResult,
    columns: [
      {
        field: 'pseudoId', header: 'Mã kho hàng', width: 200, matchMode: 'contains', dataType: 'custom', customCell(rowData: any): any {
          return <a onClick={() => update(rowData)}>{rowData.pseudoId}</a>
        }
      },      {field: 'facilityName', header: 'Tên kho', minWidth: 250, matchMode: 'contains'},
      {field: 'address1', header: 'Địa chỉ', minWidth: 250, matchMode: 'contains'}
    ],
    indexColumnWidth: 45,
    actionColumnWidth: 120,
    initActions(items: Array<any>) {
      if (items) {
        items.forEach(item => {
          item.actions = [
            {icon: 'pi pi-eye', title: 'Xem', className: 'p-button-info', command: () => view( item)},
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', className: 'p-button-warning', command: () => update(item)},
          ]
          if (statusId === 'FacActive') {
            item.actions.push({icon: 'pi pi-lock', title: 'Khóa', className: 'p-button-danger', command: () => doDisable(item)})
          } else if (statusId === 'FacInactive') {
            item.actions.push({icon: 'pi pi-lock-open', title: 'Mở khóa', className: 'p-button-success', command: () => doEnable(item)})
          }
        })

      }
    },
    getList: lazyLoadEvent => {
      return Service.getList(lazyLoadEvent);
    },
    requiredParams
  });

  const {render: renderDialogCrup, create, update, view} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'pseudoId', header: 'Mã kho', required: true, className: 'md:col-12'},
      {field: 'facilityName', header: 'Tên kho', required: true, className: 'md:col-12'},
      {field: 'address1', header: 'Địa chỉ',  className: 'md:col-12'}
    ],
    createItem: item => {
      return Service.create(item);
    },
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData
  });


  const doDisable = (rowData) => {
      Service.disable(rowData[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });

  }
  const doEnable = (rowData) => {
      Service.enable(rowData[dataKey]).then(() => {
        ToastService.success();
        reloadLazyData();
      });

  }
  const tabHeader = (
    <div>
      <div className="flex align-items-center justify-content-between ">
        <Fragment>
          <span>{header}</span>
          <div>
            <Button label={'Tạo mới'} icon="pi pi-plus" style={{height:'25px'}} size={'small'} severity={'success'} className={'mr-2'} onClick={() => create()}></Button>
          </div>
        </Fragment>
      </div>
    </div>
  )

  return (
    <div className="grid">
      <div className="col-12">
        <SelectButton value={statusId} options={statuses} onChange={e => setStatusId(e.value)}/>
      </div>
      <div className="col-12 pb-0" style={{maxHeight: 'calc(100vh - 20.4rem)'}}>
        {renderDataTable()}
      </div>

      {renderDialogCrup()}
    </div>
  );
}