import {EntityService} from '../../../service/EntityService';

export class PurchasesOrdersService extends EntityService {

  static entity = 'iam-purchase/orders';

  static getPurchasePlanning(lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`iam-sale/orders/purchase-planning?lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }
  static getPseudoId(): Promise<any> {
    return this.axios.get(`${this.entity}/shipment-pseudoId`).then(res => res.data);
  }
  static getPurchasePlanningItems(orderId: string, purchaseOrderId: string, excludes?: Array<string>): Promise<any> {
    return this.axios.get(`iam-sale/orders/${orderId}/planning-items?purchaseOrderId=${purchaseOrderId}`, {params: {excludes}}).then(res => res.data);
  }

  static getAwaitingItems(orderId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${orderId}/not-shipped`).then(res => res.data);
  }

  static getShipments(orderId: string): Promise<any> {
    return this.axios.get(`${this.entity}/${orderId}/shipments/list`).then(res => res.data);
  }

  static createRemainShipment(orderId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${orderId}/shipments/create-remain`, data).then(res => res.data);
  }

  static createShipment(orderId: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${orderId}/shipments/create`, data).then(res => res.data);
  }

  static items(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/items`).then(res => res.data);
  }

  static cancel(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/cancel`).then(res => res.data);
  }

  static calender() {
    return this.axios.get(`${this.entity}/calender-po`).then(res => res.data);
  }

  static displayId() {
    return this.axios.get(`${this.entity}/displayId`).then(res => res.data);
  }

  static cancelShipment(workEffortId: string): Promise<any> {
    return this.axios.put(`${this.entity}/shipments/${workEffortId}/cancel`).then(res => res.data);
  }

  static receiveEntireShipments(workEffortId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/shipments/${workEffortId}/receive-entire`, data).then(res => res.data);
  }

  static getShipmentsDetail(workEffortId: string): Promise<any> {
    return this.axios.get(`${this.entity}/shipments/${workEffortId}/detail`).then(res => res.data);
  }

  static updateShipmentsDetail(workEffortId: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/shipments/${workEffortId}/update`, data).then(res => res.data);
  }

}