import {Fragment, useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {AutoComplete} from "primereact/autocomplete";
import {TabView, TabPanel} from 'primereact/tabview';
import {InputText} from "primereact/inputtext";

import {FormatDisplay, ToastService, useForm} from '@iamsoftware/react-hooks';

import {SalesOrdersService, SalesOrdersService as Service} from './SalesOrdersService';
import {SuppliersService} from '../../mua-hang/nha-cung-cap/SuppliersService';
import {CustomersService} from '../khach-hang/CustomersService';
import {useCrupSOItems} from './CrupSOItems';
import {ProductContents} from '../san-pham/ProductContents';
import {AppStorage, UserStorage} from '../../../service/UserStorage';
import {HouseholdsService} from '../../../shared/components/layout/HouseholdsService';
import {useCrupCustomer} from '../khach-hang/CrupCustomer';
import {ProductLabel} from './ProductLabel';
import {SaleOrderShipments} from './OrderShipments';

export const useCrupSO = ({reloadLazyData}) => {
  const [orderId, setOrderId] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [display, setDisplay] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [displayId, setDisplayId] = useState('');

  const [vendorParties, setVendorParties] = useState([]);
  const [customerParties, setCustomerParties] = useState([]);
  const [destFacilities, setDestFacilities] = useState([]);
  const [labelItems, setLabelItems] = useState([])
  const [displayShipments, setDisplayShipments] = useState(null);

  const [activeIndex, setActiveIndex] = useState(0)
  const [displayProductLabel, setDisplayProductLabel] = useState(null);

  const [displayProductContents, setDisplayProductContents] = useState(null);
  const [customerPartyName, setCustomerPartyName] = useState(null);
  const {renderCustomer, doCreateCustomer, doUpdateCustomer} = useCrupCustomer({reloadLazyData});
  const {renderProducts, items, setItems, deleteOrderItemSeqIds} = useCrupSOItems({display, disabled, setDisplayProductContents, reloadLazyData});
  const itemTemplate = (item) => {
    return (
      <div className="flex align-items-center">
        <div>{item.label}</div>
      </div>
    );
  };
  const getDisplayId = () => {
    Service.displayId().then(data => {
      setDisplayId(data.displayId)
    })
  }
  const searchDestFacilities = event => {
    CustomersService.getFacilities(customerPartyName?.value).then(data => {
      setDestFacilities(data.listData.map(item => {
        return {value: item.facilityId, label: `[${item.pseudoId}] - ${item.facilityName}`};
      }));
    });
  }
  const searchVendorParties = event => {
    SuppliersService.find(event.query).then(data => {
      setVendorParties(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`};
      }));
    });
  }

  const searchCustomerParties = event => {
    CustomersService.find(event.query).then(data => {
      setCustomerParties(data.listData.map(item => {
        delete item.disabled;
        return {...item, value: item.partyId, label: `[${item.pseudoId}] - ${item.partyName}`};
      }));
    });
  }

  const form = useForm({
    formGroups: [
      {className: 'col-4', start: 0, end: 5},
      {className: 'col-4', start: 5, end: 8},
      {className: 'col-4', start: 8, end: 12}
    ],
    fields: [
      {
        field: 'pseudoId', type: 'custom', className: 'md:col-6', body: <div>
          <div>
            <div>
              Mã đơn hàng<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <InputText value={displayId} onChange={e => setDisplayId(e.target.value)}/>
              <span className="p-inputgroup-addon" style={{background: '#3B82F6', color: 'white', cursor: 'pointer'}} onClick={getDisplayId}>
            <i className="pi pi-sync"/>
          </span>
            </div>
          </div>
        </div>
      },
      {field: 'status', header: 'Trạng thái', className: 'md:col-6', disabled: true},
      {field: 'placedDate', header: 'Ngày lập', InputTextProps: {type: 'date'}, className: 'md:col-6'},
      {field: 'estimatedShipDate', header: 'Dự kiến giao', InputTextProps: {type: 'date'}, className: 'md:col-6'},
      {field: 'orderName', header: 'Ghi chú', className: 'md:col-12'},
      {
        field: 'vendorParty', header: 'Nhà cung cấp', required: true, type: 'AutoComplete',
        AutoCompleteProps: {suggestions: vendorParties, completeMethod: searchVendorParties, field: 'label', dropdown: true, forceSelection: true},
        className: 'md:col-12', disabled: true
      },
      {field: 'vendorTelecomNumber', header: 'Số điện thoại nhà cung cấp', className: 'md:col-12'},
      {field: 'vendorAddress', header: 'Địa chỉ nhà cung cấp', className: 'md:col-12'},
      {
        field: 'customerParty', type: 'custom', className: 'md:col-12', body: <div>
          <div>
            <div>
              Khách hàng<span style={{color: 'red'}}>*</span>
            </div>
            <div className='p-inputgroup mt-2'>
              <AutoComplete field='label' disabled={disabled} value={customerPartyName} suggestions={customerParties} completeMethod={searchCustomerParties} itemTemplate={itemTemplate} onChange={(e) => {
                setCustomerPartyName(e.value);
              }} dropdown/>
              {!disabled && <span className="p-inputgroup-addon" style={{background: '#22C55E', color: 'white', cursor: 'pointer'}} onClick={() => doCreateCustomer()}>
            <i className="pi pi-plus"/>
          </span>}
            </div>
          </div>
        </div>
      },
      {field: 'customerTelecomNumber', header: 'Số điện thoại khách hàng', className: 'md:col-6'},
      {
        field: 'destFacility', header: 'Mã xưởng', type: 'AutoComplete',
        AutoCompleteProps: {suggestions: destFacilities, completeMethod: searchDestFacilities, field: 'label', dropdown: true},
        className: 'md:col-6'
      },
      {field: 'customerAddress', header: 'Địa chỉ khách hàng', className: 'md:col-12'},
      {field: 'ownerPartyId', type: 'hidden'},
    ],
    disabled
  });

  const {vendorParty, customerParty} = form.getValue();

  useEffect(() => {
    if (vendorParty?.value && form.dirty['vendorParty']) {
      const _value = form.getRawValue();
      _value.vendorAddress = vendorParty.address1;
      _value.vendorTelecomNumber = vendorParty.telNumber;
      form.setValue(_value);
    }
  }, [vendorParty?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customerParty?.value && form.dirty['customerParty']) {
      const _value = form.getRawValue();
      _value.customerAddress = customerParty.address1;
      _value.customerTelecomNumber = customerParty.telNumber;
      _value.destFacility = customerParty.destFacilityName;
      form.setValue(_value);
      // CustomersService.getFacilities(customerParty?.value).then(data => {
      //   setDestFacilities(data.listData.map(item => {
      //     return {value: item.facilityId, label: `[${item.pseudoId}] - ${item.facilityName}`};
      //   }));
      // });
    }
  }, [customerParty?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const doCreate = (defaultValue?: any) => {
    setActiveIndex(0)
    setOrderId(null)
    setCustomerPartyName(null)
    form.reset();
    HouseholdsService.get(UserStorage.get('household')).then(async household => {
      await Service.displayId().then(data => {
        setDisplayId(data.displayId)
        form.setValue(defaultValue?.formValue ||
          {
            placedDate: FormatDisplay.date(new Date(), 'YYYY-MM-DD'),
            vendorParty: household?.partyName,
            vendorTelecomNumber: household?.telNumber,
            vendorAddress: household?.address1,
            ownerPartyId: household?.partyId,
            status: "Tạo mới"
          });
      })

    })
    setItems([]);
    setDisabled(false);
    setDisplay({create: Date.now()});
  }

  const doUpdate = (orderId: string, data: any, doView: boolean) => {
    setActiveIndex(0)
    setOrderId(orderId);
    setDisplayId(data.displayId)

    if (data.placedDate) {
      data.placedDate = FormatDisplay.date(new Date(data.placedDate), 'YYYY-MM-DD');
    }
    if (data.estimatedShipDate) {
      data.estimatedShipDate = FormatDisplay.date(new Date(data.estimatedShipDate), 'YYYY-MM-DD');
    }

    if (data.vendorPartyId) {
      data.vendorParty = {value: data.vendorPartyId, label: `[${data.vendorPseudoId}] - ${data.vendorName}`};
    }
    if (data.customerPartyId) {
      data.customerParty = {value: data.customerPartyId, label: `[${data.customerPseudoId}] - ${data.customerName}`};
      let label = `[${data.customerPseudoId}] - ${data.customerName}`
      setCustomerPartyName({value: data.customerPartyId, label: label})
    }
    if (data.destFacilityId) {
      data.destFacility = {value: data.destFacility, label: `[${data.destFacilityPseudoId}] - ${data.destFacilityName}`};
    }
    form.setValue(data);
    setItems(data.items);
    if (doView === true) {
      data.disabled = true
      setOrderData(data)
      setDisabled(true);
      setDisplay({update: true});
    } else {
      data.disabled = false
      setOrderData(data)
      setDisabled(false);
      setDisplay({update: true});
    }
  }
  const inject = () => {
    const item = form.getValue();
    if (deleteOrderItemSeqIds?.length) {
      item.deleteOrderItemSeqIds = deleteOrderItemSeqIds;
    }
    item.vendorPartyId = item.vendorParty.value;
    delete item.vendorParty;

    item.customerPartyId = customerPartyName.value;

    item.destFacilityId = item.destFacility.value;
    delete item.destFacility;

    item.items = items;

    return item;
  }

  const onSubmit = event => {
    if (!submitting && form.valid()) {
      if (items?.length) {
        setSubmitting(true);
        let promise;
        if (orderId) {
          promise = Service.update(orderId, inject());
        } else {
          promise = Service.create(inject());
        }
        promise.then(() => {
          setDisplay(null);
          ToastService.success();
          reloadLazyData();
        }).finally(() => {
          setSubmitting(false);
        });
      } else {
        ToastService.error('Chưa có sản phẩm trong đơn hàng.');
      }
    }
    event.preventDefault();
  }
  useEffect(() => {
      if (activeIndex === 2) {
        setDisplayProductLabel(orderId)
      }
      if (activeIndex === 1) {
        setDisplayShipments(orderData)
      }
    },
    [activeIndex])
  const ownerPartyId = UserStorage.get('household');
  const selectAll = () => {
    setLabelItems(prevState => {
      prevState.forEach(item => {
        item.shouldPrint = true;
      });
      return [...prevState];
    });
  }
  const unselectAll = () => {
    setLabelItems(prevState => {
      prevState.forEach(item => {
        item.shouldPrint = false;
      });
      return [...prevState];
    });
  }
  const doPrint = () => {
    const printItems = labelItems.filter(item => item.shouldPrint && item.quantityStamp > 0).map(item => {
      return {
        orderPartSeqId: item.orderPartSeqId,
        orderItemSeqId: item.orderItemSeqId,
        quantityStamp: item.quantityStamp,
        customerContactName: item.customerContactName,
        receivedDate: item.receivedDate
      }
    });
    if (printItems?.length) {
      window.open(`${Service.baseURL}/${Service.entity}/${orderId}/pdf/ms-01?items=${JSON.stringify(printItems)}&ownerPartyId=${ownerPartyId}`);
    } else {
      ToastService.error('Chưa có sản phẩm.');
    }
  }

  const doPrint2 = () => {
    const printItems = labelItems.filter(item => item.shouldPrint && item.quantityStamp > 0).map(item => {
      return {
        orderPartSeqId: item.orderPartSeqId,
        orderItemSeqId: item.orderItemSeqId,
        quantityStamp: item.quantityStamp,
        customerContactName: item.customerContactName,
        receivedDate: item.receivedDate,
      }
    });
    if (printItems?.length) {
      window.open(`${Service.baseURL}/${Service.entity}/${orderId}/pdf/ms-02?items=${JSON.stringify(printItems)}&ownerPartyId=${ownerPartyId}`);
    } else {
      ToastService.error('Chưa có sản phẩm.');
    }
  }


  const footer = (
    <div className="flex justify-content-end">
      {!disabled && activeIndex === 0 && <Button label="Lưu" icon="pi pi-check" size="small" onClick={onSubmit} loading={submitting}/>}
      {activeIndex === 2 && <Button label="Chọn tất cả" icon="pi pi-check-square" type="button" outlined severity="help" size="small" onClick={selectAll}/>}
      {activeIndex === 2 && <Button label="Bỏ chọn tất cả" icon="pi pi-times" type="button" outlined severity="help" size="small" onClick={unselectAll}/>}
      {activeIndex === 2 && <Button label="In mẫu 1" icon="pi pi-print" type="button" outlined size="small" onClick={doPrint}/>}
      {activeIndex === 2 && <Button label="In mẫu 2" icon="pi pi-print" type="button" outlined size="small" onClick={doPrint2}/>}
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );
  const render = () => {
    return (
      <Fragment>
        <Dialog header="Đơn hàng bán" footer={footer} visible={!!display} maximized={true} draggable={false} onHide={() => setDisplay(null)}>
          {display?.create &&
						<div className="grid pt-2">
							<div className="col-12">
                {form.render()}
							</div>
							<div className="col-12">
                {renderProducts()}
							</div>
						</div>
          }
          {display?.update &&
						<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
							<TabPanel header="Thông tin đơn hàng ">
								<div className="grid pt-2">
									<div className="col-12">
                    {form.render()}
									</div>
									<div className="col-12">
                    {renderProducts()}
									</div>
								</div>
							</TabPanel>
							<TabPanel header="Phiếu xuất">
								<SaleOrderShipments Service={SalesOrdersService} type="outgoing" display={displayShipments} setDisplay={setDisplayShipments} reloadLazyData={reloadLazyData}/>
							</TabPanel>
							<TabPanel header="In tem">
								<ProductLabel display={displayProductLabel} setDisplay={setDisplayProductLabel} items={labelItems} setItems={setLabelItems}/>
							</TabPanel>
						</TabView>
          }
        </Dialog>
        <ProductContents display={displayProductContents} setDisplay={setDisplayProductContents}/>
        {renderCustomer()}
      </Fragment>
    );
  }

  return {renderCrupSO: render, doCreateSO: doCreate, doUpdateSO: doUpdate};
}