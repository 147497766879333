import {Route, Routes} from 'react-router-dom';

import {SalesOrders} from './don-hang-ban';
import LichSO from './lich-SO';
import {Products} from './san-pham';
import {Customers} from './khach-hang';

export default function Sales() {

  return (
    <Routes>
      <Route path="don-hang-ban" element={<SalesOrders/>}/>
      <Route path="lich-SO" element={<LichSO/>}/>
      <Route path="san-pham" element={<Products/>}/>
      <Route path="khach-hang" element={<Customers/>}/>
    </Routes>
  );
}
