import {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ToastService, useDataTable, useDataTableBasic} from '@iamsoftware/react-hooks';

import {PaymentService as Service} from "./PaymentServices";

export const useCrupItemProducts = ({doCreateItem}, {agreementId},productIds) => {

  const [display, setDisplay] = useState(null);
  const [products, setProducts] = useState(null);


  const {render: renderDataTable, selectedItems, setSelectedItems} = useDataTableBasic({
    tableHeader: 'Sản phẩm ',
    dataKey: 'productId',
    columns: [
      {field: 'productNameWithlocalizedName', header: 'Sản phẩm', minWidth: 200},
      {field: 'unitAmount', header: 'Giá', width: 140, dataType: 'number'},
    ],
    selectionMode: 'checkbox',
    items: products
  });

  const doPlanProduct = () => {
    setSelectedItems(null)
     Service.getAddableItem(agreementId, productIds).then(data=>{
       data.items.forEach((item) => {
         if (!item.productNameWithlocalizedName) {
           item.productNameWithlocalizedName = `[${item.pseudoId}] - ${item.productName} ${item.localizedName}`;
         }
       });
       setProducts(data.items)
     })
    setDisplay(Date.now());
  }

  const onSubmit = event => {
    if (selectedItems?.length) {
      doCreateItem(selectedItems);
      setDisplay(null);
    } else {
      ToastService.error('Chưa chọn sản phẩm.');
    }
    event.preventDefault();
  }

  const footer = (
    <div className="flex justify-content-center">
      <Button label="Xác nhận" icon="pi pi-arrow-right" size="small" onClick={onSubmit}/>
      <Button label="Đóng" icon="pi pi-times" type="button" outlined severity="secondary" size="small" onClick={() => setDisplay(null)}/>
    </div>
  );

  const renderPurchasePlanning = () => {
    return (
      <Dialog header="Chọn sản phẩm" footer={footer} visible={!!display} style={{width: '70rem'}} position="top" onHide={() => setDisplay(null)}>
        <div className="grid pt-2">
          {renderDataTable()}
        </div>
      </Dialog>
    );
  }

  return {renderPurchasePlanning, doPlanProduct};
}
const amountUoms = [
  {value: "TF_yr", label: "Năm"},
  {value: "TF_mon", label: "Tháng"}
]